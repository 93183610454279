export const naturalPersonDisabledList = [
  'id_investor_representations_3',
  'id_investor_representations_4',
  'id_investor_representations_5',
  'id_investor_representations_6',
  'id_investor_representations_7',
  'id_investor_representations_8',
  'id_investor_representations_9',
  'id_purchase_representations_3',
  'id_purchase_representations_4',
  'id_purchase_representations_5',
  'id_purchase_representations_6',
  'id_client_representations_1',
  'id_client_representations_2',
  'id_client_representations_or',
  'id_special_entities_1_a',
  'id_special_entities_1_b',
  'id_special_entities_2_a',
  'id_special_entities_2_b',
  'id_special_entities_2_c',
  'id_special_entities_2_d',
  'id_special_entities_3',
  'id_special_entities_3_a',
  'id_special_entities_4',
  'id_special_entities_5',
  'id_special_entities_6',
  'id_special_entities_7',
  'id_special_entities_8',
  'id_special_entities_9_a',
  'id_special_entities_9_b',
  'id_special_entities_9_c',
  'id_foreign_person_2',
  'id_foreign_person_3',
  'id_foreign_person_4',
  'id_foreign_person_2_1',
  'id_foreign_person_2_2',
  'id_foreign_person_2_or',
];

export const entityDisabledList = [
  'id_investor_representations_1',
  'id_investor_representations_2',
  'id_purchase_representations_1',
  'id_foreign_person_1',
];

export const yesOrNoQuestions = [
  'id_investor_status_1',
  'id_investor_status_2',
  'id_investor_status_3',
  'id_investor_status_4',
  'id_investor_status_5_a',
  'id_investor_status_5_b',
  'id_investor_status_5_c',
  'id_investor_status_5_d',
  'id_investor_status_5_e',
  'id_investor_status_5_f',
  'id_investor_status_6',
  'id_wiring_bank_status_1',
  'id_wiring_bank_status_2',
  'id_special_entities_1_a',
  'id_special_entities_1_b',
  'id_special_entities_2_a',
  'id_special_entities_2_b',
  'id_special_entities_2_c',
  'id_special_entities_2_d',
  'id_special_entities_3_a',
  'id_special_entities_4',
  'id_special_entities_5',
  'id_special_entities_6',
  'id_special_entities_7',
  'id_special_entities_8',
  'id_special_entities_9_a',
  'id_special_entities_9_b',
  'id_special_entities_9_c',
];

export const multiSelectQuestions = [
  'id_investor_representations_1',
  'id_investor_representations_2',
  'id_investor_representations_3',
  'id_investor_representations_4',
  'id_investor_representations_5',
  'id_investor_representations_6',
  'id_investor_representations_7',
  'id_investor_representations_8',
  'id_investor_representations_9',
  'id_investor_representations_or',
  'id_purchase_representations_1',
  'id_purchase_representations_2',
  'id_purchase_representations_3',
  'id_purchase_representations_4',
  'id_purchase_representations_5',
  'id_purchase_representations_6',
  'id_purchase_representations_or',
  'id_client_representations_1',
  'id_client_representations_2',
  'id_client_representations_or',
  'id_foreign_person_1',
  'id_foreign_person_2',
  'id_foreign_person_3',
  'id_foreign_person_4',
  'id_foreign_person_or',
  'id_foreign_person_2_1',
  'id_foreign_person_2_2',
  'id_foreign_person_2_or',
  'id_additional_contact_info',
];

export const textFieldQuestions = [
  'id_wiring_bank_status_1_opt',
  'id_special_entities_1_b_opt',
  'id_special_entities_2_e_opt',
];
