/* eslint-disable react/sort-comp,import/prefer-default-export,no-shadow, no-loop-func,
 no-nested-ternary */

/* @flow */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import type { Connector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import './../../../../styles_common/questionnaire_form.scss';
import * as notify from '../../../../../../utils/notify';
import {
  Context as ContextType,
  QuestionnaireExperience as QuestionnaireExperienceContext,
  Reducer,
  Dispatch,
} from '../../../../../../types/index';
import TextInput from '../../../../../../components/TextInput/index.uncontrolled';
import Button from '../../../../../../components/Button/index';
import TextArea from '../../../../../../components/TextArea/index.uncontrolled';
import Title from '../../../../../../components/Title/index';
import ConfirmCheckbox from '../../../../../../components/ConfirmCheckbox/index';
import MultiSelect from '../../../../../../components/MultiSelect/index';
import { QuestionnaireIndex } from '../QuestionnaireIndex/index.v1';
import Alert from '../../../../../../components/Alert/index';
import Paragraph from '../../../../../../components/Paragraph/index';
import FundTool from '../../../../../../components/FundTool/index';
import Tooltip from '../../../../../../components/Tooltip/index';
import * as iqID from '../../../../../../utils/constants/questionnaire/identifiers';
import { SERVICE_CONFIG_QUESTIONNAIRE } from 'utils/service-commons';
import { saveGeneralInformation } from '../../../../util';

type Props = {
  context: ContextType,
  questionnaireContext: QuestionnaireExperienceContext,
};

export class QuestionnaireForm extends PureComponent {
  props: Props;

  static defaultProps: {
    context: {},
  };

  handleTextInputChange = (event) => {
    event.preventDefault();
    const { questionnaireContext } = this.props;
    const name = event.target.name;
    const value = event.target.value;

    const payload = {};
    if (questionnaireContext.multiAccess) {
      payload.limitedPartnerFund =
        questionnaireContext.investorFund._id.toString();
    } else {
      payload.investorFund = questionnaireContext.investorFund._id.toString();
    }

    const generalInfo = questionnaireContext.investorFund.generalInfo;
    payload.name = generalInfo.name;
    payload.signatory = generalInfo.signatory;
    payload.tax = generalInfo.tax;
    payload.country = generalInfo.country;
    payload.countryPlace = generalInfo.countryPlace;
    payload.types = generalInfo.types;
    payload.otherValue = generalInfo.otherValue;
    payload.namePrimaryContact = generalInfo.namePrimaryContact;
    payload.emailPrimaryContact = generalInfo.emailPrimaryContact;
    payload.addressPrimaryContact = generalInfo.addressPrimaryContact;
    payload.phonePrimaryContact = generalInfo.phonePrimaryContact;
    payload.capitalCommitment = generalInfo.capitalCommitment;
    payload.nameLetter = generalInfo.nameLetter;
    payload.totalAllFund = generalInfo.totalAllFund;
    payload.additionalContactInfo = generalInfo.additionalContactInfo;
    payload.additionalDistributionInfo = generalInfo.additionalDistributionInfo;
    // eslint-disable-next-line
    payload.principalPlaceOfBusiness =
      generalInfo.principalPlaceOfBusiness || '';
    payload.principalPlaceOfBusinessPrimaryContact =
      generalInfo.principalPlaceOfBusinessPrimaryContact || '';
    // eslint-disable-next-line
    payload.principalPlaceOfResidence =
      generalInfo.principalPlaceOfResidence || '';
    payload.principalPlaceOfResidencePrimaryContact =
      generalInfo.principalPlaceOfResidencePrimaryContact || '';
    // eslint-disable-next-line
    payload.jurisdictionOrganitzationPrimaryContact =
      generalInfo.jurisdictionOrganitzationPrimaryContact;
    payload.reportsPrimaryContactName = generalInfo.reportsPrimaryContactName;
    payload.reportsPrimaryContactEmail = generalInfo.reportsPrimaryContactEmail;
    payload.reportsPrimaryContactPhone = generalInfo.reportsPrimaryContactPhone;
    payload.reportsSecondaryContactName =
      generalInfo.reportsSecondaryContactName;
    payload.reportsSecondaryContactEmail =
      generalInfo.reportsSecondaryContactEmail;
    payload.reportsSecondaryContactPhone =
      generalInfo.reportsSecondaryContactPhone;
    payload.taxPrimaryContactName = generalInfo.taxPrimaryContactName;
    payload.taxPrimaryContactEmail = generalInfo.taxPrimaryContactEmail;
    payload.taxPrimaryContactPhone = generalInfo.taxPrimaryContactPhone;
    payload.taxSecondaryContactName = generalInfo.taxSecondaryContactName;
    payload.taxSecondaryContactEmail = generalInfo.taxSecondaryContactEmail;
    payload.taxSecondaryContactPhone = generalInfo.taxSecondaryContactPhone;
    payload.capitalPrimaryContactName = generalInfo.capitalPrimaryContactName;
    payload.capitalPrimaryContactEmail = generalInfo.capitalPrimaryContactEmail;
    payload.capitalPrimaryContactPhone = generalInfo.capitalPrimaryContactPhone;
    payload.capitalSecondaryContactName =
      generalInfo.capitalSecondaryContactName;
    payload.capitalSecondaryContactEmail =
      generalInfo.capitalSecondaryContactEmail;
    payload.capitalSecondaryContactPhone =
      generalInfo.capitalSecondaryContactPhone;
    payload.stockPrimaryContactName = generalInfo.stockPrimaryContactName;
    payload.stockPrimaryContactEmail = generalInfo.stockPrimaryContactEmail;
    payload.stockPrimaryContactPhone = generalInfo.stockPrimaryContactPhone;
    payload.stockSecondaryContactName = generalInfo.stockSecondaryContactName;
    payload.stockSecondaryContactEmail = generalInfo.stockSecondaryContactEmail;
    payload.stockSecondaryContactPhone = generalInfo.stockSecondaryContactPhone;
    payload.legalPrimaryContactName = generalInfo.legalPrimaryContactName;
    payload.legalPrimaryContactEmail = generalInfo.legalPrimaryContactEmail;
    payload.legalPrimaryContactPhone = generalInfo.legalPrimaryContactPhone;
    payload.legalSecondaryContactName = generalInfo.legalSecondaryContactName;
    payload.legalSecondaryContactEmail = generalInfo.legalSecondaryContactEmail;
    payload.legalSecondaryContactPhone = generalInfo.legalSecondaryContactPhone;
    payload.bankName = generalInfo.bankName;
    payload.acctName = generalInfo.acctName;
    payload.swiftCode = generalInfo.swiftCode;
    payload.ibanNumber = generalInfo.ibanNumber;
    payload.interBankAcctNumber = generalInfo.interBankAcctNumber;
    payload.interBankAcctName = generalInfo.interBankAcctName;
    payload.interBankAba = generalInfo.interBankAba;
    payload.interBankName = generalInfo.interBankName;
    payload.interBankSwift = generalInfo.interBankSwift;
    payload.additionalComment = generalInfo.additionalComment;
    payload.acctNumber = generalInfo.acctNumber;
    payload.bankaba = generalInfo.bankaba;
    payload.furtherCredit = generalInfo.furtherCredit;
    payload.furtherCreditNumber = generalInfo.furtherCreditNumber;
    payload.location = generalInfo.location;

    // switch (name) {
    //   // case 'legalNameBeneficalOwner':
    //   //   payload.legalNameBeneficalOwner = value;
    //   //   payload.residenceBeneficalOwner =
    //   //     questionnaireContext.investorFund.generalInfo.residenceBeneficalOwner;
    //   // case 'residenceBeneficalOwner':
    //   //   payload.residenceBeneficalOwner = value;
    //   //   payload.legalNameBeneficalOwner =
    //   //     questionnaireContext.investorFund.generalInfo.legalNameBeneficalOwner;
    //     break;
    // }
    saveGeneralInformation(payload, this.props);
  };

  componentDidMount() {
    this.updateTextAnswersState();
    notify.hideNotification();
  }

  updateTextAnswersState = () => {

    // repopulate text inputs values if they exist
    const id_wiring_bank_status_Data = this.parseQuestionDataFromId(
      iqID.id_wiring_bank_status_1_opt
    );
    const id_special_entities_1_b_opt_Data = this.parseQuestionDataFromId(
      iqID.id_special_entities_1_b_opt
    );
    const id_special_entities_2_e_opt_Data = this.parseQuestionDataFromId(
      iqID.id_special_entities_2_e_opt
    );
    const id_warranties_exceptions_Data = this.parseQuestionDataFromId(
      iqID.id_warranties_exceptions
    );
    const id_investor_status_7_tcg_o_Data = this.parseQuestionDataFromId(
      iqID.id_investor_status_7_tcg_o
    );

    const id_girw_7_i_Data = this.parseQuestionDataFromId(iqID.id_girw_7_i);

    const id_girw_7_2_f_Data = this.parseQuestionDataFromId(iqID.id_girw_7_2_f);

    const id_client_representations_2_Data = this.parseQuestionDataFromId(iqID.id_client_representations_2);

    if (this.id_wiring_bank_status_1_opt_Ref) {
      this.id_wiring_bank_status_1_opt_Ref.value = id_wiring_bank_status_Data
        ? id_wiring_bank_status_Data.answer
        : '';
    }
    if (this.id_special_entities_1_b_opt_Ref) {
      this.id_special_entities_1_b_opt_Ref.value =
        id_special_entities_1_b_opt_Data
          ? id_special_entities_1_b_opt_Data.answer
          : '';
    }

    if (this.id_investor_status_7_tcg_o_Ref) {
      this.id_investor_status_7_tcg_o_Ref.value = id_investor_status_7_tcg_o_Data && id_investor_status_7_tcg_o_Data.answer
      ? id_investor_status_7_tcg_o_Data.description
      : ' ';
    }

    if (this.id_girw_7_i_Ref) {
      this.id_girw_7_i_Ref.value = id_girw_7_i_Data && id_girw_7_i_Data.answer ? id_girw_7_i_Data.description : ' ';
    }

    if (this.id_girw_7_2_f_Ref) {
      this.id_girw_7_2_f_Ref.value = id_girw_7_2_f_Data && id_girw_7_2_f_Data.answer ? id_girw_7_2_f_Data.description : ' ';
    }

    if (this.id_client_representations_2_Ref) {
      this.id_client_representations_2_Ref.value = id_client_representations_2_Data && 
      id_client_representations_2_Data.answer ? id_client_representations_2_Data.description : ' ';
    }

    if (this.id_special_entities_2_e_opt_Ref) {
      this.id_special_entities_2_e_opt_Ref.value =
        id_special_entities_2_e_opt_Data
          ? id_special_entities_2_e_opt_Data.answer
          : '';
    }

    this.id_warranties_exceptions_Ref.value = id_warranties_exceptions_Data
      ? id_warranties_exceptions_Data.answer
      : '';
  };
  handlerFormSubmit = (event) => {
    const { questionnaireContext } = this.props;
    const nextStep = questionnaireContext.currentStep + 1;
    this.props.handleRedirect(event, nextStep);
  };
  backStep = (e) => {
    const { questionnaireContext } = this.props;
    this.props.handleRedirect(e, questionnaireContext.currentStep - 1);
  };

  parseCheckboxStateFromId = (_identifier) => {
    const { questionnaireContext } = this.props;
    const questions =
      questionnaireContext.investorFund.questionnaire.questions || [];
    if (questions && questions.length) {
      const targetQuestion = questions.find(
        (_q) => _q.descriptionIdentifier === _identifier
      );

      if (targetQuestion) {
        return [
          targetQuestion.answer === 'Yes',
          targetQuestion.answer === 'No',
        ];
      }
      return [false, false];
    }
    return [false, false];
  };
  parseMultiSelectStateFromId = (_identifier) => {
    const { questionnaireContext } = this.props;
    const questions =
      questionnaireContext.investorFund.questionnaire.questions || [];
    if (questions && questions.length) {
      const targetQuestion = questions.find(
        (_q) => _q.descriptionIdentifier === _identifier
      );
      if (targetQuestion) {
        return targetQuestion.answer === 'Yes';
      }
      return false;
    }
    return false;
  };
  parseQuestionDataFromId = (_identifier) => {
    const { questionnaireContext } = this.props;
    let targetQuestion = null;
    const questions =
      questionnaireContext.investorFund.questionnaire.questions || [];
    if (questions && questions.length) {
      targetQuestion = questions.find(
        (_q) => _q.descriptionIdentifier === _identifier
      );
      if (targetQuestion) {
        return targetQuestion;
      }
    }
    return targetQuestion;
  };
  handlerConfirm = (event) => {
    event.preventDefault();
    let answer = event.target.nextSibling.innerHTML;
    let descriptionIdentifier = event.target.closest('div').parentElement.parentElement.id;

    this.props.updateMultiSelectFormAnswer(descriptionIdentifier, answer);
  };
  orQuestionSelected = (_checked, _identifier, _targetSection) => {
    const {
      questionnaireContext: { currentVersionQuestions },
    } = this.props;
    const targetQuestionsLookup = currentVersionQuestions[_targetSection];
    for (let i = 0; i < targetQuestionsLookup.length; i += 1) {
      const item = targetQuestionsLookup[i];
      // if the item was selected other than _or it needs to be reverted
      if (
        item.id !== _identifier &&
        this.parseMultiSelectStateFromId(item.id)
      ) {
        // right now this is saving one by one . But ideally we should do bulks updates or at least Promise all
        const answer = 'No';
        const descriptionIdentifier = item.id;

        this.props.updateMultiSelectFormAnswer(descriptionIdentifier, answer);
      }
    }
  };
  regularQuestionSelected = (_checked, _identifier, _targetSection) => {
    const {
      questionnaireContext: { currentVersionQuestions },
    } = this.props;
    const targetQuestionsLookup = currentVersionQuestions[_targetSection];
    for (let i = 0; i < targetQuestionsLookup.length; i += 1) {
      const item = targetQuestionsLookup[i];
      // if the item was selected other than _or it needs to be reverted
      if (
        item.id.includes('_or') &&
        this.parseMultiSelectStateFromId(item.id)
      ) {
        // can be simplified with the orQuestionSelected on top look for better naming
        const answer = 'No';
        const descriptionIdentifier = item.id;
        this.props.updateMultiSelectFormAnswer(descriptionIdentifier, answer);
      }
    }
  };
  // send the belonging section here
  handlerMultiSelect = (event, _targetSection) => {
    const answer = event.target.checked ? 'Yes' : 'No';
    const descriptionIdentifier = event.target.id;

    if (event.target.id.includes('_or')) {
      this.orQuestionSelected(
        event.target.checked,
        event.target.id,
        _targetSection
      );
    } else {
      // if any other is marked verify that or question is not selected from the targetSection
      this.regularQuestionSelected(
        event.target.checked,
        event.target.id,
        _targetSection
      );
    }
    this.props.updateMultiSelectFormAnswer(descriptionIdentifier, answer);
  };

  categoriesOfBeneficialOwnership = () => {
    const {
      questionnaireContext,
      questionnaireContext: {
        multiSelectFormMetadata: { disabled: disabledList }, x
      },
    } = this.props;
    const categoriesOfBeneficialOwnershipQuestions = questionnaireContext && 
    questionnaireContext.currentVersionQuestions.categoriesOfBeneficialOwnershipQuestions;
   
    const indexToAlpha = (num = 1) => {
      // ASCII value of first character
      const A = 'A'.charCodeAt(0);
      let numberToCharacter = number => {
          return String.fromCharCode(A + number);
      };
      return numberToCharacter(num);
    };

    const getValue = (value) => this.parseQuestionDataFromId(value) ? this.parseQuestionDataFromId(value).description : ''

    const triggerOptions = (id) =>  {
      if (id === 'id_investor_status_7_tcg_m') {
        return { value: getValue('id_investor_status_7_tcg_m'), id: `id_investor_status_7_tcg_m`, input : 'select', 
          selectOptions: ['','a','b','c', 'd','e' ,'f', 'g', 'h', 'i', 'j', 'k', 'l'], changeHandler: this.handleTextInputBlur, style: {width: '60px'} }
      } else if (id === 'id_investor_status_7_tcg_o') {
        return { id: `id_investor_status_7_tcg_o`, style: {width: '500px'}, input : 'text2', 
        reference: (_node) => this.id_investor_status_7_tcg_o_Ref =_node, 
        blurHandler: (e) => this.handleTextInputBlur(e, this.id_investor_status_7_tcg_o_Ref.value)}
                                      
      } else {
         return {}   
      }
    }

     const results = [];
     if (categoriesOfBeneficialOwnershipQuestions) {
      for (
        let i = 0;
        i < categoriesOfBeneficialOwnershipQuestions.length;
        i += 1
      ) {
        results.push({
          id: categoriesOfBeneficialOwnershipQuestions[i].id,
          displayName: ReactHtmlParser(
            categoriesOfBeneficialOwnershipQuestions[i].displayName
          ),
          name: categoriesOfBeneficialOwnershipQuestions[i].name,
          identifier: categoriesOfBeneficialOwnershipQuestions[i].identifier,
          isSelected: this.parseMultiSelectStateFromId(
            categoriesOfBeneficialOwnershipQuestions[i].id
          ),
          helpText: categoriesOfBeneficialOwnershipQuestions[i].helpText,
          showHelp: categoriesOfBeneficialOwnershipQuestions[i].showHelp,
          disabled: disabledList.includes(categoriesOfBeneficialOwnershipQuestions[i].id),
          triggerOptions: triggerOptions(categoriesOfBeneficialOwnershipQuestions[i].id),
          letter: indexToAlpha(i),
           // this part should come from top part lists
        });
      }
      return results;
    }  
  };

  // send the belonging section here
  handlerSingleSelect = (event, _targetSection) => {
    const answer = event.target.checked ? 'Yes' : 'No';
    const descriptionIdentifier = event.target.id;

    this.orQuestionSelected(
      event.target.checked,
      event.target.id,
      _targetSection
    );
    this.props.updateMultiSelectFormAnswer(descriptionIdentifier, answer);
  };

  accreditedInvestorRepresentationsContent = () => {
    const {
      questionnaireContext,
      questionnaireContext: {
        multiSelectFormMetadata: { disabled: disabledList },
      },
    } = this.props;
    const accreditedInvestorRepresentationsQuestions =
      questionnaireContext.currentVersionQuestions
        .accreditedInvestorRepresentations;
    const results = [];
    for (
      let i = 0;
      i < accreditedInvestorRepresentationsQuestions.length;
      i += 1
    ) {
      results.push({
        id: accreditedInvestorRepresentationsQuestions[i].id,
        displayName: ReactHtmlParser(
          accreditedInvestorRepresentationsQuestions[i].displayName
        ),
        name: accreditedInvestorRepresentationsQuestions[i].name,
        identifier: accreditedInvestorRepresentationsQuestions[i].identifier,
        isSelected: this.parseMultiSelectStateFromId(
          accreditedInvestorRepresentationsQuestions[i].id
        ),
        helpText: accreditedInvestorRepresentationsQuestions[i].helpText,
        showHelp: accreditedInvestorRepresentationsQuestions[i].showHelp,
        disabled: disabledList.includes(
          accreditedInvestorRepresentationsQuestions[i].id
        ), // this part should come from top part lists
      });
    }
    return results;
  };

  // This one should be handled as part of the optional questions set at the
  checkBadActorQuestionAbility = (identifier) => {
    if (identifier === iqID.id_investor_status_5_f) {
      if (
        !this.parseMultiSelectStateFromId(iqID.id_investor_status_5_a) &&
        !this.parseMultiSelectStateFromId(iqID.id_investor_status_5_b) &&
        !this.parseMultiSelectStateFromId(iqID.id_investor_status_5_c) &&
        !this.parseMultiSelectStateFromId(iqID.id_investor_status_5_d) &&
        !this.parseMultiSelectStateFromId(iqID.id_investor_status_5_e)
      ) {
        return false; // to show return true;
      }
    }
    return true;
  };

  girw7Part1Content = () => {
    const {
      questionnaireContext,
      questionnaireContext: {
        multiSelectFormMetadata: { disabled: disabledList },
      },
    } = this.props;
    const girw7 =
      questionnaireContext.currentVersionQuestions.girw7;

    const triggerOptions = (id) =>  {
      if (id === 'id_girw_7_i') {
        return { 
          id: `id_girw_7_i`, style: {width: '500px'}, input : 'text2', 
          reference: (_node) => this.id_girw_7_i_Ref =_node, 
          blurHandler: (e) => this.handleTextInputBlur(e, this.id_girw_7_i_Ref.value)
        }
      } else {
         return {}   
      }
    }

    const results = [];
    for (
      let i = 0;
      i < girw7.length;
      i += 1
    ) {
      results.push({
        id: girw7[i].id,
        displayName: ReactHtmlParser(
          girw7[i].displayName
        ),
        name: girw7[i].name,
        identifier: girw7[i].identifier,
        isSelected: this.parseMultiSelectStateFromId(
          girw7[i].id
        ),
        triggerOptions: triggerOptions(girw7[i].id),
        helpText: girw7[i].helpText,
        showHelp: girw7[i].showHelp,
        disabled: disabledList.includes(girw7[i].id), // this part should come from top part lists
      });
    }
    return results;
  };

  girw7Part2Content = () => {
    const {
      questionnaireContext,
      questionnaireContext: {
        multiSelectFormMetadata: { disabled: disabledList },
      },
    } = this.props;
    const girw7_2 = questionnaireContext.currentVersionQuestions.girw7_2;

    const griw7_2CompanyBasis = questionnaireContext.currentVersionQuestions.griw7_2CompanyBasis;

    const griw7_2CompanyBasisOptions = griw7_2CompanyBasis.map((item) => (
      {...item, isSelected: this.parseMultiSelectStateFromId(item.id), disabled: !this.parseMultiSelectStateFromId(iqID.id_girw_7_2_d) }
    ))

    const triggerOptions = (id) =>  {
      if (id === 'id_girw_7_2_d') {
        return { 
            showInput: true,
            input: 'singleSelect',
            selectOptions: griw7_2CompanyBasisOptions, 
            changeHandler: (e) => this.handlerMultiSelect(e,'griw7_2CompanyBasis'),
          }
      } else if (id === 'id_girw_7_2_f') {
        return { 
          showInput: true,
          extraText: 'If applicable, the following person is authorised to carry out transactions on behalf of the undersigned:',
          id: `id_girw_7_2_f`, style: {width: '500px'}, input : 'text2', 
          reference: (_node) => this.id_girw_7_2_f_Ref =_node, 
          blurHandler: (e) => this.handleTextInputBlur(e, this.id_girw_7_2_f_Ref.value)
        }
      } else {
         return {}   
      }
    }

    const results = [];
    for (
      let i = 0;
      i < girw7_2.length;
      i += 1
    ) {
      results.push({
        id:girw7_2[i].id,
        displayName: ReactHtmlParser(
          girw7_2[i].displayName
        ),
        name: girw7_2[i].name,
        identifier: girw7_2[i].identifier,
        isSelected: this.parseMultiSelectStateFromId(
          girw7_2[i].id
        ),
        triggerOptions: triggerOptions(girw7_2[i].id),
        helpText: girw7_2[i].helpText,
        showHelp: girw7_2[i].showHelp,
        disabled: disabledList.includes(girw7_2[i].id), // this part should come from top part lists
      });
    }
    return results;
  };

   girw8Content = () => {
    const {
      questionnaireContext,
      questionnaireContext: {
        multiSelectFormMetadata: { disabled: disabledList },
      },
    } = this.props;
    const girw8 =
      questionnaireContext.currentVersionQuestions.girw8;


    const results = [];
    for (
      let i = 0;
      i < girw8.length;
      i += 1
    ) {
      results.push({
        id: girw8[i].id,
        displayName: ReactHtmlParser(
          girw8[i].displayName
        ),
        name: girw8[i].name,
        identifier: girw8[i].identifier,
        isSelected: this.parseMultiSelectStateFromId(
          girw8[i].id
        ),
        onlyText: girw8[i].type === 'text' ? girw8[i].displayName : null,
        helpText: girw8[i].helpText,
        showHelp: girw8[i].showHelp,
        className: girw8[i].className,
        disabled: disabledList.includes(girw8[i].id), // this part should come from top part lists
      });
    }
    return results;
  };

  qualifiedPurchaserRepresentationsContent = () => {
    const {
      questionnaireContext,
      questionnaireContext: {
        multiSelectFormMetadata: { disabled: disabledList },
      },
    } = this.props;
    const results = [];
    const qualifiedPurchaserRepresentations =
      questionnaireContext.currentVersionQuestions
        .qualifiedPurchaserRepresentations;
    for (let i = 0; i < qualifiedPurchaserRepresentations.length; i += 1) {
      results.push({
        id: qualifiedPurchaserRepresentations[i].id,
        displayName: qualifiedPurchaserRepresentations[i].displayName,
        name: qualifiedPurchaserRepresentations[i].name,
        identifier: qualifiedPurchaserRepresentations[i].identifier,
        isSelected: this.parseMultiSelectStateFromId(
          qualifiedPurchaserRepresentations[i].id
        ),
        helpText: qualifiedPurchaserRepresentations[i].helpText,
        showHelp: qualifiedPurchaserRepresentations[i].showHelp,
        disabled: disabledList.includes(
          qualifiedPurchaserRepresentations[i].id
        ),
      });
    }
    return results;
  };

  qualifiedClientRepresentationsContent = () => {
    const {
      questionnaireContext,
      questionnaireContext: {
        multiSelectFormMetadata: { disabled: disabledList },
      },
    } = this.props;
    const qualifiedClientRepresentations =
      questionnaireContext.currentVersionQuestions
        .qualifiedClientRepresentations;
    const naturalPersonOrCompany = questionnaireContext.currentVersionQuestions.naturalPersonOrCompany;


    const naturalPersonOrCompanyOptions = (disabled) => naturalPersonOrCompany.map((item) => (
      {
        ...item, 
        isSelected: this.parseMultiSelectStateFromId(item.id), 
        disabled: disabled,
      }
    ))

    const triggerOptions = (id) =>  {
      if (id === 'id_client_representations_2') {
        return {
          showInput: true,
          input: 'singleSelect',
          selectOptions: naturalPersonOrCompanyOptions(disabledList.includes(iqID.id_client_representations_2)),
          changeHandler: (e) => this.handlerSingleSelect(e,'naturalPersonOrCompany'),
        }
      } else {
        return {}
      }
    }

    const results = [];
    for (let i = 0; i < qualifiedClientRepresentations.length; i += 1) {
      results.push({
        id: qualifiedClientRepresentations[i].id,
        displayName: qualifiedClientRepresentations[i].displayName,
        name: qualifiedClientRepresentations[i].name,
        identifier: qualifiedClientRepresentations[i].identifier,
        isSelected: this.parseMultiSelectStateFromId(
          qualifiedClientRepresentations[i].id
        ),
        triggerOptions: triggerOptions(qualifiedClientRepresentations[i].id,),
        helpText: qualifiedClientRepresentations[i].helpText,
        showHelp: qualifiedClientRepresentations[i].showHelp,
        disabled: disabledList.includes(qualifiedClientRepresentations[i].id),
      });
    }
    return results;
  };

  foreignPersonStatusContent = () => {
    const {
      questionnaireContext,
      questionnaireContext: {
        multiSelectFormMetadata: { disabled: disabledList },
      },
    } = this.props;
    const foreignPersonStatusRepresentations =
      questionnaireContext.currentVersionQuestions
        .foreignPersonStatusRepresentations;
    const results = [];
    for (let i = 0; i < foreignPersonStatusRepresentations.length; i += 1) {
      results.push({
        id: foreignPersonStatusRepresentations[i].id,
        displayName: foreignPersonStatusRepresentations[i].displayName,
        name: foreignPersonStatusRepresentations[i].name,
        identifier: foreignPersonStatusRepresentations[i].identifier,
        helpText: foreignPersonStatusRepresentations[i].helpText,
        showHelp: foreignPersonStatusRepresentations[i].showHelp,
        isSelected: this.parseMultiSelectStateFromId(
          foreignPersonStatusRepresentations[i].id
        ),
        disabled: disabledList.includes(
          foreignPersonStatusRepresentations[i].id
        ),
      });
    }
    return results;
  };
  foreignPersonStatusContent2 = () => {
    const {
      questionnaireContext,
      questionnaireContext: {
        multiSelectFormMetadata: { disabled: disabledList },
      },
    } = this.props;
    const foreignPersonStatusRepresentations2 =
      questionnaireContext.currentVersionQuestions
        .foreignPersonStatusRepresentations2;
    const results = [];
    for (let i = 0; i < foreignPersonStatusRepresentations2.length; i += 1) {
      results.push({
        id: foreignPersonStatusRepresentations2[i].id,
        displayName: foreignPersonStatusRepresentations2[i].displayName,
        name: foreignPersonStatusRepresentations2[i].name,
        identifier: foreignPersonStatusRepresentations2[i].identifier,
        helpText: foreignPersonStatusRepresentations2[i].helpText,
        showHelp: foreignPersonStatusRepresentations2[i].showHelp,
        isSelected: this.parseMultiSelectStateFromId(
          foreignPersonStatusRepresentations2[i].id
        ),
        className: foreignPersonStatusRepresentations2[i].className,
        disabled: disabledList.includes(
          foreignPersonStatusRepresentations2[i].id,
        ),
      });
    }
    return results;
  };
  // This one should be handled as part of the optional questions set at the

  handleTextInputBlur = (event, currentValue) => {
    event.preventDefault();
    const hasId = [
      'id_investor_status_7_tcg_o',
      'id_investor_status_7_tcg_m',
      'id_girw_7_i',
      'id_girw_7_2_f',
      'id_girw_7_2_d',
    ]

    const isSelect = [
      'id_client_representations_2_a',
      'id_client_representations_2_b',
    ]
     
    const answer = isSelect.includes(event.target.id) ? event.target.id : event.target.value;
    const descriptionIdentifier = event.target.id;
    const hasDescription = hasId.includes(descriptionIdentifier)

    const current = this.parseQuestionDataFromId(descriptionIdentifier);
    if (!current || current.answer !== currentValue && !hasDescription) {
      this.props.updateMultiSelectFormAnswer(descriptionIdentifier, answer);
    }
    if ((!current || current.answer) && hasDescription) {
     this.props.updateMultiSelectFormAnswer(descriptionIdentifier, 'Yes', answer);
      
    }
    
  };

  renderConfirm = (
    id,
    title,
    isSelected,
    name,
    size,
    helpText,
    changeHandler,
    isDisabled
  ) => {
    //eslint-disable-next-line
    if (!isDisabled) isDisabled = [false, false];
    const inputDataTemp = {
      id,
      title,
      isSelected,
      name,
      size,
      showHelp: helpText && helpText !== '',
      helpText,
      isDisabled,
    };
    return (
      <ConfirmCheckbox data={inputDataTemp} onChangeHandler={changeHandler} />
    );
  };

  renderTextInput = (
    name,
    id,
    type,
    value,
    displayName,
    isRequired,
    size,
    hasError,
    errorMessage,
    changeHandler,
    helpText,
    disabled
  ) => {
    const inputDataTemp = {
      name,
      id,
      displayName,
      isRequired,
      size,
      hasError,
      type,
      errorMessage,
      value,
      wrapperClass: '',
      showHelp: helpText && helpText !== '',
      helpText,
      disabled,
      moneyInput: id === 'capitalCommitment',
    };
    return (
      <TextInput
        inputData={inputDataTemp}
        onChangeHandler={changeHandler}
        onBlurHandler={this.handleSaveFieldOnBlur}
      />
    );
  };

  render() {
    const {
      context,
      questionnaireContext,
      questionnaireContext: {
        multiSelectFormMetadata: { optionals, disabled: disabledList },
      },
    } = this.props;
    const { includesFormPFQuestion, includesRegulatoryFund } = questionnaireContext.fund;

    const readableDate =
      questionnaireContext.investorFund.questionnaire &&
      questionnaireContext.investorFund.questionnaire.updatedAt
        ? new Date(questionnaireContext.investorFund.questionnaire.updatedAt)
        : '';
    const formattedDate =
      readableDate !== ''
        ? `${
            readableDate.getMonth() + 1
          }/${readableDate.getDate()}/${readableDate.getFullYear()}  ${readableDate.getHours()}:${readableDate.getMinutes()}:${readableDate.getSeconds()}`
        : readableDate;

    return questionnaireContext.investorFund.questionnaire.questions ? (
      <div className="margin-bottom-spacing">
        <FundTool
          data={{
            saveLaterAble: true,
            modalToggle: 'modal',
            modalTarget: 'saveLaterPopup',
            lastSavedTimeStamp: formattedDate,
          }}
          printHandler={null}
          saveLaterHandler={null}
        />
        {/* <Alert
          inputData={{
            type: context.notificationType,
            title: context.title,
            message: context.message,
          }}
        /> */}
        <div className="row">
          {' '}
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="content-bg main-content-area clearfix">
              <div className="row">
                <QuestionnaireIndex />
                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                  <div className="main">
                    <h4 className="color-dark-green align-header-text-sub-header questionnaire-header">
                      Investor Questionnaire
                    </h4>
                    <b>
                      <Paragraph
                        text={
                          `In connection with the proposed investment in the Fund, the undersigned hereby represents, warrants and agrees as follows:`
                        }
                        className="align-header-text-sub-header"
                      />
                    </b>
                    <br />
                    <form onSubmit={this.handlerFormSubmit}>
                      <Title
                        text={
                          'A. General Investor Representations and Covenants'
                        }
                        className="align-header-text-sub-header"
                        contentId={'content1'}
                      />
                      {questionnaireContext.currentVersionQuestions.generalInvestorRepresentationsandWarranties.map(
                        (item) => (
                          <div key={item.id + item.displayName} className="row">
                            {item.id === 'girw-7' ? 
                              <>
                              <Paragraph text={item.displayName} />
                              <Tooltip data={{ message: item.toolTip, className: 'positioning-tool-tip', }} />
                              <MultiSelect
                                data={{
                                  id: 'org-types-multiselect',
                                  size: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
                                }}
                                content={this.girw7Part1Content()}
                                onChangeHandler={(e) =>
                                  this.handlerMultiSelect(
                                    e,
                                    'girw7'
                                  )
                               } />
                              <Paragraph className={""} text={`and is required to be authorised or regulated to operate in the financial markets.`} />
                              <Paragraph className={""} text={`The list above should be understood as including all authorised entities
                                carrying out the characteristic activities of the entities mentioned above, i.e.
                                entities authorised by an EU Member State under an EU directive, entities
                                authorised or regulated by an EU Member State without reference to an EU
                                directive and entities authorised or regulated by a non-EU Member State.`} />
                              <MultiSelect
                                data={{
                                  id: 'org-types-multiselect',
                                  size: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
                                }}
                                content={this.girw7Part2Content()}
                                onChangeHandler={(e) =>
                                  this.handlerMultiSelect(
                                    e,
                                    'girw7_2'
                                  )
                               } />
                               <Paragraph text={`The undersigned undertakes to provide the General Partner and its
                                affiliates with further information and documents and to make
                                further confirmations insofar it is required for the status of a
                                professional client by law.`} />
                               <Paragraph text={`If the undersigned is a resident of, or domiciled in, any jurisdiction
                                within the European Economic Area or the United Kingdom, the
                                undersigned further represents and warrants that (i) it actively solicited
                                the Fund’s manager in connection with a prospective investment in the
                                Fund, (ii) it approached the Fund’s manager on its own initiative, (iii) its
                                interest in the Fund and the Fund’s manager did not come about as a result
                                of any direct or indirect marketing, offering or placement efforts by or on
                                behalf of the Fund or the Fund’s manager and (iv) neither the European 
                                Union (EU) Directive on Alternative Investment Fund Managers (the
                                “Directive”), the United Kingdom Alternative Investment Fund Managers Regulations,
                                nor any similar national legislation or other
                                non-U.S. marketing, licensing or other regulatory regime that applies
                                to the undersigned’s interest in the Fund.`} />
                                 
                              </>
                             : null}
                             {item.id === 'girw-8' ? <>
                              <Paragraph text={item.displayName} />
                              <MultiSelect
                                data={{
                                  id: 'org-types-multiselect-cfius',
                                  size: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
                                }}
                                content={this.girw8Content()}
                                onChangeHandler={(e) =>
                                  this.handlerMultiSelect(
                                    e,
                                    'girw8'
                                  )
                                }
                              />
                              <br/>
                             </> 
                             : null}
                            {item.id === 'girw-7' || item.id === 'girw-8' 
                            ? null 
                            : <>
                                <Paragraph text={item.displayName} />
                                {item.toolTip && (
                                  <Tooltip
                                    data={{
                                      message: item.toolTip,
                                      className: 'positioning-tool-tip',
                                    }}
                                  />
                                )}
                              </> }
                          </div>
                        )
                      )}
                      <br />
                      <Title
                        text={'B. Identification of Special Entities'}
                        className="align-header-text-sub-header"
                        contentId={'content2'}
                      />
                      <div className="row">
                        <p>
                            In order to identify certain special entities whose investment may
                            require special treatment by the Fund, please carefully answer each of the following
                            questions:
                          </p>
                      </div>
                        <div className="row">
                          {questionnaireContext.currentVersionQuestions.identificationofSpecialEntities.map(
                            (item) => (
                              <div
                                key={item.id + item.displayName}
                                className="row col-lg-12 col-md-12 col-sm-12 col-xs-12"
                              >
                                {item.id === iqID.id_special_entities_3_a ? (
                                  <div
                                    className={`${
                                      disabledList.includes(
                                        'special-entities-lost-descrip-quest'
                                      )
                                        ? 'color-gray'
                                        : ''
                                    } col-lg-10 col-md-10 col-sm-10 col-xs-10 tab-space`}
                                    id="special-entities-lost-descrip-quest"
                                  >
                                    <Paragraph
                                      text={
                                        '(a). any agency, authority or instrumentality of the state or political subdivision;'
                                      }
                                    />
                                    <Paragraph
                                      text={
                                        '(b). a pool of assets sponsored or established by the state or political subdivision or any agency, authority or instrumentality thereof, including but not limited to a “defined benefit plan” as defined in section 414(j) of the Code, or a state general fund;'
                                      }
                                    />
                                    <Paragraph
                                      text={
                                        '(c). a plan or program of a government entity; and'
                                      }
                                    />
                                    <Paragraph
                                      text={
                                        '(d). officers, agents or employees of the state or political subdivision or any agency, authority or instrumentality thereof, acting in their official capacity?'
                                      }
                                    />
                                  </div>
                                ) : null}
                              <div key={item.id + item.displayName} className={`row ${disabledList.includes(item.id) ? 'color-gray' : ''}`}>
                                <div id={item.id}>
                                  {item.type === 'title' && (
                                    <div className="align-text-push" style={ iqID.id_special_entities_4_a === item.id ? {paddingBottom: '20px', marginTop: '-15px'} : {}}>
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <Paragraph text={item.displayName} />
                                    </div>
                                  )}
                                
                                  {item.type === 'quest' &&
                                  this.checkBadActorQuestionAbility(item.id) &&
                                  item.id !== iqID.id_investor_status_1
                                    ? this.renderConfirm(
                                        [
                                          item.radioIdentifier[0],
                                          item.radioIdentifier[1],
                                          item.id,
                                        ],
                                        ReactHtmlParser(item.displayName),
                                        this.parseCheckboxStateFromId(item.id),
                                        item.name,
                                        item.size,
                                        item.tooltip,
                                        this.handlerConfirm,
                                        [
                                          questionnaireContext.isNaturalPerson,
                                          questionnaireContext.isNaturalPerson,
                                        ]
                                      )
                                    : item.type === 'quest' &&
                                      this.checkBadActorQuestionAbility(item.id) &&
                                      item.id === iqID.id_investor_status_1
                                    ? this.renderConfirm(
                                        [
                                          item.radioIdentifier[0],
                                          item.radioIdentifier[1],
                                          item.id,
                                        ],
                                        ReactHtmlParser(item.displayName),
                                        this.parseCheckboxStateFromId(item.id),
                                        item.name,
                                        item.size,
                                        item.tooltip,
                                        this.handlerConfirm,
                                        [
                                          questionnaireContext.isNaturalPerson,
                                          questionnaireContext.isNaturalPerson,
                                        ]
                                      )
                                    : null}
                                  </div>
                                  {item.type === 'opt' && item.id === iqID.id_special_entities_1_b_opt
                                   && this.parseMultiSelectStateFromId(iqID.id_special_entities_1_b)
                                   ? (
                                    <div>
                                      <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10 tab-space">
                                        <Paragraph
                                          className="reduce-bottom-margin"
                                          text={item.displayName}
                                        />
                                      </div>
                                      <TextInput
                                        inputData={{
                                          id: item.id,
                                          isRequired: false,
                                          size: item.size,
                                          hasError: false,
                                          type: 'text',
                                        }}
                                        reference={(_node) => {
                                          this.id_special_entities_1_b_opt_Ref =
                                            _node;
                                        }}
                                        onBlurHandler={(e) =>
                                          this.handleTextInputBlur(
                                            e,
                                            this.id_special_entities_1_b_opt_Ref.value
                                          )
                                        }
                                      />
                                    </div>
                                  ) : null}
                                  {item.type === 'opt' &&
                                  item.id ===
                                    iqID.id_special_entities_2_e_opt &&
                                  optionals.includes(item.id) ? (
                                    <div>
                                      <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10 tab-space" style={{marginLeft: '30px'}}>
                                        <Paragraph
                                          className="reduce-bottom-margin align-text-push"
                                          text={item.displayName}
                                        />
                                      </div>
                                      <div className="">
                                        <TextInput
                                          inputData={{
                                            id: item.id,
                                            isRequired: false,
                                            size: item.size,
                                            hasError: false,
                                            type: 'number',
                                          }}
                                          reference={(_node) => {
                                            this.id_special_entities_2_e_opt_Ref =
                                              _node;
                                          }}
                                          onBlurHandler={(e) =>
                                            this.handleTextInputBlur(
                                              e,
                                              this
                                                .id_special_entities_2_e_opt_Ref
                                                .value
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            )
                          )}
                          
                        </div>
                        {includesFormPFQuestion &&
                      <div className="row" style={{ marginLeft: '-30px' }}>
                        <div className="align-text-push">
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <p>
                              15. Categories of Beneficial Ownership 
                              for Advisers Act Reporting: {' '}
                              The Fund’s management 
                              company or an affiliate thereof may be required,
                              pursuant to the Investment Advisers Act 
                              of 1940 (as amended, the “Advisers Act”), to make periodic filings on Form PF with the 
                              Securities  and Exchange  Commission.  Form PF requires disclosure of, among other information, 
                              the percentage composition of the Fund’s equity by the type of beneficial owner. 
                              The undersigned hereby represents and warrants that the undersigned is one of the following 
                              (check exactly one (1) applicable box that best describes the undersigned):      
                            </p>
                          </div>
                        <MultiSelect
                          data={{
                            id: 'org-types-multiselect',
                            size: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
                          }}
                          content={this.categoriesOfBeneficialOwnership()}
                          onChangeHandler={(e) =>
                            this.handlerSingleSelect(
                              e,
                              'categoriesOfBeneficialOwnershipQuestions'
                            )
                        } />
                      </div>}
                      <br />
                      <Title
                        text={'C. Accredited Investor Representations'}
                        className="align-header-text-sub-header"
                        contentId={'content3'}
                      />
                      <div className="row">
                        <p id="accredited-investor-principal-introduction">
                          <b>
                            The undersigned hereby represents and warrants that
                            the undersigned is an accredited investor under Rule
                            501 of Regulation D promulgated by the Securities
                            and Exchange Commission and that the undersigned
                            qualifies as such because the undersigned is (check
                            applicable box):
                          </b>
                        </p>
                      </div>
                      <div className="row">
                        <MultiSelect
                          data={{
                            id: 'org-types-multiselect',
                            size: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
                          }}
                          content={this.accreditedInvestorRepresentationsContent()}
                          onChangeHandler={(e) =>
                            this.handlerMultiSelect(
                              e,
                              'accreditedInvestorRepresentations'
                            )
                          }
                        />
                      </div>
                      <br />
                      <Title
                        text={'D. Qualified Purchaser Representations'}
                        className="align-header-text-sub-header"
                        contentId={'content4'}
                      />
                      <div className="row">
                        <p id="qualified-purchaser-principal-introduction">
                          <b>
                            1. The undersigned hereby represents and warrants that
                            the undersigned is a Qualified Purchaser under
                            Section 2(a)(51) of the 1940 Act and that the
                            undersigned qualifies as such because the
                            undersigned is (check applicable box):
                          </b>
                        </p>
                      </div>
                      <div className="row">
                        <MultiSelect
                          data={{
                            id: 'org-types-multiselect2',
                            size: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
                          }}
                          content={this.qualifiedPurchaserRepresentationsContent()}
                          onChangeHandler={(e) =>
                            this.handlerMultiSelect(
                              e,
                              'qualifiedPurchaserRepresentations'
                            )
                          }
                        />
                        <Paragraph text={`2. If the undersigned relies on Section 3(c)(1) or Section 3(c)(7) of the 1940 Act
                        for its exclusion from being deemed an investment company, the undersigned
                        hereby represents and warrants that it has obtained the consent to its
                        treatment as a qualified purchaser from the appropriate beneficial owners of
                        its securities in accordance with the requirements of, and only to the extent
                        required by, Section 2(a)(51)(C) of and Rule 2a51-2 under the 1940 Act.`} />
                        <Paragraph text={`3. The undersigned hereby consents to the treatment of the Fund as a qualified
                          purchaser, and represents and warrants that it has obtained the consent for
                          such treatment from the appropriate beneficial owners of its securities in
                          accordance with the requirements of, and only to the extent required by,
                          Section 2(a)(51)(C) and Rule 2a51-2 under the 1940 Act.`} />
                      </div>
                      <br />
                      <br />
                       <Title
                        text={'E. Qualified Client Representations'}
                        className="align-header-text-sub-header"
                        contentId={'content6'}
                      />
                      <div>
                        <div
                          id="qualified-client-principal-introduction"
                          className={`row ${
                            disabledList.includes(
                              'qualified-client-principal-introduction'
                            )
                              ? 'color-gray'
                              : ''
                          }`}
                        >
                          <p>
                            The undersigned hereby represents and warrants that the undersigned is a
                            qualified client under Rule 205-3 of the Investment Advisers Act of 1940
                            promulgated by the Securities and Exchange Commission, and that the
                            undersigned qualifies as such because the undersigned is (check applicable
                            box):
                          </p>
                        </div>
                        <div className="row">
                          <MultiSelect
                            data={{
                              id: 'org-types-multiselect3',
                              size: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
                            }}
                            content={this.qualifiedClientRepresentationsContent()}
                            onChangeHandler={(e) =>
                              this.handlerSingleSelect(
                                e,
                                'qualifiedClientRepresentations'
                              )
                            }
                          />
                        </div>
                      </div>

                      <br />
                      <Title
                        text={'F. Committee on Foreign Investment in the United States (“CFIUS”) Foreign Person Status Representations and Commitments'}
                        className="align-header-text-sub-header"
                        contentId={'content8'}
                      />

                      <Paragraph
                        text={
                          '1. The undersigned hereby represents and warrants that the undersigned is (check any applicable box):'
                        }
                        className="align-header-text-sub-header"
                      />
                      <div className="row bottom-space">
                        <MultiSelect
                          data={{
                            id: 'org-types-multiselect-cfius',
                            size: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
                          }}
                          content={this.foreignPersonStatusContent()}
                          onChangeHandler={(e) =>
                            this.handlerSingleSelect(
                              e,
                              'foreignPersonStatusRepresentations'
                            )
                          }
                        />
                      </div>

                   <div className={`row ${questionnaireContext.isNaturalPerson && 'color-gray'}`}>
                        <p>2. If the undersigned is an entity (i.e., not a natural person), the
                          undersigned further represents and warrants that:
                          <Tooltip data={{ message: `For purposes of determining the percentage of 
                          voting interest held indirectly by one entity in another entity, any voting
                          interest of a parent will be deemed to be a 100 percent 
                          voting interest in any entity of which it is a parent.`, }} />
                          </p>
                          
                             <MultiSelect
                          data={{
                            id: 'org-types-multiselect-cfius-2',
                            size: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
                          }}
                          content={this.foreignPersonStatusContent2()}
                          subheaderText="Or, the undersigned hereby represents and warrants that:"
                          onChangeHandler={(e) =>
                            this.handlerSingleSelect(
                              e,
                              'foreignPersonStatusRepresentations2'
                            )
                          }
                        />

                     <p class="text-justify">  
                   3. The undersigned acknowledges and agrees that its status as a Limited
                    Partner of the Fund or as a designated member of the advisory
                    committee of the Fund shall not confer upon the undersigned any
                    rights to:
                    </p>
                    <div style={{marginLeft: '6%' }}>
                    <p>
                     (a) communicate with any Portfolio Investment or the management
                      thereof regarding the day-to-day operations of its business;
                    </p>
                    <p>
                     (b) become actively involved, directly or indirectly, in the
                      management or operation of the Fund or any Portfolio Investment
                      (outside of participation, if any, on the Limited
                      Partner advisory committee of the Fund);
                      <Tooltip data={{ message: `“Portfolio Investment” shall mean any investment made by the Fund and shall include both the Fund investment itself as well the entity that is the target of such investment.`}} />
                    </p>
                    <p>
                     (c) obtain access to Material Nonpublic Technical Information of the Fund or any Portfolio Investment;
                     <Tooltip data={{ message: `“Material Nonpublic Technical Information” shall have the meaning given to it in Section 721 of the United States
                      Defense Production Act of 1950, as amended, including all implementing regulations thereof.` }} />
                    </p>
                    <p>
                    (d)  obtain membership or observer rights on the board of directors or
                      equivalent governing body of any Portfolio Investment or of the
                      Fund (other than participation, if any, on the Limited Partner
                      advisory committee of the Fund);
                    </p>
                    <p>
                    (e)  become involved with or engage in any substantive
                      decision-making of the Fund or any Portfolio Investment
                      regarding U.S. critical infrastructure, critical technologies, or
                      sensitive personal data as defined under Section 721 of the United
                      States Defense Production Act of 1950, as amended, including all
                      implementing regulations thereof (the “DPA”); or
                    </p>
                    <p>
                     (f)   without the written consent of the General Partner, maintain any
                        other relationship to the Fund or any Portfolio Investment other
                        than (i) the relationship with the Fund permitted under the
                        applicable Partnership Agreement and (ii) ordinary course,
                        independent commercial relationships with Portfolio Investments.
                    </p>
                    </div>
                    <div>
                  <Paragraph text={` The undersigned agrees that it shall promptly notify the General Partner in
                    the event that any foreign government holds or acquires either a Substantial
                    Interest
                    or a greater than ten percent (10%) undiluted equity interest in it
                    or its LP Affiliates.`} />
                  <Tooltip data={{ message: `“Substantial Interest” shall have the meaning given to it in the DPA.
                      <br/>
                      With respect to any Limited Partner, an “LP Affiliate” means: any director, officer, manager, partner, member or
                      similar person, or any 5% or greater equity holder of such Limited Partner, and any person controlling or under common
                      control with such Limited Partner.` }} />
                    </div>
                  <Paragraph text={`The undersigned acknowledges and agrees that it shall use reasonable best
                      efforts to provide the information that may be requested by the General
                      Partner from time to time, and otherwise cooperate with the General
                      Partner, to enable the General Partner and the Fund to comply with the DPA
                      or CFIUS laws, rules, regulations, directives or special measures. The
                      undersigned acknowledges that such information may include such Limited
                      Partner’s (and its LP Affiliates’) holdings, investments, and relationships
                      with United States persons.`} />
                    </div>
                      <br />
                      <Title
                        text={'G. Wiring Bank Status'}
                        className="align-header-text-sub-header"
                        contentId={'content7'}
                      />
                       <div className="row">
                        {questionnaireContext.currentVersionQuestions.wiringBankStatus.map(
                          (item) => (
                            <div
                              key={item.id + item.displayName}
                              className="row"
                            >
                              <div id={item.id}>
                                {item.type === 'title' ? (
                                  <Paragraph text={item.displayName} className={item.className} />
                                ) : null}
                                <div className="col-lg-12 col-xs-12 col-md-12 col-sm-12">
                                  {item.type === 'quest'
                                    ? this.renderConfirm(
                                        [
                                          item.radioIdentifier[0],
                                          item.radioIdentifier[1],
                                          item.id,
                                        ],
                                        item.displayName,
                                        this.parseCheckboxStateFromId(item.id),
                                        item.name,
                                        item.size,
                                        item.tooltip,
                                        this.handlerConfirm
                                      )
                                    : null}
                                </div>
                                {item.type === 'opt' &&
                                item.id === iqID.id_wiring_bank_status_1_opt &&
                                optionals.includes(item.id) ? (
                                  <div>
                                    <Paragraph
                                      className="align-text-push reduce-bottom-margin"
                                      text={item.displayName}
                                    />
                                    <TextInput
                                      inputData={{
                                        id: item.id,
                                        isRequired: false,
                                        size: item.size,
                                        hasError: false,
                                        type: 'text',
                                      }}
                                      reference={(_node) => {
                                        this.id_wiring_bank_status_1_opt_Ref =
                                          _node;
                                      }}
                                      onBlurHandler={(e) =>
                                        this.handleTextInputBlur(
                                          e,
                                          this.id_wiring_bank_status_1_opt_Ref
                                            .value
                                        )
                                      }
                                    />
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          )
                        )}
                        <p>
                            If the undersigned answered “No” to either of the above questions, the
                            General Partner reserves the right to request additional information
                            from the undersigned.
                        </p>
                      </div>
                        <br />
                      <Title
                        text={'H. Miscellaneous'}
                        className="align-header-text-sub-header"
                        contentId={'content9'}
                      />
                      <div className="row">
                        {includesRegulatoryFund &&  
                        <p>
                        1. If the General Partner forms a Regulatory Fund (as defined in the Fund Agreement) 
                          for the purpose of seeking an alternate exemption from registration under the 1940 Act 
                          to the exemption sought by the Fund (such Regulatory Fund, the “1940 Act Regulatory  Fund”),  
                          the  undersigned  agrees  that  the  General  Partner  may,  in  its sole and absolute 
                          discretion, elect to admit the undersigned as a limited partner of the 1940 Act Regulatory 
                          Fund in lieu of admitting the undersigned as a Limited Partner of the Fund; provided, 
                          that the material economic terms of the 1940 Act Regulatory Fund shall be substantially 
                          identical to those of the Fund Agreement.  If  the  General  Partner  elects  to  admit  
                          the  undersigned  to  such  a  1940  Act Regulatory Fund, the undersigned further 
                          acknowledges and agrees that (a) all of the  covenants,  representations  and 
                          warranties  made  by  the  undersigned  in  this Investor   Questionnaire   
                          and   all   exhibits   and   other   documents   information accompanying  
                          the  same  shall  be  deemed  to  have  been  delivered  to  the  General
                            Partner with respect to such 1940 Act Regulatory Fund and all references 
                            to “the Fund” herein shall be deemed to apply to such 1940 Act Regulatory 
                            Fund, and (b) the undersigned’s signature page to the Fund Agreement executed 
                            in connection with the undersigned’s subscription for an interest in the Fund 
                            shall be deemed to be a counterpart signature page to the limited partnership 
                            agreement of such 1940 Act Regulatory Fund and may be attached as a counterpart 
                            signature page to such agreement  (in  which  case  the  undersigned  agrees  
                            that  the  undersigned  shall  be deemed to have made, and shall be subject to, 
                            all of the covenants, representations, warranties  and  agreements  of  the 
                            limited  partners  set  forth  in  the  limited partnership  agreement  of 
                              the  1940  Act  Regulatory  Fund  to  the  extent  such covenants,  
                              representations,  warranties  and  agreements  are  substantially  
                              identical in form and substance to the covenants, representations, 
                              warranties and agreements of  the  limited  partners  set  forth  in 
                              the  Fund  Agreement).   In  such  event,  the undersigned hereby 
                              authorizes the General Partner to redact or otherwise cross out any 
                                reference  to  the  name  of  the  Fund  on  any  such  signature 
                                page  to  the  Fund Agreement, and to insert the name of the 1940 
                                Act Regulatory Fund in its place.
                        </p>}

                      {questionnaireContext.currentVersionQuestions.miscellaneous.map(
                        (item, i) => (
                                 <p>
                                    {includesRegulatoryFund ? i + 2 : i + 1}. {item.displayName}
                                 </p>
                            ))}
                      </div>
                      <div className="row color-section-9">
                        <TextArea
                          inputData={{
                            id: questionnaireContext.currentVersionQuestions
                              .warrantiesExceptions[0].id,
                            displayName:
                              questionnaireContext.currentVersionQuestions
                                .warrantiesExceptions[0].displayName,
                            isRequired: false,
                            hasError: false,
                            title: '',
                            description:
                              questionnaireContext.currentVersionQuestions
                                .warrantiesExceptions[0].description,
                          }}
                          reference={(_node) => {
                            this.id_warranties_exceptions_Ref = _node;
                          }}
                          onBlurHandler={(e) =>
                            this.handleTextInputBlur(
                              e,
                              this.id_warranties_exceptions_Ref.value
                            )
                          }
                        />
                      </div>
                      <div className="margin-bottom-spacing">
                        <div className="back-btn">
                          <Button
                            inputData={{
                              id: 'back-btn',
                              displayName: 'Back',
                              type: 'button',
                            }}
                            clickHandler={this.backStep}
                          />
                        </div>
                        <div className="next-btn">
                          <Button
                            inputData={{
                              id: 'next-btn',
                              displayName: 'Next',
                              type: 'submit',
                            }}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <p>'...'</p>
    );
  }
}

const mapStateToProps = (state: Reducer) => ({
  context: state.context,
  questionnaireContext: state.questionnaireExperience,
});
const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  (dispatch: Dispatch) => ({})
);

export default connector(QuestionnaireForm);
