/* eslint-disable react/sort-comp,no-unused-vars */
/* @flow */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import type { Connector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Notifications from 'react-notify-toast';
import Helmet from 'react-helmet';
import * as action from './action';
import * as validator from './../../utils/form-validation';
import * as utility from './../../utils/utility';
import * as notify from './../../utils/notify';
import './styles.scss';
import { Context as ContextType, Dispatch, Reducer } from '../../types/index';
import Button from '../../components/Button/index';
import Footer from '../../components/Footer/index';
import DynamicHeader from '../../components/DynamicHeader/index';
import TextInput from '../../components/TextInput/index';
import Alert from '../../components/Alert/index';
import App from '../App';
import { webDomainClient } from '../../utils/client-access';

type Props = {
	history: Object,
	context: ContextType,
	login: () => void,
	loginToken: () => void,
	sendVerificationEmail: () => void,
	temporalLogin: () => void,
	notifyIE: () => {},
	disableLogin: () => {},
	verifyIQUser: () => void,
};

const initialState = {
	emailAddress: {
		value: '',
		valid: true,
		isRequired: true,
		errorMessage: '',
		validators: ['required'],
	},
	password: {
		value: '',
		valid: true,
		isRequired: true,
		errorMessage: '',
		validators: ['required'],
	},
	forgotPass: { error: false, message: '', type: '', title: '' },
};

export class OrganizationLogin extends PureComponent {
	props: Props;

	static defaultProps: {
		history: {},
		context: { canLogIn: true },
		login: () => {},
		loginToken: () => {},
		sendVerificationEmail: () => {},
		temporalLogin: () => {},
		notifyIE: () => {},
		disableLogin: () => {},
		verifyIQUser: () => {},
	};

	constructor(state) {
		super(state);
		this.state = initialState;
		this.handleLoginSubmit = this.handleLoginSubmit.bind(this);
		this.handleEmailAddressChange = this.handleEmailAddressChange.bind(this);
		this.handlePasswordChange = this.handlePasswordChange.bind(this);
		this.updateStateFromValidation = this.updateStateFromValidation.bind(this);
		this.handleForgotPassword = this.handleForgotPassword.bind(this);
	}

  componentDidMount() {
    const {
      loginToken,
      temporalLogin,
      verifyIQUser,
      context,
      history,
    } = this.props;
    if (document.title !== 'Fund Formation Portal - Organization Login')
      notify.hideNotification();
    if (utility.getUrlParameter('tab')) {
      let extraQueries = '';
      if (utility.getUrlParameter('continue')) {
        extraQueries = `&continue=${utility.getUrlParameter('continue')}`;
      }
      history.push(`/signup?tab=1${extraQueries}`);
    } else {
      if (
        context.organization &&
        context.organization._id &&
        !utility.getUrlParameter('rs') &&
        !utility.getUrlParameter('verify')
      ) {
        loginToken(history);
      }
      if (
        localStorage.getItem('organization-auth-token') &&
        !utility.getUrlParameter('rs') &&
        !utility.getUrlParameter('verify')
      ) {
        loginToken(history);
      }
      if (utility.getUrlParameter('rs')) {
        temporalLogin(
          utility.getUrlParameter('e'),
          utility.getUrlParameter('rs'),
          history
        );
      } else if (utility.getUrlParameter('verify')) {
        verifyIQUser(
          utility.getUrlParameter('email'),
          utility.getUrlParameter('verify'),
          history
        );
      }
      if (window) {
        if (
          navigator.userAgent.indexOf('MSIE ') > -1 ||
          navigator.userAgent.indexOf('Trident') > -1 ||
          navigator.userAgent.indexOf('Edge') > -1 ||
          /MSIE 10/i.test(navigator.userAgent) ||
          /MSIE 9/i.test(navigator.userAgent) ||
          /rv:11.0/i.test(navigator.userAgent) ||
          /Edg\/\d./i.test(navigator.userAgent)
        ) {
          this.props.notifyIE();
          this.props.disableLogin();
          // alert(parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10));
        }
      }
    }
  }

	handleEmailAddressChange(event) {
		event.preventDefault();
		this.setState(
			{
				...this.state,
				emailAddress: {
					...this.state.emailAddress,
					value: event.target.value,
				},
			},
			() => {
				validator.runFieldValidations(
					this.state.emailAddress,
					'emailAddress',
					this.state.emailAddress.value,
					this.updateStateFromValidation
				);
			}
		);
	}

	handlePasswordChange(event) {
		event.preventDefault();
		this.setState(
			{
				...this.state,
				password: {
					...this.state.password,
					value: event.target.value,
				},
			},
			() => {
				validator.runFieldValidations(
					this.state.password,
					'password',
					this.state.password.value,
					this.updateStateFromValidation
				);
			}
		);
	}
	handleForgotPassword(event) {
		event.preventDefault();
		const { sendVerificationEmail } = this.props;
		const forgotLink = document.getElementById('forgotPasswordLink');
		forgotLink.style.opacity = 0.3;
		if (this.state.emailAddress.value === '') {
			this.setState({
				...this.state,
				forgotPass: {
					...this.state.forgotPass,
					error: true,
					message: 'Enter your email to reset your password!',
					type: 'warning',
					title: 'Warning',
				},
			});
		} else if (this.validateEmail(this.state.emailAddress.value)) {
			sendVerificationEmail(this.state.emailAddress.value);
		} else {
			this.setState({
				...this.state,
				forgotPass: {
					...this.state.forgotPass,
					error: true,
					message: 'Enter a valid email to reset your password!',
					type: 'error',
					title: 'Error',
				},
			});
		}
		setTimeout(() => {
			this.setState({
				...this.state,
				forgotPass: {
					...this.state.forgotPass,
					error: false,
					message: '',
					type: '',
					title: '',
				},
			});
			forgotLink.style.opacity = 1;
		}, 3000);
	}
	handleLoginSubmit(event) {
		event.preventDefault();
		const { login, history } = this.props;
		login(this.state.emailAddress.value, this.state.password.value, history);
	}
	validateEmail() {
		// eslint-disable-next-line no-useless-escape
		const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
		if (reg.test(this.state.emailAddress.value) === false) {
			return false;
		}
		return true;
	}
	renderTextInput = (
		id,
		type,
		displayName,
		placeholder,
		isRequired,
		size,
		hasError,
		errorMessage,
		changeHandler,
		disabled
	) => {
		const inputDataTemp = {
			id,
			displayName,
			isRequired,
			size,
			hasError,
			type,
			noTitle: true,
			errorMessage,
			placeholder,
			disabled,
		};
		return <TextInput inputData={inputDataTemp} onChangeHandler={changeHandler} />;
	};

	updateStateFromValidation(validationResult, fieldName, value, errMessage) {
		this.setState({
			...this.state,
			[fieldName]: {
				...this.state[fieldName],
				value,
				valid: validationResult,
				errorMessage: errMessage,
			},
		});
	}

	render() {
		const { context } = this.props;
		const disabledInputs = !context.canLogIn;
		return (
			<div className='main-container'>
				<Helmet title='Organization Login' />
				<DynamicHeader
					type={'user'}
					homePath={'/'}
					data={{
						name: context.profile.name,
						// profileHandler: this.handleProfile,
						// logoutHandler: this.handleLogout,
					}}
				/>
				<div className='container'>
					{/* <Alert
            inputData={{
              showAlways: context.showAlways,
              type: context.notificationType,
              title: context.title,
              message: context.message,
            }}
          /> */}
					<Notifications />
					<div className='userlogin-section'>
						<div className='row'>
							<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
								<h3 className='color-dark-green text-align-center'>Fund Formation Portal</h3>
							</div>
						</div>
						<div className='row'>
							<div className='col-lg-7 col-md-9 col-sm-11 col-xs-12 center-block'>
								{!this.state.primaryAuthReady ? (
									<form onSubmit={this.handleLoginSubmit}>
										<div className='userlogin-main text-align-center content-bg'>
											<h6 className='color-black'>investor Login</h6>
											{this.renderTextInput(
												'login-email',
												'text',
												'Email',
												'Email Address',
												true,
												'large',
												!this.state.emailAddress.valid,
												this.state.emailAddress.errorMessage,
												this.handleEmailAddressChange,
												disabledInputs
											)}
											{this.renderTextInput(
												'login-password',
												'password',
												'Password',
												'Password',
												true,
												'large',
												!this.state.password.valid,
												this.state.password.errorMessage,
												this.handlePasswordChange,
												disabledInputs
											)}
											<Link
												style={{
													marginLeft: '-115px',
													marginTop: '110px',
													position: 'absolute',
												}}
												to='/forgotPassword'
												target='_blank'>
												Forgot password?
											</Link>
											<div className='forgot-password-container'>
												<br />
												<br />
												{this.state.forgotPass.error ? (
													<div className='error-message not-match-pass red-color'>
														<span className='text'>{this.state.forgotPass.message}</span>
													</div>
												) : (
													<div className='error-message not-match-pass green-color'>
														<span className='text'>{this.state.forgotPass.message}</span>
													</div>
												)}
											</div>
											<hr style={{ marginTop: '35px' }} />
											<Button
												inputData={{
													id: 'admin-user-submit',
													displayName: 'Sign In',
													type: 'submit',
													isDisabled: disabledInputs,
												}}
												clickHandler={this.handleLoginSubmit}
											/>
											<div className='col-lg-12 col-md-12 col-xs-12'>
												<p className='sign-up-sec-wrapper'>
													Don&#39;t have an account? <Link to='/signup'>Sign Up to Create an Account.</Link>
												</p>
											</div>
										</div>
									</form>
								) : null}
							</div>
								<div style={{marginTop: '30px'}}>
									<div className='col-lg-7 col-md-9 col-sm-11 col-xs-12 center-block'>
										<div className='text-align-center content-bg login-box-parent'>
											<div className='go-to-login-box'>
												<div>
													<h4>Client Access (beta)</h4>
													<p>For Gunderson Dettmer clients who have been granted access by their fund formation team.</p>
												</div>
												<div>
												<a href={webDomainClient}>
													<Button
													inputData={{
														id: 'go-to-client-login',
														displayName: 'Go to Client Login',
														type: 'button',
													}}
													clickHandler={() => {}}
												/>
												</a>
												</div>
											</div>
										</div>
									</div>
								</div>
						</div>
					</div>
				</div>
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<Footer />
			</div>
		);
	}
}
const mapStateToProps = (state: Reducer) => ({ context: state.context });
const mapDispatchToProps = (dispatch: Dispatch) => ({
	login: (emailAddress, password, history) => dispatch(action.login(emailAddress, password, history)),
	loginToken: (history) => dispatch(action.loginToken(history)),
	temporalLogin: (email, rs, history) => dispatch(action.temporalLogin(email, rs, history)),
	notifyIE: () =>
		dispatch({
			type: 'RENDER_NOTIFICATION',
			notificationType: 'error',
			message: 'Please change to Chrome or Firefox',
			title: 'You are using an unsupported browser',
			showAlways: true,
		}),
	disableLogin: () => dispatch({ type: 'SET_CAN_LOG_IN', canLogIn: false }),
	verifyIQUser: (email, verifyToken, history) => dispatch(action.verifyIQUser(email, verifyToken, history)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrganizationLogin));
