/* eslint-disable react/sort-comp,import/prefer-default-export,class-methods-use-this,max-len,spaced-comment,consistent-return */
/* @flow */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import type { Connector } from 'react-redux';
import * as notify from '../../../../../../utils/notify';
import Dropdown from '../../../../../../components/Dropdown/index';
import TextInput from '../../../../../../components/TextInput/index';
import Title from '../../../../../../components/Title/index';
import '../../GeneralInformation/styles.scss';
import SubTitle from '../../../../../../components/SubTitle/index';
import Button from '../../../../../../components/Button/index';
import {
  Context as ContextType,
  QuestionnaireExperience as QuestionnaireExperienceContext,
  Reducer,
  Dispatch,
} from '../../../../../../types/index';
import MultiSelect from '../../../../../../components/MultiSelect/index';
import FundTool from '../../../../../../components/FundTool/index';
import Alert from '../../../../../../components/Alert/index';
import Paragraph from '../../../../../../components/Paragraph/index';
import {
  IQ_SET_LAST_FOCUSED_FIELD,
  IQ_IS_SAVING_GENERAL_INFORMATION,
} from '../../../../reducer';
import * as iqID from '../../../../../../utils/constants/questionnaire/identifiers';
import { saveGeneralInformation } from '../../../../util';
import {
  caStatesAbbr,
  usStatesAbbr,
  countries,
  checkSelectedCountries,
  checkOptionals,
  CANADA,
  USA,
  permittedStates,
  permittedCountries,
} from '../../../../../../utils/location.util';

type Props = {
  history: Object,
  context: ContextType,
  questionnaireContext: QuestionnaireExperienceContext,
};

const checkOptions = [
  {
    id: 'org-type-radio-1',
    displayName: 'Corporation',
    isSelected: false,
    name: '',
    identifier: 'Corporation',
  },
  {
    id: 'org-type-radio-2',
    displayName: 'Estate',
    isSelected: false,
    name: '',
    identifier: 'Estate',
  },
  {
    id: 'org-type-radio-3',
    displayName: 'US Tax-Exempt Organization',
    isSelected: false,
    name: '',
    identifier: 'US Tax-Exempt Organization',
  },
  {
    id: 'org-type-radio-4',
    displayName: 'Fiduciary',
    isSelected: false,
    name: '',
    identifier: 'Fiduciary',
  },
  {
    id: 'org-type-radio-5',
    displayName: 'Grantor Trust',
    isSelected: false,
    name: '',
    identifier: 'Grantor Trust',
  },
  {
    id: 'org-type-radio-6',
    displayName: 'Natural Person',
    isSelected: false,
    name: '',
    identifier: 'Natural Person',
  },
  {
    id: 'org-type-radio-7',
    displayName: 'Limited Liability Company',
    isSelected: false,
    name: '',
    identifier: 'Limited Liability Company',
  },
  {
    id: 'org-type-radio-8',
    displayName: 'Limited Partnership',
    isSelected: false,
    name: '',
    identifier: 'Limited Partnership',
  },
  {
    id: 'org-type-radio-9',
    displayName: 'Limited Liability Partnership',
    isSelected: false,
    name: '',
    identifier: 'Limited Liability Partnership',
  },
  {
    id: 'org-type-radio-10',
    displayName: 'Nominee',
    isSelected: false,
    name: '',
    identifier: 'Nominee',
  },
  {
    id: 'org-type-radio-11',
    displayName: 'General Partnership',
    isSelected: false,
    name: '',
    identifier: 'General Partnership',
  },
  {
    id: 'org-type-radio-12',
    displayName: 'Individual Retirement Account',
    isSelected: false,
    name: '',
    identifier: 'IRA',
  },
  {
    id: 'org-type-radio-13',
    displayName: 'S-Corporation',
    isSelected: false,
    name: '',
    identifier: 'S-Corporation',
  },
  {
    id: 'org-type-radio-14',
    displayName: 'Revocable Trust',
    isSelected: false,
    name: '',
    identifier: 'Revocable Trust',
  },
  {
    id: 'org-type-radio-15',
    displayName: 'Irrevocable Trust',
    isSelected: false,
    name: '',
    identifier: 'Irrevocable Trust',
  },
  {
    id: 'org-type-radio-16',
    displayName: 'Business Trust',
    isSelected: false,
    name: '',
    identifier: 'Business Trust',
  },
  {
    id: 'org-type-radio-17',
    displayName: 'Joint Tenants with Right of Survivorship',
    isSelected: false,
    name: '',
    identifier: 'Joint Tenants with Right of Survivorship',
  },
  {
    id: 'org-type-radio-18',
    displayName: 'Tenants in Common',
    isSelected: false,
    name: '',
    identifier: 'Tenants in Common',
  },
  {
    id: 'org-type-radio-other-1',
    displayName: 'Other - Entity',
    isSelected: false,
    name: '',
    identifier: 'Other - Entity',
  },
  {
    id: 'org-type-radio-other-2',
    displayName: 'Other - Individual',
    isSelected: false,
    name: '',
    identifier: 'Other - Individual',
  }
];

const initialState = {
  previousInfo: false,
  name: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  signatory: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  tax: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  country: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  countryPlace: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  types: [],
  otherValue: '',
  dayBirth: '',
  monthBirth: '',
  yearBirth: '',
  namePrimaryContact: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  emailPrimaryContact: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  addressPrimaryContact: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  principalPlaceOfResidence: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  principalPlaceOfResidencePrimaryContact: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  principalPlaceOfBusiness: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  principalPlaceOfBusinessPrimaryContact: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  jurisdictionOrganitzationPrimaryContact: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },

  phonePrimaryContact: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  capitalCommitment: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required', 'number'],
  },
  reportsPrimaryContactName: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  reportsPrimaryContactEmail: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  reportsPrimaryContactPhone: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  reportsSecondaryContactName: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  reportsSecondaryContactEmail: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  reportsSecondaryContactPhone: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  taxPrimaryContactName: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  taxPrimaryContactEmail: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  taxPrimaryContactPhone: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  taxSecondaryContactName: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  taxSecondaryContactEmail: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  taxSecondaryContactPhone: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  capitalPrimaryContactName: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  capitalPrimaryContactEmail: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  capitalPrimaryContactPhone: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  capitalSecondaryContactName: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  capitalSecondaryContactEmail: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  capitalSecondaryContactPhone: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  stockPrimaryContactName: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  stockPrimaryContactEmail: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  stockPrimaryContactPhone: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  stockSecondaryContactName: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  stockSecondaryContactEmail: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  stockSecondaryContactPhone: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  legalPrimaryContactName: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  legalPrimaryContactEmail: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  legalPrimaryContactPhone: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  legalSecondaryContactName: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  legalSecondaryContactEmail: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  legalSecondaryContactPhone: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  bankName: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  acctName: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  swiftCode: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  acctNumber: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  bankaba: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  furtherCredit: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  location: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    validators: ['required'],
  },
  lastFocusedField: {},
  unsavedInfo: false,
  ignoreFocus: false,
};
// Export this for unit testing more easily
export class GeneralInfo extends PureComponent {
  props: Props;
  state = initialState;
  static defaultProps: {
    history: {},
    context: {},
  };

  componentDidMount() {
    notify.hideNotification();
    this.checkPreviousInformation();
  }

  mapDateOfBirthFields = (birth) => {
    if (birth && birth.length) {
      const splittedResult = birth.split('-');
      const day = splittedResult[1];
      const month = splittedResult[0];
      const year = splittedResult[2];
      return {
        dayBirth: day,
        monthBirth: month,
        yearBirth: year,
      };
    }
  };

  checkPreviousInformation = () => {
    const { questionnaireContext } = this.props;
    if (
      questionnaireContext.investorFund.generalInfo &&
      questionnaireContext.investorFund.generalInfo._id
    ) {
      const birthData = this.mapDateOfBirthFields(
        questionnaireContext.investorFund.generalInfo.birth
      );
      this.setState(
        {
          unsavedInfo: false,
          previousInfo: true,
          ignoreFocus: questionnaireContext.lastFocusedField.ignoreFocus,
          lastFocusedField: questionnaireContext.lastFocusedField,
          ...birthData,
          types: questionnaireContext.investorFund.generalInfo.types || [],
          otherValue: questionnaireContext.investorFund.generalInfo.otherValue,
          name: {
            ...this.state.name,
            value: questionnaireContext.investorFund.generalInfo.name || '',
          },
          signatory: {
            ...this.state.signatory,
            value:
              questionnaireContext.investorFund.generalInfo.signatory || '',
          },
          tax: {
            ...this.state.tax,
            value: questionnaireContext.investorFund.generalInfo.tax || '',
          },
          country: {
            ...this.state.country,
            value: permittedCountries.includes(
              questionnaireContext.investorFund.generalInfo.country
            )
              ? questionnaireContext.investorFund.generalInfo.country
              : USA,
          },
          countryPlace: {
            ...this.state.countryPlace,
            value: permittedCountries.includes(
              questionnaireContext.investorFund.generalInfo.countryPlace
            )
              ? questionnaireContext.investorFund.generalInfo.countryPlace
              : USA,
          },
          namePrimaryContact: {
            ...this.state.namePrimaryContact,
            value:
              questionnaireContext.investorFund.generalInfo
                .namePrimaryContact || '',
          },
          emailPrimaryContact: {
            ...this.state.emailPrimaryContact,
            value:
              questionnaireContext.investorFund.generalInfo
                .emailPrimaryContact || '',
          },
          addressPrimaryContact: {
            ...this.state.addressPrimaryContact,
            value:
              questionnaireContext.investorFund.generalInfo
                .addressPrimaryContact || '',
          },
          phonePrimaryContact: {
            ...this.state.phonePrimaryContact,
            value:
              questionnaireContext.investorFund.generalInfo
                .phonePrimaryContact || '',
          },
          capitalCommitment: {
            ...this.state.capitalCommitment,
            value:
              questionnaireContext.investorFund.generalInfo.capitalCommitment,
          },
          principalPlaceOfResidence: {
            ...this.state.principalPlaceOfResidence,
            value:
              questionnaireContext.investorFund.generalInfo
                .principalPlaceOfResidence || '',
          },
          principalPlaceOfResidencePrimaryContact: {
            ...this.state.principalPlaceOfResidencePrimaryContact,
            value: permittedStates.includes(
              questionnaireContext.investorFund.generalInfo
                .principalPlaceOfResidencePrimaryContact
            )
              ? questionnaireContext.investorFund.generalInfo
                  .principalPlaceOfResidencePrimaryContact
              : '',
          },
          principalPlaceOfBusiness: {
            ...this.state.principalPlaceOfBusiness,
            value:
              questionnaireContext.investorFund.generalInfo
                .principalPlaceOfBusiness || '',
          },
          principalPlaceOfBusinessPrimaryContact: {
            ...this.state.principalPlaceOfBusinessPrimaryContact,
            value: permittedStates.includes(
              questionnaireContext.investorFund.generalInfo
                .principalPlaceOfBusinessPrimaryContact
            )
              ? questionnaireContext.investorFund.generalInfo
                  .principalPlaceOfBusinessPrimaryContact
              : '',
          },
          jurisdictionOrganitzationPrimaryContact: {
            ...this.state.jurisdictionOrganitzationPrimaryContact,
            value:
              questionnaireContext.investorFund.generalInfo
                .jurisdictionOrganitzationPrimaryContact || '',
          },
          reportsPrimaryContactName: {
            ...this.state.reportsPrimaryContactName,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .reportsPrimaryContactName
                ? questionnaireContext.investorFund.limitedPartner
                    .reportsPrimaryContactName
                : questionnaireContext.investorFund.generalInfo
                    .reportsPrimaryContactName || '',
          },
          reportsPrimaryContactEmail: {
            ...this.state.reportsPrimaryContactEmail,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .reportsPrimaryContactEmail
                ? questionnaireContext.investorFund.limitedPartner
                    .reportsPrimaryContactEmail
                : questionnaireContext.investorFund.generalInfo
                    .reportsPrimaryContactEmail || '',
          },
          reportsPrimaryContactPhone: {
            ...this.state.reportsPrimaryContactPhone,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .reportsPrimaryContactPhone
                ? questionnaireContext.investorFund.limitedPartner
                    .reportsPrimaryContactPhone
                : questionnaireContext.investorFund.generalInfo
                    .reportsPrimaryContactPhone || '',
          },
          reportsSecondaryContactName: {
            ...this.state.reportsSecondaryContactName,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .reportsSecondaryContactName
                ? questionnaireContext.investorFund.limitedPartner
                    .reportsSecondaryContactName
                : questionnaireContext.investorFund.generalInfo
                    .reportsSecondaryContactName || '',
          },
          reportsSecondaryContactEmail: {
            ...this.state.reportsSecondaryContactEmail,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .reportsSecondaryContactEmail
                ? questionnaireContext.investorFund.limitedPartner
                    .reportsSecondaryContactEmail
                : questionnaireContext.investorFund.generalInfo
                    .reportsSecondaryContactEmail || '',
          },
          reportsSecondaryContactPhone: {
            ...this.state.reportsSecondaryContactPhone,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .reportsSecondaryContactPhone
                ? questionnaireContext.investorFund.limitedPartner
                    .reportsSecondaryContactPhone
                : questionnaireContext.investorFund.generalInfo
                    .reportsSecondaryContactPhone || '',
          },
          taxPrimaryContactName: {
            ...this.state.taxPrimaryContactName,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .taxPrimaryContactName
                ? questionnaireContext.investorFund.limitedPartner
                    .taxPrimaryContactName
                : questionnaireContext.investorFund.generalInfo
                    .taxPrimaryContactName || '',
          },
          taxPrimaryContactEmail: {
            ...this.state.taxPrimaryContactEmail,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .taxPrimaryContactEmail
                ? questionnaireContext.investorFund.limitedPartner
                    .taxPrimaryContactEmail
                : questionnaireContext.investorFund.generalInfo
                    .taxPrimaryContactEmail || '',
          },
          taxPrimaryContactPhone: {
            ...this.state.taxPrimaryContactPhone,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .taxPrimaryContactPhone
                ? questionnaireContext.investorFund.limitedPartner
                    .taxPrimaryContactPhone
                : questionnaireContext.investorFund.generalInfo
                    .taxPrimaryContactPhone || '',
          },
          taxSecondaryContactName: {
            ...this.state.taxSecondaryContactName,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .taxSecondaryContactName
                ? questionnaireContext.investorFund.limitedPartner
                    .taxSecondaryContactName
                : questionnaireContext.investorFund.generalInfo
                    .taxSecondaryContactName || '',
          },
          taxSecondaryContactEmail: {
            ...this.state.taxSecondaryContactEmail,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .taxSecondaryContactEmail
                ? questionnaireContext.investorFund.limitedPartner
                    .taxSecondaryContactEmail
                : questionnaireContext.investorFund.generalInfo
                    .taxSecondaryContactEmail || '',
          },
          taxSecondaryContactPhone: {
            ...this.state.taxSecondaryContactPhone,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .taxSecondaryContactPhone
                ? questionnaireContext.investorFund.limitedPartner
                    .taxSecondaryContactPhone
                : questionnaireContext.investorFund.generalInfo
                    .taxSecondaryContactPhone || '',
          },
          capitalPrimaryContactName: {
            ...this.state.capitalPrimaryContactName,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .capitalPrimaryContactName
                ? questionnaireContext.investorFund.limitedPartner
                    .capitalPrimaryContactName
                : questionnaireContext.investorFund.generalInfo
                    .capitalPrimaryContactName || '',
          },
          capitalPrimaryContactEmail: {
            ...this.state.capitalPrimaryContactEmail,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .capitalPrimaryContactEmail
                ? questionnaireContext.investorFund.limitedPartner
                    .capitalPrimaryContactEmail
                : questionnaireContext.investorFund.generalInfo
                    .capitalPrimaryContactEmail || '',
          },
          capitalPrimaryContactPhone: {
            ...this.state.capitalPrimaryContactPhone,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .capitalPrimaryContactPhone
                ? questionnaireContext.investorFund.limitedPartner
                    .capitalPrimaryContactPhone
                : questionnaireContext.investorFund.generalInfo
                    .capitalPrimaryContactPhone || '',
          },
          capitalSecondaryContactName: {
            ...this.state.capitalSecondaryContactName,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .capitalSecondaryContactName
                ? questionnaireContext.investorFund.limitedPartner
                    .capitalSecondaryContactName
                : questionnaireContext.investorFund.generalInfo
                    .capitalSecondaryContactName || '',
          },
          capitalSecondaryContactEmail: {
            ...this.state.capitalSecondaryContactEmail,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .capitalSecondaryContactEmail
                ? questionnaireContext.investorFund.limitedPartner
                    .capitalSecondaryContactEmail
                : questionnaireContext.investorFund.generalInfo
                    .capitalSecondaryContactEmail || '',
          },
          capitalSecondaryContactPhone: {
            ...this.state.capitalSecondaryContactPhone,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .capitalSecondaryContactPhone
                ? questionnaireContext.investorFund.limitedPartner
                    .capitalSecondaryContactPhone
                : questionnaireContext.investorFund.generalInfo
                    .capitalSecondaryContactPhone || '',
          },
          stockPrimaryContactName: {
            ...this.state.stockPrimaryContactName,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .stockPrimaryContactName
                ? questionnaireContext.investorFund.limitedPartner
                    .stockPrimaryContactName
                : questionnaireContext.investorFund.generalInfo
                    .stockPrimaryContactName || '',
          },
          stockPrimaryContactEmail: {
            ...this.state.stockPrimaryContactEmail,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .stockPrimaryContactEmail
                ? questionnaireContext.investorFund.limitedPartner
                    .stockPrimaryContactEmail
                : questionnaireContext.investorFund.generalInfo
                    .stockPrimaryContactEmail || '',
          },
          stockPrimaryContactPhone: {
            ...this.state.stockPrimaryContactPhone,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .stockPrimaryContactPhone
                ? questionnaireContext.investorFund.limitedPartner
                    .stockPrimaryContactPhone
                : questionnaireContext.investorFund.generalInfo
                    .stockPrimaryContactPhone || '',
          },
          stockSecondaryContactName: {
            ...this.state.stockSecondaryContactName,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .stockSecondaryContactName
                ? questionnaireContext.investorFund.limitedPartner
                    .stockSecondaryContactName
                : questionnaireContext.investorFund.generalInfo
                    .stockSecondaryContactName || '',
          },
          stockSecondaryContactEmail: {
            ...this.state.stockSecondaryContactEmail,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .stockSecondaryContactEmail
                ? questionnaireContext.investorFund.limitedPartner
                    .stockSecondaryContactEmail
                : questionnaireContext.investorFund.generalInfo
                    .stockSecondaryContactEmail || '',
          },
          stockSecondaryContactPhone: {
            ...this.state.stockSecondaryContactPhone,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .stockSecondaryContactPhone
                ? questionnaireContext.investorFund.limitedPartner
                    .stockSecondaryContactPhone
                : questionnaireContext.investorFund.generalInfo
                    .stockSecondaryContactPhone || '',
          },
          legalPrimaryContactName: {
            ...this.state.legalPrimaryContactName,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .legalPrimaryContactName
                ? questionnaireContext.investorFund.limitedPartner
                    .legalPrimaryContactName
                : questionnaireContext.investorFund.generalInfo
                    .legalPrimaryContactName || '',
          },
          legalPrimaryContactEmail: {
            ...this.state.legalPrimaryContactEmail,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .legalPrimaryContactEmail
                ? questionnaireContext.investorFund.limitedPartner
                    .legalPrimaryContactEmail
                : questionnaireContext.investorFund.generalInfo
                    .legalPrimaryContactEmail || '',
          },
          legalPrimaryContactPhone: {
            ...this.state.legalPrimaryContactPhone,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .legalPrimaryContactPhone
                ? questionnaireContext.investorFund.limitedPartner
                    .legalPrimaryContactPhone
                : questionnaireContext.investorFund.generalInfo
                    .legalPrimaryContactPhone || '',
          },
          legalSecondaryContactName: {
            ...this.state.legalSecondaryContactName,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .legalSecondaryContactName
                ? questionnaireContext.investorFund.limitedPartner
                    .legalSecondaryContactName
                : questionnaireContext.investorFund.generalInfo
                    .legalSecondaryContactName || '',
          },
          legalSecondaryContactEmail: {
            ...this.state.legalSecondaryContactEmail,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .legalSecondaryContactEmail
                ? questionnaireContext.investorFund.limitedPartner
                    .legalSecondaryContactEmail
                : questionnaireContext.investorFund.generalInfo
                    .legalSecondaryContactEmail || '',
          },
          legalSecondaryContactPhone: {
            ...this.state.legalSecondaryContactPhone,
            value:
              questionnaireContext.investorFund.limitedPartner &&
              !questionnaireContext.investorFund.generalInfo
                .legalSecondaryContactPhone
                ? questionnaireContext.investorFund.limitedPartner
                    .legalSecondaryContactPhone
                : questionnaireContext.investorFund.generalInfo
                    .legalSecondaryContactPhone || '',
          },
          bankName: {
            ...this.state.bankName,
            value:
              questionnaireContext.investorFund.generalInfo.bankName ||
              (questionnaireContext.investorFund.limitedPartner &&
                questionnaireContext.investorFund.limitedPartner.bankName) ||
              '',
          },
          acctName: {
            ...this.state.acctName,
            value:
              questionnaireContext.investorFund.generalInfo.acctName ||
              (questionnaireContext.investorFund.limitedPartner &&
                questionnaireContext.investorFund.limitedPartner.acctName) ||
              '',
          },
          swiftCode: {
            ...this.state.swiftCode,
            value:
              questionnaireContext.investorFund.generalInfo.swiftCode ||
              (questionnaireContext.investorFund.limitedPartner &&
                questionnaireContext.investorFund.limitedPartner.swiftCode) ||
              '',
          },
          acctNumber: {
            ...this.state.acctNumber,
            value:
              questionnaireContext.investorFund.generalInfo.acctNumber ||
              (questionnaireContext.investorFund.limitedPartner &&
                questionnaireContext.investorFund.limitedPartner.acctNumber) ||
              '',
          },
          bankaba: {
            ...this.state.bankaba,
            value:
              questionnaireContext.investorFund.generalInfo.bankaba ||
              (questionnaireContext.investorFund.limitedPartner &&
                questionnaireContext.investorFund.limitedPartner.bankaba) ||
              '',
          },
          furtherCredit: {
            ...this.state.furtherCredit,
            value:
              questionnaireContext.investorFund.generalInfo.furtherCredit ||
              (questionnaireContext.investorFund.limitedPartner &&
                questionnaireContext.investorFund.limitedPartner
                  .furtherCredit) ||
              '',
          },
          location: {
            ...this.state.location,
            value:
              questionnaireContext.investorFund.generalInfo.location ||
              (questionnaireContext.investorFund.limitedPartner &&
                questionnaireContext.investorFund.limitedPartner.location) ||
              '',
          },
        },
        () => {
          sessionStorage.setItem(
            'generalInfo',
            JSON.stringify(questionnaireContext.investorFund.generalInfo)
          );
        }
      );
    } else {
      // fixme needs to set an initial generalInfo with the initialState info
      // fixme this part can be simplified
      const templateGeneralInfo = {
        name: this.state.name.value,
        signatory: this.state.signatory.value,
        tax: this.state.tax.value,
        country: this.state.country.value || USA,
        countryPlace: this.state.countryPlace.value || USA,
        types: this.state.types,
        otherValue: this.state.otherValue,
        birth: `${this.state.monthBirth}-${this.state.dayBirth}-${this.state.yearBirth}`,
        namePrimaryContact: this.state.namePrimaryContact.value,
        emailPrimaryContact: this.state.emailPrimaryContact.value,
        addressPrimaryContact: this.state.addressPrimaryContact.value,
        phonePrimaryContact: this.state.phonePrimaryContact.value,
        // faxPrimaryContact: this.state.faxPrimaryContact.value,
        capitalCommitment: this.state.capitalCommitment.value,
        // eslint-disable-next-line
        principalPlaceOfBusiness:
          this.state.principalPlaceOfBusiness.value || '',
        principalPlaceOfBusinessPrimaryContact:
          this.state.principalPlaceOfBusinessPrimaryContact.value || '',
        // eslint-disable-next-line
        principalPlaceOfResidence:
          this.state.principalPlaceOfResidence.value || '',
        principalPlaceOfResidencePrimaryContact:
          this.state.principalPlaceOfResidencePrimaryContact.value || '',
        // eslint-disable-next-line
        jurisdictionOrganitzationPrimaryContact:
          this.state.jurisdictionOrganitzationPrimaryContact.value,
        investorFund: questionnaireContext.investorFund._id,
        reportsPrimaryContactName: this.state.reportsPrimaryContactName.value,
        reportsPrimaryContactEmail: this.state.reportsPrimaryContactEmail.value,
        reportsPrimaryContactPhone: this.state.reportsPrimaryContactPhone.value,
        reportsSecondaryContactName:
          this.state.reportsSecondaryContactName.value,
        reportsSecondaryContactEmail:
          this.state.reportsSecondaryContactEmail.value,
        reportsSecondaryContactPhone:
          this.state.reportsSecondaryContactPhone.value,
        taxPrimaryContactName: this.state.taxPrimaryContactName.value,
        taxPrimaryContactEmail: this.state.taxPrimaryContactEmail.value,
        taxPrimaryContactPhone: this.state.taxPrimaryContactPhone.value,
        taxSecondaryContactName: this.state.taxSecondaryContactName.value,
        taxSecondaryContactEmail: this.state.taxSecondaryContactEmail.value,
        taxSecondaryContactPhone: this.state.taxSecondaryContactPhone.value,
        capitalPrimaryContactName: this.state.capitalPrimaryContactName.value,
        capitalPrimaryContactEmail: this.state.capitalPrimaryContactEmail.value,
        capitalPrimaryContactPhone: this.state.capitalPrimaryContactPhone.value,
        capitalSecondaryContactName:
          this.state.capitalSecondaryContactName.value,
        capitalSecondaryContactEmail:
          this.state.capitalSecondaryContactEmail.value,
        capitalSecondaryContactPhone:
          this.state.capitalSecondaryContactPhone.value,
        stockPrimaryContactName: this.state.stockPrimaryContactName.value,
        stockPrimaryContactEmail: this.state.stockPrimaryContactEmail.value,
        stockPrimaryContactPhone: this.state.stockPrimaryContactPhone.value,
        stockSecondaryContactName: this.state.stockSecondaryContactName.value,
        stockSecondaryContactEmail: this.state.stockSecondaryContactEmail.value,
        stockSecondaryContactPhone: this.state.stockSecondaryContactPhone.value,
        legalPrimaryContactName: this.state.legalPrimaryContactName.value,
        legalPrimaryContactEmail: this.state.legalPrimaryContactEmail.value,
        legalPrimaryContactPhone: this.state.legalPrimaryContactPhone.value,
        legalSecondaryContactName: this.state.legalSecondaryContactName.value,
        legalSecondaryContactEmail: this.state.legalSecondaryContactEmail.value,
        legalSecondaryContactPhone: this.state.legalSecondaryContactPhone.value,
        bankName: this.state.bankName.value,
        acctName: this.state.acctName.value,
        swiftCode: this.state.swiftCode.value,
        acctNumber: this.state.acctNumber.value,
        bankaba: this.state.bankaba.value,
        furtherCredit: this.state.furtherCredit.value,
        location: this.state.location.value,
      };
      sessionStorage.setItem(
        'generalInfo',
        JSON.stringify(templateGeneralInfo)
      );
    }
  };
  // todo review this with onblur change
  handleOtherTypeValueChange = (event) => {
    event.preventDefault();
    const optionToTrack = event.target.value;
    this.setState(
      {
        ...this.state,
        otherValue: optionToTrack,
      },
      () => {
        if (sessionStorage.getItem('generalInfo')) {
          const info = JSON.parse(sessionStorage.getItem('generalInfo'));
          info.otherValue = optionToTrack;
          sessionStorage.setItem('generalInfo', JSON.stringify(info));
        }
      }
    );
  };

  handleTextInputChange = (event) => {
    event.preventDefault();
    this.setState({
      ...this.state,
      unsavedInfo: true,
      [event.target.name]: {
        ...this.state[event.target.name],
        value: event.target.value,
      },
    });
  };

  handleSaveFieldOnBlur = (event) => {
    event.preventDefault();
    if (sessionStorage.getItem('generalInfo')) {
      const info = JSON.parse(sessionStorage.getItem('generalInfo'));
      if (event.target.name === 'capitalCommitment') {
        info[event.target.name] = this.state.capitalCommitment.value;
      } else {
        info[event.target.name] = event.target.value;
      }
      sessionStorage.setItem('generalInfo', JSON.stringify(info));
    }
  };
  //eslint-disable-next-line
  handleFieldFocus = (event) => {
    event.preventDefault();
    // eslint-disable-next-line no-unused-vars
    const { questionnaireContext } = this.props;
    if (questionnaireContext.lastFocusedField.field !== event.target.name) {
      this.props.setLastFocusedField({ field: event.target.name });
    }
  };

  handleDateOfBirthChange = (event) => {
    event.preventDefault();
    this.setState({
      ...this.state,
      [`${event.target.name}Birth`]: event.target.value,
    });
  };

  multiCheckboxHandler = (event) => {
    event.preventDefault();
    const updateStorage = (_stateTypes) => {
      if (sessionStorage.getItem('generalInfo')) {
        const info = JSON.parse(sessionStorage.getItem('generalInfo'));
        info.types = _stateTypes;
        sessionStorage.setItem('generalInfo', JSON.stringify(info));
      }
    };
    const optionToTrack = event.target.getAttribute('data-identifier');
    // search if options is already there and remove because it means
    // its triggering again
    if (this.state.types.indexOf(optionToTrack) > -1) {
      // is in list needs to be removed
      const currentTypes = this.state.types;
      const index = currentTypes.indexOf(optionToTrack);
      currentTypes.splice(index, 1);
      this.setState(
        {
          ...this.state,
          types: currentTypes,
        },
        () => {
          updateStorage(this.state.types);
        }
      );
    } else {
      // todo figure out this saving without going to the backend
      const newTypes = [optionToTrack];
      if (
        optionToTrack === 'Natural Person' ||
        optionToTrack === 'IRA' ||
        optionToTrack === 'Joint Tenants with Right of Survivorship' ||
        optionToTrack === 'Tenants in Common' || optionToTrack === 'Other - Individual'
      ) {
        this.setState(
          {
            ...this.state,
            types: newTypes,
          },
          () => {
            updateStorage(this.state.types);
            this.props.updateMultiSelectFormAnswer(
              iqID.id_investor_status_1,
              'Yes'
            );
            this.saveAllFields();
          }
        );
      } else {
        this.setState(
          {
            ...this.state,
            types: newTypes,
          },
          () => {
            updateStorage(this.state.types);
            this.props.updateMultiSelectFormAnswer(
              iqID.id_investor_status_1,
              'No'
            );
            this.saveAllFields();
          }
        );
      }
    }
  };

  backStep = (e) => {
    const { questionnaireContext } = this.props;
    this.props.handleRedirect(e, questionnaireContext.currentStep - 1);
    /*if (document.baseURI.split('/access/home/')[1] !== 'information') {
      window.scrollTo(0, 0);
    } */
  };

  renderTextInput = (
    name,
    id,
    type,
    value,
    displayName,
    isRequired,
    size,
    hasError,
    errorMessage,
    changeHandler,
    helpText,
    disabled
  ) => {
    const inputDataTemp = {
      name,
      id,
      displayName,
      isRequired,
      size,
      hasError,
      type,
      errorMessage,
      value,
      wrapperClass: '',
      showHelp: helpText && helpText !== '',
      helpText,
      disabled,
      moneyInput: id === 'capitalCommitment',
    };
    return (
      <TextInput
        inputData={inputDataTemp}
        onChangeHandler={changeHandler}
        onBlurHandler={this.handleSaveFieldOnBlur}
      />
    );
  };

  // eslint-disable-next-line class-methods-use-this
  renderCheckOptions = () => {
    const { questionnaireContext } = this.props;
    const info = JSON.parse(sessionStorage.getItem('generalInfo'));
    let types = [];
    if (info && info.types) {
      types = info.types;
    } else if (
      questionnaireContext.investorFund.generalInfo &&
      questionnaireContext.investorFund.generalInfo.types
    ) {
      types = questionnaireContext.investorFund.generalInfo.types;
    }
    const results = [];
    for (let i = 0; i < checkOptions.length; i += 1) {
      if (checkOptions[i].id !== 'org-type-radio-other') {
        results.push({
          id: checkOptions[i].id,
          displayName: checkOptions[i].displayName,
          isSelected: types && types.indexOf(checkOptions[i].identifier) > -1,
          name: checkOptions[i].name,
          identifier: checkOptions[i].identifier,
        });
      }
    }
    return results;
  };

  checkOtherValueIsSelected = () => {
    const { questionnaireContext } = this.props;
    const info = JSON.parse(sessionStorage.getItem('generalInfo'));
    let types = [];
    if (info && info.types) {
      types = info.types;
    } else if (
      questionnaireContext.investorFund.generalInfo &&
      questionnaireContext.investorFund.generalInfo.types
    ) {
      types = questionnaireContext.investorFund.generalInfo.types;
    }

    return types && (types.indexOf('Other - Entity') > -1 || types.indexOf('Other - Individual') > -1);
  };

  handleFormSubmit = (event) => {
    const { questionnaireContext } = this.props;
    event.preventDefault();
    this.saveAllFields();
    if (event.target.id !== 'saveLater') {
      this.props.handleRedirect(event, questionnaireContext.currentStep + 1);
      // this.nextStep(event);
    }
  };

  saveAllFields = () => {
    const { questionnaireContext } = this.props;
    if (!questionnaireContext.savingGeneralInformation) {
      const payload = {
        name: this.state.name.value,
        signatory: this.state.signatory.value,
        tax: this.state.tax.value,
        country: this.state.country.value || USA,
        countryPlace: this.state.countryPlace.value || USA,
        types: this.state.types,
        otherValue: this.state.otherValue,
        birth: `${this.state.monthBirth}-${this.state.dayBirth}-${this.state.yearBirth}`,
        namePrimaryContact: this.state.namePrimaryContact.value,
        emailPrimaryContact: this.state.emailPrimaryContact.value,
        addressPrimaryContact: this.state.addressPrimaryContact.value,
        phonePrimaryContact: this.state.phonePrimaryContact.value,
        // faxPrimaryContact: this.state.faxPrimaryContact.value,
        capitalCommitment: this.state.capitalCommitment.value,
        // eslint-disable-next-line
        principalPlaceOfBusiness:
          this.state.principalPlaceOfBusiness.value || '',
        principalPlaceOfBusinessPrimaryContact:
          this.state.principalPlaceOfBusinessPrimaryContact.value || '',
        // eslint-disable-next-line
        principalPlaceOfResidence:
          this.state.principalPlaceOfResidence.value || '',
        principalPlaceOfResidencePrimaryContact:
          this.state.principalPlaceOfResidencePrimaryContact.value || '',
        // eslint-disable-next-line
        jurisdictionOrganitzationPrimaryContact:
          this.state.jurisdictionOrganitzationPrimaryContact.value,
        investorFund: questionnaireContext.investorFund._id,
        reportsPrimaryContactName: this.state.reportsPrimaryContactName.value,
        reportsPrimaryContactEmail: this.state.reportsPrimaryContactEmail.value,
        reportsPrimaryContactPhone: this.state.reportsPrimaryContactPhone.value,
        reportsSecondaryContactName:
          this.state.reportsSecondaryContactName.value,
        reportsSecondaryContactEmail:
          this.state.reportsSecondaryContactEmail.value,
        reportsSecondaryContactPhone:
          this.state.reportsSecondaryContactPhone.value,
        taxPrimaryContactName: this.state.taxPrimaryContactName.value,
        taxPrimaryContactEmail: this.state.taxPrimaryContactEmail.value,
        taxPrimaryContactPhone: this.state.taxPrimaryContactPhone.value,
        taxSecondaryContactName: this.state.taxSecondaryContactName.value,
        taxSecondaryContactEmail: this.state.taxSecondaryContactEmail.value,
        taxSecondaryContactPhone: this.state.taxSecondaryContactPhone.value,
        capitalPrimaryContactName: this.state.capitalPrimaryContactName.value,
        capitalPrimaryContactEmail: this.state.capitalPrimaryContactEmail.value,
        capitalPrimaryContactPhone: this.state.capitalPrimaryContactPhone.value,
        capitalSecondaryContactName:
          this.state.capitalSecondaryContactName.value,
        capitalSecondaryContactEmail:
          this.state.capitalSecondaryContactEmail.value,
        capitalSecondaryContactPhone:
          this.state.capitalSecondaryContactPhone.value,
        stockPrimaryContactName: this.state.stockPrimaryContactName.value,
        stockPrimaryContactEmail: this.state.stockPrimaryContactEmail.value,
        stockPrimaryContactPhone: this.state.stockPrimaryContactPhone.value,
        stockSecondaryContactName: this.state.stockSecondaryContactName.value,
        stockSecondaryContactEmail: this.state.stockSecondaryContactEmail.value,
        stockSecondaryContactPhone: this.state.stockSecondaryContactPhone.value,
        legalPrimaryContactName: this.state.legalPrimaryContactName.value,
        legalPrimaryContactEmail: this.state.legalPrimaryContactEmail.value,
        legalPrimaryContactPhone: this.state.legalPrimaryContactPhone.value,
        legalSecondaryContactName: this.state.legalSecondaryContactName.value,
        legalSecondaryContactEmail: this.state.legalSecondaryContactEmail.value,
        legalSecondaryContactPhone: this.state.legalSecondaryContactPhone.value,
        bankName: this.state.bankName.value,
        acctName: this.state.acctName.value,
        swiftCode: this.state.swiftCode.value,
        acctNumber: this.state.acctNumber.value,
        bankaba: this.state.bankaba.value,
        furtherCredit: this.state.furtherCredit.value,
        location: this.state.location.value,
      };
      if (this.state.previousInfo) {
        payload._id = questionnaireContext.investorFund.generalInfo._id;
      }
      if (questionnaireContext.multiAccess) {
        payload.limitedPartnerFund =
          questionnaireContext.investorFund._id.toString();
      } else {
        payload.investorFund = questionnaireContext.investorFund._id.toString();
      }
      saveGeneralInformation(payload, this.props);
    }
  };

  // eslint-disable-next-line
  checkIsEntity = () => {
    let flag = false;
    checkOptions.forEach((e) => {
      const checkBox = document.getElementById(e.id);
      if (
        e.id !== 'org-type-radio-6' &&
        e.id !== 'org-type-radio-17' &&
        e.id !== 'org-type-radio-12' &&
        e.id !== 'org-type-radio-18' &&
        e.id !== 'org-type-radio-other-2'
      ) {
        if (checkBox && checkBox.checked) flag = true;
      }
    });
    return flag;
  };

  // eslint-disable-next-line
  checkIsPerson = () => {
    let flag = false;
    checkOptions.forEach((e) => {
      const checkBox = document.getElementById(e.id);
      if (
        e.id === 'org-type-radio-6' ||
        e.id === 'org-type-radio-17' ||
        e.id === 'org-type-radio-12' ||
        e.id === 'org-type-radio-18' ||
        e.id === 'org-type-radio-other-2'
      ) {
        if (checkBox && checkBox.checked) flag = true;
      }
    });
    return flag;
  };

  handleCapitalChange = (maskedValue, floatValue, event) => {
    event.preventDefault();
    this.setState(
      {
        ...this.state,
        capitalCommitment: {
          ...this.state.capitalCommitment,
          value: floatValue,
        },
      },
      () => {
        if (sessionStorage.getItem('generalInfo')) {
          const info = JSON.parse(sessionStorage.getItem('generalInfo'));
          info.capitalCommitment = floatValue;
          sessionStorage.setItem('generalInfo', JSON.stringify(info));
        }
      }
    );
  };

  getTwoLetterStates = () => {
    const { value } = this.state.countryPlace;
    switch (value) {
      case CANADA:
        return caStatesAbbr.map((item) => {
          return {
            id: item.abbreviation,
            value: item.abbreviation,
          };
        });
      case USA:
        return usStatesAbbr.map((item) => {
          return {
            id: item.abbreviation,
            value: item.abbreviation,
          };
        });
      default:
        return [];
    }
  };

  render() {
    const { questionnaireContext, context } = this.props;
    const { country, countryPlace } = this.state;
    // const info = JSON.parse(sessionStorage.getItem('generalInfo'));
    const readableDate =
      questionnaireContext.investorFund.generalInfo &&
      questionnaireContext.investorFund.generalInfo.updatedAt
        ? new Date(questionnaireContext.investorFund.generalInfo.updatedAt)
        : '';
    const formattedDate =
      readableDate !== ''
        ? `${
            readableDate.getMonth() + 1
          }/${readableDate.getDate()}/${readableDate.getFullYear()}  ${readableDate.getHours()}:${readableDate.getMinutes()}:${readableDate.getSeconds()}`
        : readableDate;
    const toParagraph = `TO: ${questionnaireContext.fund.fundLegalName}`;
    const letterParagraph = `In connection with its subscription to ${questionnaireContext.fund.fundLegalName}  (the “Fund”), and subject to the terms and conditions of the Fund’s limited partnership agreement (as amended and/or restated from time to time, the “Fund Agreement”), the undersigned hereby provides in this Investor Questionnaire (this “Investor Questionnaire”) the following information and makes the following representations, warranties and agreements.  The undersigned acknowledges and agrees that its Capital Commitment shall be the lesser of the amount indicated on its signature page to the Fund Agreement or the amount accepted by ${questionnaireContext.fund.generalPartnerLegalName}, the general partner of the Fund (the “General Partner”), on the General Partner Acceptance Page.  The General Partner reserves the right to request additional information from the undersigned in connection with its subscription for an interest in the Fund.`;
    return (
      <div>
        <FundTool
          data={{
            saveLaterAble: true,
            modalToggle: 'modal',
            modalTarget: 'saveLaterPopup',
            lastSavedTimeStamp: formattedDate,
          }}
          saveLaterHandler={this.handleFormSubmit}
        />
        {/* <Alert
          inputData={{
            type: context.notificationType,
            title: context.title,
            message: context.message,
          }}
        /> */}
        {/* <BlockUi tag="div" blocking={tracker.savingInfo}> */}
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div>
              <div className="content-bg main-content-area clearfix">
                <div className="row container-content-padding">
                  <Title text={questionnaireContext.fund.fundLegalName} />
                  <Title text={'Investor Questionnaire'} />
                  <br />
                  <SubTitle isLink={false} text={toParagraph} />
                  <SubTitle isLink={false} text={'Ladies and Gentlemen'} />
                  <br />
                  <Paragraph text={letterParagraph} />
                  <br />
                  <Title text={'General Information'} />
                  <div className="row">
                    {this.renderTextInput(
                      'name',
                      'name',
                      'text',
                      this.state.name.value,
                      'Name of Limited Partner',
                      false,
                      'medium',
                      !this.state.name.valid,
                      this.state.name.errorMessage,
                      this.handleTextInputChange,
                      'Name of L.P. as it should appear in the books of the Fund'
                    )}
                  </div>
                  <div className="row">
                    {this.renderTextInput(
                      'capitalCommitment',
                      'capitalCommitment',
                      'text',
                      this.state.capitalCommitment.value,
                      'Capital Commitment ($)',
                      false,
                      'medium',
                      !this.state.capitalCommitment.valid,
                      this.state.capitalCommitment.errorMessage,
                      this.handleCapitalChange,
                      'Enter amount in US Dollars'
                    )}
                  </div>
                  <div className="row">
                    <MultiSelect
                      data={{
                        id: 'org-types-multiselect',
                        displayName: 'Type of Organization or Association',
                        otherSelected: this.checkOtherValueIsSelected(),
                        otherValue: this.state.otherValue,
                        otherValueChangeHandler:
                          this.handleOtherTypeValueChange,
                        showHelp: false,
                        helpText: 'Check all that apply',
                        size: 'col-lg-3 col-md-4 col-sm-6 col-xs-6 types-item',
                      }}
                      content={this.renderCheckOptions()}
                      onChangeHandler={this.multiCheckboxHandler}
                    />
                  </div>
                  {this.checkIsEntity() ? (
                    <div>
                      <div className="row">
                        <Dropdown
                          inputData={{
                            id: 'countryPlace',
                            name: 'countryPlace',
                            value: countryPlace.value,
                            displayName: '',
                            title: 'Principal Place of Business',
                            size: checkSelectedCountries(countryPlace.value)
                              ? 'medium'
                              : 'large',
                            options: countries.map((item) => {
                              return {
                                id: item.code,
                                value: item.name,
                              };
                            }),
                            hasError: !country.valid,
                            errorMessage: country.errorMessage,
                            showHelp: true,
                            helpText:
                              'Indicate U.S. state (or indicate country if outside of U.S.)',
                          }}
                          onChangeHandler={this.handleTextInputChange}
                          onBlurHandler={this.handleSaveFieldOnBlur}
                        />

                        {checkSelectedCountries(countryPlace.value) ? (
                          <>
                            <br />
                            <Dropdown
                              inputData={{
                                id: 'principalPlaceOfBusinessPrimaryContact',
                                name: 'principalPlaceOfBusinessPrimaryContact',
                                value:
                                  this.state
                                    .principalPlaceOfBusinessPrimaryContact
                                    .value,
                                displayName: 'Select',
                                title: 'State / Province / Territory',
                                size: 'medium',
                                options: this.getTwoLetterStates(),
                                hasError:
                                  !this.state
                                    .principalPlaceOfBusinessPrimaryContact
                                    .valid,
                                errorMessage:
                                  this.state
                                    .principalPlaceOfBusinessPrimaryContact
                                    .errorMessage,
                              }}
                              onChangeHandler={this.handleTextInputChange}
                              onBlurHandler={this.handleSaveFieldOnBlur}
                            />
                          </>
                        ) : null}

                        <br />
                        <br />
                        {checkOptionals(
                          this.state.principalPlaceOfBusinessPrimaryContact
                            .value,
                          countryPlace.value
                        ) &&
                          this.renderTextInput(
                            'principalPlaceOfBusiness',
                            'principalPlaceOfBusiness',
                            'text',
                            this.state.principalPlaceOfBusiness.value,
                            '',
                            false,
                            'large',
                            !this.state.principalPlaceOfBusiness.valid,
                            this.state.principalPlaceOfBusiness.errorMessage,
                            this.handleTextInputChange,
                            ''
                          )}
                      </div>
                    </div>
                  ) : null}
                  {this.checkIsPerson() ? (
                    <div className="row">
                      <Dropdown
                        inputData={{
                          id: 'country',
                          name: 'country',
                          value: country.value,
                          displayName: '',
                          title: 'Country of Citizenship',
                          size: 'medium',
                          options: countries.map((item) => {
                            return {
                              id: item.code,
                              value: item.name,
                            };
                          }),
                          hasError: !country.valid,
                          errorMessage: country.errorMessage,
                          showHelp: true,
                          helpText:
                            'Indicate U.S. state (or indicate country if outside of U.S.)',
                        }}
                        onChangeHandler={this.handleTextInputChange}
                        onBlurHandler={this.handleSaveFieldOnBlur}
                      />
                      <br />
                      <Dropdown
                        inputData={{
                          id: 'countryPlace',
                          name: 'countryPlace',
                          value: countryPlace.value,
                          displayName: '',
                          title: 'Principal Place Of Residence',
                          size: checkSelectedCountries(countryPlace.value)
                            ? 'small'
                            : 'medium',
                          options: countries.map((item) => {
                            return {
                              id: item.code,
                              value: item.name,
                            };
                          }),
                          hasError: !countryPlace.valid,
                          errorMessage: countryPlace.errorMessage,
                        }}
                        onChangeHandler={this.handleTextInputChange}
                        onBlurHandler={this.handleSaveFieldOnBlur}
                      />

                      {checkSelectedCountries(countryPlace.value) ? (
                        <Dropdown
                          inputData={{
                            id: 'principalPlaceOfResidencePrimaryContact',
                            name: 'principalPlaceOfResidencePrimaryContact',
                            value:
                              this.state.principalPlaceOfResidencePrimaryContact
                                .value,
                            displayName: 'Select',
                            title: 'State / Province / Territory',
                            size: 'small',
                            options: this.getTwoLetterStates(),
                            hasError:
                              !this.state
                                .principalPlaceOfResidencePrimaryContact.valid,
                            errorMessage:
                              this.state.principalPlaceOfResidencePrimaryContact
                                .errorMessage,
                          }}
                          onChangeHandler={this.handleTextInputChange}
                          onBlurHandler={this.handleSaveFieldOnBlur}
                        />
                      ) : null}

                      <br />
                      {checkOptionals(
                        this.state.principalPlaceOfResidencePrimaryContact
                          .value,
                        countryPlace.value
                      )
                        ? this.renderTextInput(
                            'principalPlaceOfResidence',
                            'principalPlaceOfResidence',
                            'text',
                            this.state.principalPlaceOfResidence.value,
                            '',
                            false,
                            'large',
                            !this.state.principalPlaceOfResidence.valid,
                            this.state.principalPlaceOfResidence.errorMessage,
                            this.handleTextInputChange,
                            ''
                          )
                        : null}
                    </div>
                  ) : null}
                  <br />
                  <Title
                    text={'Principal Contact Information for Limited Partner'}
                  />
                  <div className="row">
                    {this.renderTextInput(
                      'namePrimaryContact',
                      'contact-person',
                      'text',
                      this.state.namePrimaryContact.value,
                      'Contact Person',
                      false,
                      'medium',
                      !this.state.namePrimaryContact.valid,
                      this.state.namePrimaryContact.errorMessage,
                      this.handleTextInputChange
                    )}
                    {this.renderTextInput(
                      'emailPrimaryContact',
                      'eaddress',
                      'email',
                      this.state.emailPrimaryContact.value,
                      'Email Address',
                      false,
                      'medium',
                      !this.state.emailPrimaryContact.valid,
                      this.state.emailPrimaryContact.errorMessage,
                      this.handleTextInputChange,
                      'Most communications will be sent via email'
                    )}
                  </div>
                  <div className="row">
                    {this.renderTextInput(
                      'phonePrimaryContact',
                      'phone',
                      'text',
                      this.state.phonePrimaryContact.value,
                      'Phone Number',
                      false,
                      'medium',
                      !this.state.phonePrimaryContact.valid,
                      this.state.phonePrimaryContact.errorMessage,
                      this.handleTextInputChange
                    )}
                    {this.renderTextInput(
                      'addressPrimaryContact',
                      'address',
                      'text',
                      this.state.addressPrimaryContact.value,
                      'Full Mailing Address',
                      false,
                      'medium',
                      !this.state.addressPrimaryContact.valid,
                      this.state.addressPrimaryContact.errorMessage,
                      this.handleTextInputChange
                    )}
                  </div>
                  <br />
                  <Title
                    text={
                      'Additional Contact Information for Limited Partner (Optional)'
                    }
                  />
                  <p>
                    If copies of correspondence, capital calls, tax documents, legal notices or 
                    other information should be provided to additional parties, please provide specific 
                    instructions and complete contact information below or on a separate sheet 
                    detailing similar information.  If no information is provided below, then all information 
                    will be provided only to the principal contact listed above.  
                    The Limited Partner is responsible for promptly informing the General Partner in the 
                    event of any changes in its contact information.
                   </p>
                    <p>
                    If possible, we prefer to have two or more points of contact, 
                    in the event we are unable to reach one of the individuals.  
                   <strong> Please note that if any information is requested to be delivered to a party 
                    other than the Limited Partner, the General Partner will retain sole and absolute discretion 
                    over whether such additional party may receive the information, and such additional party may be 
                    required to execute a nondisclosure agreement in connection therewith.</strong>
                  </p>
                  <br />
                  <div className="row">
                    <h5 className="color-black align-text-push">
                      <b>Quarterly and Annual Reports</b>
                    </h5>
                    <br />
                  </div>
                  <div className="" id="collapseQuarterly">
                    <div className="row">
                      {this.renderTextInput(
                        'reportsPrimaryContactName',
                        'reports-prim-contact-name',
                        'text',
                        this.state.reportsPrimaryContactName.value,
                        'Primary Contact Name',
                        false,
                        'medium',
                        !this.state.reportsPrimaryContactName.valid,
                        this.state.reportsPrimaryContactName.errorMessage,
                        this.handleTextInputChange
                      )}
                      {this.renderTextInput(
                        'reportsPrimaryContactEmail',
                        'reports-prim-contact-email',
                        'text',
                        this.state.reportsPrimaryContactEmail.value,
                        'Email Address',
                        false,
                        'small',
                        !this.state.reportsPrimaryContactEmail.valid,
                        this.state.reportsPrimaryContactEmail.errorMessage,
                        this.handleTextInputChange
                      )}
                      {this.renderTextInput(
                        'reportsPrimaryContactPhone',
                        'reports-prim-contact-phone',
                        'text',
                        this.state.reportsPrimaryContactPhone.value,
                        'Phone Number',
                        false,
                        'small',
                        !this.state.reportsPrimaryContactPhone.valid,
                        this.state.reportsPrimaryContactPhone.errorMessage,
                        this.handleTextInputChange
                      )}
                    </div>
                    <div className="row">
                      {this.renderTextInput(
                        'reportsSecondaryContactName',
                        'reports-sec-contact-name',
                        'text',
                        this.state.reportsSecondaryContactName.value,
                        'Secondary Contact Name',
                        false,
                        'medium',
                        !this.state.reportsSecondaryContactName.valid,
                        this.state.reportsSecondaryContactName.errorMessage,
                        this.handleTextInputChange
                      )}
                      {this.renderTextInput(
                        'reportsSecondaryContactEmail',
                        'reports-sec-contact-email',
                        'text',
                        this.state.reportsSecondaryContactEmail.value,
                        'Email Address',
                        false,
                        'small',
                        !this.state.reportsSecondaryContactEmail.valid,
                        this.state.reportsSecondaryContactEmail.errorMessage,
                        this.handleTextInputChange
                      )}
                      {this.renderTextInput(
                        'reportsSecondaryContactPhone',
                        'reports-sec-contact-email-phone',
                        'text',
                        this.state.reportsSecondaryContactPhone.value,
                        'Phone Number',
                        false,
                        'small',
                        !this.state.reportsSecondaryContactPhone.valid,
                        this.state.reportsSecondaryContactPhone.errorMessage,
                        this.handleTextInputChange
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <h5 className="color-black align-text-push">
                      <b>Tax Information / K-1s</b>
                    </h5>
                    <br />
                  </div>
                  <div id="collapseTaxInfo">
                    <div className="row">
                      {this.renderTextInput(
                        'taxPrimaryContactName',
                        'tax-prim-contact-name',
                        'text',
                        this.state.taxPrimaryContactName.value,
                        'Primary Contact Name',
                        false,
                        'medium',
                        !this.state.taxPrimaryContactName.valid,
                        this.state.taxPrimaryContactName.errorMessage,
                        this.handleTextInputChange
                      )}
                      {this.renderTextInput(
                        'taxPrimaryContactEmail',
                        'tax-prim-contact-email',
                        'text',
                        this.state.taxPrimaryContactEmail.value,
                        'Email Address',
                        false,
                        'small',
                        !this.state.taxPrimaryContactEmail.valid,
                        this.state.taxPrimaryContactEmail.errorMessage,
                        this.handleTextInputChange
                      )}
                      {this.renderTextInput(
                        'taxPrimaryContactPhone',
                        'tax-prim-contact-phone',
                        'text',
                        this.state.taxPrimaryContactPhone.value,
                        'Phone Number',
                        false,
                        'small',
                        !this.state.taxPrimaryContactPhone.valid,
                        this.state.taxPrimaryContactPhone.errorMessage,
                        this.handleTextInputChange
                      )}
                    </div>
                    <div className="row">
                      {this.renderTextInput(
                        'taxSecondaryContactName',
                        'tax-sec-contact-name',
                        'text',
                        this.state.taxSecondaryContactName.value,
                        'Secondary Contact Name',
                        false,
                        'medium',
                        !this.state.taxSecondaryContactName.valid,
                        this.state.taxSecondaryContactName.errorMessage,
                        this.handleTextInputChange
                      )}
                      {this.renderTextInput(
                        'taxSecondaryContactEmail',
                        'tax-sec-contact-email',
                        'text',
                        this.state.taxSecondaryContactEmail.value,
                        'Email Address',
                        false,
                        'small',
                        !this.state.taxSecondaryContactEmail.valid,
                        this.state.taxSecondaryContactEmail.errorMessage,
                        this.handleTextInputChange
                      )}
                      {this.renderTextInput(
                        'taxSecondaryContactPhone',
                        'tax-sec-contact-email-phone',
                        'text',
                        this.state.taxSecondaryContactPhone.value,
                        'Phone Number',
                        false,
                        'small',
                        !this.state.taxSecondaryContactPhone.valid,
                        this.state.taxSecondaryContactPhone.errorMessage,
                        this.handleTextInputChange
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <h5 className="color-black align-text-push">
                      <b>Capital Call Notices</b>
                    </h5>
                    <br />
                  </div>
                  <div id="collapseCapitalCall">
                    <div className="row">
                      {this.renderTextInput(
                        'capitalPrimaryContactName',
                        'capital-prim-contact-name',
                        'text',
                        this.state.capitalPrimaryContactName.value,
                        'Primary Contact Name',
                        false,
                        'medium',
                        !this.state.capitalPrimaryContactName.valid,
                        this.state.capitalPrimaryContactName.errorMessage,
                        this.handleTextInputChange
                      )}
                      {this.renderTextInput(
                        'capitalPrimaryContactEmail',
                        'capital-prim-contact-email',
                        'text',
                        this.state.capitalPrimaryContactEmail.value,
                        'Email Address',
                        false,
                        'small',
                        !this.state.capitalPrimaryContactEmail.valid,
                        this.state.capitalPrimaryContactEmail.errorMessage,
                        this.handleTextInputChange
                      )}
                      {this.renderTextInput(
                        'capitalPrimaryContactPhone',
                        'capital-prim-contact-phone',
                        'text',
                        this.state.capitalPrimaryContactPhone.value,
                        'Phone Number',
                        false,
                        'small',
                        !this.state.capitalPrimaryContactPhone.valid,
                        this.state.capitalPrimaryContactPhone.errorMessage,
                        this.handleTextInputChange
                      )}
                    </div>
                    <div className="row">
                      {this.renderTextInput(
                        'capitalSecondaryContactName',
                        'capital-sec-contact-name',
                        'text',
                        this.state.capitalSecondaryContactName.value,
                        'Secondary Contact Name',
                        false,
                        'medium',
                        !this.state.capitalSecondaryContactName.valid,
                        this.state.capitalSecondaryContactName.errorMessage,
                        this.handleTextInputChange
                      )}
                      {this.renderTextInput(
                        'capitalSecondaryContactEmail',
                        'capital-sec-contact-email',
                        'text',
                        this.state.capitalSecondaryContactEmail.value,
                        'Email Address',
                        false,
                        'small',
                        !this.state.capitalSecondaryContactEmail.valid,
                        this.state.capitalSecondaryContactEmail.errorMessage,
                        this.handleTextInputChange
                      )}
                      {this.renderTextInput(
                        'capitalSecondaryContactPhone',
                        'capital-sec-contact-phone',
                        'text',
                        this.state.capitalSecondaryContactPhone.value,
                        'Phone Number',
                        false,
                        'small',
                        !this.state.capitalSecondaryContactPhone.valid,
                        this.state.capitalSecondaryContactPhone.errorMessage,
                        this.handleTextInputChange
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <h5 className="color-black align-text-push">
                      <b>Cash and Stock Distributions</b>
                    </h5>
                    <br />
                  </div>
                  <div id="collapseCashStock">
                    <div className="row">
                      {this.renderTextInput(
                        'stockPrimaryContactName',
                        'stock-prim-contact-name',
                        'text',
                        this.state.stockPrimaryContactName.value,
                        'Primary Contact Name',
                        false,
                        'medium',
                        !this.state.stockPrimaryContactName.valid,
                        this.state.stockPrimaryContactName.errorMessage,
                        this.handleTextInputChange
                      )}
                      {this.renderTextInput(
                        'stockPrimaryContactEmail',
                        'stock-prim-contact-email',
                        'text',
                        this.state.stockPrimaryContactEmail.value,
                        'Email Address',
                        false,
                        'small',
                        !this.state.stockPrimaryContactEmail.valid,
                        this.state.stockPrimaryContactEmail.errorMessage,
                        this.handleTextInputChange
                      )}
                      {this.renderTextInput(
                        'stockPrimaryContactPhone',
                        'stock-prim-contact-phone',
                        'text',
                        this.state.stockPrimaryContactPhone.value,
                        'Phone Number',
                        false,
                        'small',
                        !this.state.stockPrimaryContactPhone.valid,
                        this.state.stockPrimaryContactPhone.errorMessage,
                        this.handleTextInputChange
                      )}
                    </div>
                    <div className="row">
                      {this.renderTextInput(
                        'stockSecondaryContactName',
                        'stock-sec-contact-name',
                        'text',
                        this.state.stockSecondaryContactName.value,
                        'Secondary Contact Name',
                        false,
                        'medium',
                        !this.state.stockSecondaryContactName.valid,
                        this.state.stockSecondaryContactName.errorMessage,
                        this.handleTextInputChange
                      )}
                      {this.renderTextInput(
                        'stockSecondaryContactEmail',
                        'stock-sec-contact-email',
                        'text',
                        this.state.stockSecondaryContactEmail.value,
                        'Email Address',
                        false,
                        'small',
                        !this.state.stockSecondaryContactEmail.valid,
                        this.state.stockSecondaryContactEmail.errorMessage,
                        this.handleTextInputChange
                      )}
                      {this.renderTextInput(
                        'stockSecondaryContactPhone',
                        'stock-sec-contact-phone',
                        'text',
                        this.state.stockSecondaryContactPhone.value,
                        'Phone Number',
                        false,
                        'small',
                        !this.state.stockSecondaryContactPhone.valid,
                        this.state.stockSecondaryContactPhone.errorMessage,
                        this.handleTextInputChange
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <h5 className="color-black align-text-push">
                      <b>Legal Documents</b>
                    </h5>
                    <br />
                  </div>
                  <div id="collapseLegalDocs">
                    <div className="row">
                      {this.renderTextInput(
                        'legalPrimaryContactName',
                        'legal-prim-contact-name',
                        'text',
                        this.state.legalPrimaryContactName.value,
                        'Primary Contact Name',
                        false,
                        'medium',
                        !this.state.legalPrimaryContactName.valid,
                        this.state.legalPrimaryContactName.errorMessage,
                        this.handleTextInputChange
                      )}
                      {this.renderTextInput(
                        'legalPrimaryContactEmail',
                        'legal-prim-contact-email',
                        'text',
                        this.state.legalPrimaryContactEmail.value,
                        'Email Address',
                        false,
                        'small',
                        !this.state.legalPrimaryContactEmail.valid,
                        this.state.legalPrimaryContactEmail.errorMessage,
                        this.handleTextInputChange
                      )}
                      {this.renderTextInput(
                        'legalPrimaryContactPhone',
                        'legal-prim-contact-phone',
                        'text',
                        this.state.legalPrimaryContactPhone.value,
                        'Phone Number',
                        false,
                        'small',
                        !this.state.legalPrimaryContactPhone.valid,
                        this.state.legalPrimaryContactPhone.errorMessage,
                        this.handleTextInputChange
                      )}
                    </div>
                    <div className="row">
                      {this.renderTextInput(
                        'legalSecondaryContactName',
                        'legal-sec-contact-name',
                        'text',
                        this.state.legalSecondaryContactName.value,
                        'Secondary Contact Name',
                        false,
                        'medium',
                        !this.state.legalSecondaryContactName.valid,
                        this.state.legalSecondaryContactName.errorMessage,
                        this.handleTextInputChange
                      )}
                      {this.renderTextInput(
                        'legalSecondaryContactEmail',
                        'legal-sec-contact-email',
                        'text',
                        this.state.legalSecondaryContactEmail.value,
                        'Email Address',
                        false,
                        'small',
                        !this.state.legalSecondaryContactEmail.valid,
                        this.state.legalSecondaryContactEmail.errorMessage,
                        this.handleTextInputChange
                      )}
                      {this.renderTextInput(
                        'legalSecondaryContactPhone',
                        'legal-sec-contact-phone',
                        'text',
                        this.state.legalSecondaryContactPhone.value,
                        'Phone Number',
                        false,
                        'small',
                        !this.state.legalSecondaryContactPhone.valid,
                        this.state.legalSecondaryContactPhone.errorMessage,
                        this.handleTextInputChange
                      )}
                    </div>
                  </div>
                  <br />
                  {questionnaireContext.fund.includesWireInstructions && (
                    <>
                      <Title
                        text={'Wire Instructions for Distributions (Optional)'}
                      />
                      <div className="row">
                        {this.renderTextInput(
                          'bankName',
                          'bank-name',
                          'text',
                          this.state.bankName.value,
                          'Bank Name',
                          false,
                          'medium',
                          !this.state.bankName.valid,
                          this.state.bankName.errorMessage,
                          this.handleTextInputChange
                        )}
                        {this.renderTextInput(
                          'acctName',
                          'acct-name',
                          'text',
                          this.state.acctName.value,
                          'Accountholder Name',
                          false,
                          'medium',
                          !this.state.acctName.valid,
                          this.state.acctName.errorMessage,
                          this.handleTextInputChange
                        )}
                      </div>
                      <div className="row">
                        {this.renderTextInput(
                          'swiftCode',
                          'swift-code',
                          'text',
                          this.state.swiftCode.value,
                          'Swift Code',
                          false,
                          'medium',
                          !this.state.swiftCode.valid,
                          this.state.swiftCode.errorMessage,
                          this.handleTextInputChange
                        )}
                        {this.renderTextInput(
                          'acctNumber',
                          'acct-number',
                          'text',
                          this.state.acctNumber.value,
                          'Account Number',
                          true,
                          'medium',
                          !this.state.acctNumber.valid,
                          this.state.acctNumber.errorMessage,
                          this.handleTextInputChange
                        )}
                      </div>
                      <div className="row">
                        {this.renderTextInput(
                          'bankaba',
                          'bank-aba',
                          'text',
                          this.state.bankaba.value,
                          'Bank ABA#',
                          false,
                          'medium',
                          !this.state.bankaba.valid,
                          this.state.bankaba.errorMessage,
                          this.handleTextInputChange
                        )}
                        {this.renderTextInput(
                          'furtherCredit',
                          'further-credit',
                          'text',
                          this.state.furtherCredit.value,
                          'For further credit to',
                          false,
                          'medium',
                          !this.state.furtherCredit.valid,
                          this.state.furtherCredit.errorMessage,
                          this.handleTextInputChange,
                          'Include Accountholder Name and Number'
                        )}
                      </div>
                      <div className="row">
                        {this.renderTextInput(
                          'location',
                          'country-city-state',
                          'text',
                          this.state.location.value,
                          'City/State/Country',
                          true,
                          'medium',
                          !this.state.location.valid,
                          this.state.location.errorMessage,
                          this.handleTextInputChange
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <br />
              <div className="back-btn">
                <Button
                  inputData={{
                    id: 'back-btn',
                    displayName: 'Back',
                    type: 'button',
                  }}
                  clickHandler={this.backStep}
                />
              </div>
              <div className="next-btn">
                <Button
                  inputData={{
                    id: 'next-btn',
                    displayName: 'Next',
                    type: 'button',
                  }}
                  clickHandler={this.handleFormSubmit}
                />
              </div>
            </div>
          </div>
        </div>
        {/* </BlockUi> */}
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  }
}
const mapStateToProps = (state: Reducer) => ({
  questionnaireContext: state.questionnaireExperience,
  context: state.context,
});
const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  (dispatch: Dispatch) => ({
    setLastFocusedField: (_info) =>
      dispatch({ type: IQ_SET_LAST_FOCUSED_FIELD, lastFocusedField: _info }),
    setSavingInfo: (_saving) =>
      dispatch({
        type: IQ_IS_SAVING_GENERAL_INFORMATION,
        savingGeneralInformation: _saving,
      }),
    // new items above
  })
);

export default connector(GeneralInfo);
