import React from 'react';
import { resolveSizeClass } from './../utils/common';
import CustomTooltip from '../../components/Tooltip/index';
import './styles.scss';

type Props = {
  inputData: Object,
  onChangeHandler: () => void,
  onBlurHandler: () => void,
};

const Dropdown = ({ inputData, onChangeHandler, onBlurHandler }: Props) => (
  <div
    className={`${resolveSizeClass(inputData.size)} ${
      inputData.wrapperExtraClass ? inputData.wrapperExtraClass : ''
    }`}
  >
  <div className="col-content">
    {inputData.title && inputData.title !== '' && (
      <h5 className="color-black tooltip-positioning">
        {inputData.title}
        {inputData.showHelp && (
          <CustomTooltip
            className="tooltip-positioning"
            data={{
              message: inputData.helpText,
              extraClass: inputData.tooltipExtraClass || 'tooltip-margin',
            }}
          />
        )}
      </h5>
    )}
    <div
      className={`dropdown form-group input-group-style ${
        inputData.hasError ? 'has-error' : ''
      }`}
    >
      <fieldset>
        <select
          value={inputData.value}
          onBlur={onBlurHandler}
          onChange={onChangeHandler}
          id={inputData.id}
          name={inputData.name}
          className="form-control input-style"
          style={inputData.style && inputData.style}
        >
          {inputData.displayName && (
            <option value="">{inputData.displayName}</option>
          )}
          {inputData.options.map((opt) => (
            <option key={opt.id} value={opt.id}>
              {opt.value}
            </option>
          ))}
        </select>
      </fieldset>
    </div>
    {inputData.hasError ? (
      <div className="error-message">
        <span className="text">{inputData.errorMessage}</span>
      </div>
    ) : null}
    </div>
  </div>
);

Dropdown.defaultProps = {
  data: {
    id: '',
    displayName: '',
    title: '',
    name: '',
    size: '',
    style: {},
    isRequired: false,
    hasError: false,
    errorMessage: '',
    value: '',
    options: [
      { id: '1', val: 'def opt 1' },
      { id: '2', val: 'def opt 2' },
    ],
  },
  onChangeHandler: () => {},
  onBlurHandler: () => {},
};

export default Dropdown;
