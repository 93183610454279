export const EXCLUDE_DATA = {
  LIMITED_PARTNER: [
    'foresite_investor_type',
    'foresiteFinraAffiliation',
    'foresiteFinraAffiliationFurtherDetails',
    'foresitePublicCompanyAffiliation',
    'foresitePublicCompanyAffiliationFurtherDetails',
    'foresiteEntities',
    'foresiteEntitiesFurtherDetails',
    'hcm',
    'institutionalAccountStatus',
    'partnerStatementsPrimaryContactName',
    'partnerStatementsPrimaryContactEmail',
    'partnerStatementsPrimaryContactPhone',
    'partnerStatementsSecondaryContactName',
    'partnerStatementsSecondaryContactEmail',
    'partnerStatementsSecondaryContactPhone',
    'rule5130_finraNewIssues_1',
    'rule5130_finraNewIssues_2',
    'rule5131_finraNewIssues_1',
    'rule5131_finraNewIssues_2',
    'retirementPlan',
    'foreignPartner',
    'Rep7EUandUK',
    'Rep8Switzerland',
  ],
  CAYMAN: [
    'foresite_investor_type',
    'foresiteFinraAffiliation',
    'foresiteFinraAffiliationFurtherDetails',
    'foresitePublicCompanyAffiliation',
    'foresitePublicCompanyAffiliationFurtherDetails',
    'foresiteEntities',
    'foresiteEntitiesFurtherDetails',
    'hcm',
    'institutionalAccountStatus',
    'partnerStatementsPrimaryContactName',
    'partnerStatementsPrimaryContactEmail',
    'partnerStatementsPrimaryContactPhone',
    'partnerStatementsSecondaryContactName',
    'partnerStatementsSecondaryContactEmail',
    'partnerStatementsSecondaryContactPhone',
    'rule5130_finraNewIssues_1',
    'rule5130_finraNewIssues_2',
    'rule5131_finraNewIssues_1',
    'rule5131_finraNewIssues_2',
    'retirementPlan',
    'foreignPartner',
    'Rep7EUandUK',
    'Rep8Switzerland',
  ],
  AUDERE: [
    'foresite_investor_type',
    'foresiteFinraAffiliation',
    'foresiteFinraAffiliationFurtherDetails',
    'foresitePublicCompanyAffiliation',
    'foresitePublicCompanyAffiliationFurtherDetails',
    'foresiteEntities',
    'foresiteEntitiesFurtherDetails',
    'hcm',
    'institutionalAccountStatus',
    'partnerStatementsPrimaryContactName',
    'partnerStatementsPrimaryContactEmail',
    'partnerStatementsPrimaryContactPhone',
    'partnerStatementsSecondaryContactName',
    'partnerStatementsSecondaryContactEmail',
    'partnerStatementsSecondaryContactPhone',
    'rule5130_finraNewIssues_1',
    'rule5130_finraNewIssues_2',
    'rule5131_finraNewIssues_1',
    'rule5131_finraNewIssues_2',
    'retirementPlan',
    'foreignPartner',
    'Rep7EUandUK',
    'Rep8Switzerland',
  ],
  LIMITED_LIABILITY_COMPANY: [
    'foresite_investor_type',
    'foresiteFinraAffiliation',
    'foresiteFinraAffiliationFurtherDetails',
    'foresitePublicCompanyAffiliation',
    'foresitePublicCompanyAffiliationFurtherDetails',
    'foresiteEntities',
    'foresiteEntitiesFurtherDetails',
    'hcm',
    'institutionalAccountStatus',
    'partnerStatementsPrimaryContactName',
    'partnerStatementsPrimaryContactEmail',
    'partnerStatementsPrimaryContactPhone',
    'partnerStatementsSecondaryContactName',
    'partnerStatementsSecondaryContactEmail',
    'partnerStatementsSecondaryContactPhone',
    'rule5130_finraNewIssues_1',
    'rule5130_finraNewIssues_2',
    'rule5131_finraNewIssues_1',
    'rule5131_finraNewIssues_2',
    'retirementPlan',
    'foreignPartner',
    'Rep7EUandUK',
    'Rep8Switzerland',
  ],
  FORESITE: [
    'hcm',
    'institutionalAccountStatus',
    'partnerStatementsPrimaryContactName',
    'partnerStatementsPrimaryContactEmail',
    'partnerStatementsPrimaryContactPhone',
    'partnerStatementsSecondaryContactName',
    'partnerStatementsSecondaryContactEmail',
    'partnerStatementsSecondaryContactPhone',
    'rule5130_finraNewIssues_1',
    'rule5130_finraNewIssues_2',
    'rule5131_finraNewIssues_1',
    'rule5131_finraNewIssues_2',
    'retirementPlan',
    'foreignPartner',
    'Rep7EUandUK',
    'Rep8Switzerland',
  ],
  QUESTACAPITAL: [
    'foresite_investor_type',
    'foresiteFinraAffiliation',
    'foresiteFinraAffiliationFurtherDetails',
    'foresitePublicCompanyAffiliation',
    'foresitePublicCompanyAffiliationFurtherDetails',
    'foresiteEntities',
    'foresiteEntitiesFurtherDetails',
    'hcm',
    'partnerStatementsPrimaryContactName',
    'partnerStatementsPrimaryContactEmail',
    'partnerStatementsPrimaryContactPhone',
    'partnerStatementsSecondaryContactName',
    'partnerStatementsSecondaryContactEmail',
    'partnerStatementsSecondaryContactPhone',
    'retirementPlan',
    'foreignPartner',
    'Rep7EUandUK',
    'Rep8Switzerland',
  ],
  INITIALIZED: [
    'foresite_investor_type',
    'foresiteFinraAffiliation',
    'foresiteFinraAffiliationFurtherDetails',
    'foresitePublicCompanyAffiliation',
    'foresitePublicCompanyAffiliationFurtherDetails',
    'foresiteEntities',
    'foresiteEntitiesFurtherDetails',
    'hcm',
    'institutionalAccountStatus',
    'partnerStatementsPrimaryContactName',
    'partnerStatementsPrimaryContactEmail',
    'partnerStatementsPrimaryContactPhone',
    'partnerStatementsSecondaryContactName',
    'partnerStatementsSecondaryContactEmail',
    'partnerStatementsSecondaryContactPhone',
    'rule5130_finraNewIssues_1',
    'rule5130_finraNewIssues_2',
    'rule5131_finraNewIssues_1',
    'rule5131_finraNewIssues_2',
    'retirementPlan',
    'foreignPartner',
    'Rep7EUandUK',
    'Rep8Switzerland',
  ],
  HCM: [
    'foresite_investor_type',
    'foresiteFinraAffiliation',
    'foresiteFinraAffiliationFurtherDetails',
    'foresitePublicCompanyAffiliation',
    'foresitePublicCompanyAffiliationFurtherDetails',
    'foresiteEntities',
    'foresiteEntitiesFurtherDetails',
    'institutionalAccountStatus',
    'rule5130_finraNewIssues_1',
    'rule5130_finraNewIssues_2',
    'rule5131_finraNewIssues_1',
    'rule5131_finraNewIssues_2',
    'partnerStatementsPrimaryContactName',
    'partnerStatementsPrimaryContactEmail',
    'partnerStatementsPrimaryContactPhone',
    'partnerStatementsSecondaryContactName',
    'partnerStatementsSecondaryContactEmail',
    'partnerStatementsSecondaryContactPhone',
    'retirementPlan',
    'foreignPartner',
    'Rep7EUandUK',
    'Rep8Switzerland',
  ],
  FORESITELABS: [
    'hcm',
    'institutionalAccountStatus',
    'partnerStatementsPrimaryContactName',
    'partnerStatementsPrimaryContactEmail',
    'partnerStatementsPrimaryContactPhone',
    'partnerStatementsSecondaryContactName',
    'partnerStatementsSecondaryContactEmail',
    'partnerStatementsSecondaryContactPhone',
    'rule5130_finraNewIssues_1',
    'rule5130_finraNewIssues_2',
    'rule5131_finraNewIssues_1',
    'rule5131_finraNewIssues_2',
    'retirementPlan',
    'foreignPartner',
    'Rep7EUandUK',
    'Rep8Switzerland',
  ],
  LIMITED_PARTNER_ABBR: [
    'governmentEntity',
    'bhcaPartner',
    'foundationPartner',
    'publicPensionPartner',
    'wiringBankCustomer',
    'country',
    'fatfCountry',
    'wireInfo',
    'usPersonSecAct',
    'badActor',
    'finraAffiliation',
    'foresite_investor_type',
    'foresiteFinraAffiliation',
    'foresiteFinraAffiliationFurtherDetails',
    'foresitePublicCompanyAffiliation',
    'foresitePublicCompanyAffiliationFurtherDetails',
    'foresiteEntities',
    'foresiteEntitiesFurtherDetails',
    'hcm',
    'institutionalAccountStatus',
    'partnerStatementsPrimaryContactName',
    'partnerStatementsPrimaryContactEmail',
    'partnerStatementsPrimaryContactPhone',
    'partnerStatementsSecondaryContactName',
    'partnerStatementsSecondaryContactEmail',
    'partnerStatementsSecondaryContactPhone',
    'rule5130_finraNewIssues_1',
    'rule5130_finraNewIssues_2',
    'rule5131_finraNewIssues_1',
    'rule5131_finraNewIssues_2',
    'finra',
    'retirementPlan',
    'foreignPartner',
    'Rep7EUandUK',
    'Rep8Switzerland',
  ],
  IOLAR: [
    'governmentEntity',
    'bhcaPartner',
    'foundationPartner',
    'publicPensionPartner',
    'wiringBankCustomer',
    'country',
    'fatfCountry',
    'wireInfo',
    'usPersonSecAct',
    'badActor',
    'finraAffiliation',
    'foresite_investor_type',
    'foresiteFinraAffiliation',
    'foresiteFinraAffiliationFurtherDetails',
    'foresitePublicCompanyAffiliation',
    'foresitePublicCompanyAffiliationFurtherDetails',
    'foresiteEntities',
    'foresiteEntitiesFurtherDetails',
    'hcm',
    'institutionalAccountStatus',
    'partnerStatementsPrimaryContactName',
    'partnerStatementsPrimaryContactEmail',
    'partnerStatementsPrimaryContactPhone',
    'partnerStatementsSecondaryContactName',
    'partnerStatementsSecondaryContactEmail',
    'partnerStatementsSecondaryContactPhone',
    'rule5130_finraNewIssues_1',
    'rule5130_finraNewIssues_2',
    'rule5131_finraNewIssues_1',
    'rule5131_finraNewIssues_2',
    'finra',
    'retirementPlan',
    'foreignPartner',
    'Rep7EUandUK',
    'Rep8Switzerland',
  ],
  OSAGE: [
    'foresite_investor_type',
    'foresiteFinraAffiliation',
    'foresiteFinraAffiliationFurtherDetails',
    'foresitePublicCompanyAffiliation',
    'foresitePublicCompanyAffiliationFurtherDetails',
    'foresiteEntities',
    'foresiteEntitiesFurtherDetails',
    'hcm',
    'institutionalAccountStatus',
    'retirementPlan',
    'foreignPartner',
    'Rep7EUandUK',
    'Rep8Switzerland',
  ],
  NEW_LIMITED_PARTNER: [
    'foresite_investor_type',
    'foresiteFinraAffiliation',
    'foresiteFinraAffiliationFurtherDetails',
    'foresitePublicCompanyAffiliation',
    'foresitePublicCompanyAffiliationFurtherDetails',
    'foresiteEntities',
    'foresiteEntitiesFurtherDetails',
    'hcm',
    'institutionalAccountStatus',
    'partnerStatementsPrimaryContactName',
    'partnerStatementsPrimaryContactEmail',
    'partnerStatementsPrimaryContactPhone',
    'partnerStatementsSecondaryContactName',
    'partnerStatementsSecondaryContactEmail',
    'partnerStatementsSecondaryContactPhone',
    'rule5130_finraNewIssues_1',
    'rule5130_finraNewIssues_2',
    'rule5131_finraNewIssues_1',
    'rule5131_finraNewIssues_2',
    'retirementPlan',
    'foreignPartner',
    'Rep7EUandUK',
    'Rep8Switzerland',
  ],
   TCG_CROSSOVER: [
    'foresite_investor_type',
    'foresiteFinraAffiliation',
    'foresiteFinraAffiliationFurtherDetails',
    'foresitePublicCompanyAffiliation',
    'foresitePublicCompanyAffiliationFurtherDetails',
    'foresiteEntities',
    'foresiteEntitiesFurtherDetails',
    'hcm',
    'institutionalAccountStatus',
    'partnerStatementsPrimaryContactName',
    'partnerStatementsPrimaryContactEmail',
    'partnerStatementsPrimaryContactPhone',
    'partnerStatementsSecondaryContactName',
    'partnerStatementsSecondaryContactEmail',
    'partnerStatementsSecondaryContactPhone',
    'rule5130_finraNewIssues_1',
    'rule5130_finraNewIssues_2',
    'rule5131_finraNewIssues_1',
    'rule5131_finraNewIssues_2',
    'retirementPlan',
    'foreignPartner',
    'Rep7EUandUK',
    'Rep8Switzerland',
  ],
   BITKRAFT: [
    'foresite_investor_type',
    'foresiteFinraAffiliation',
    'foresiteFinraAffiliationFurtherDetails',
    'foresitePublicCompanyAffiliation',
    'foresitePublicCompanyAffiliationFurtherDetails',
    'foresiteEntities',
    'foresiteEntitiesFurtherDetails',
    'hcm',
    'institutionalAccountStatus',
    'partnerStatementsPrimaryContactName',
    'partnerStatementsPrimaryContactEmail',
    'partnerStatementsPrimaryContactPhone',
    'partnerStatementsSecondaryContactName',
    'partnerStatementsSecondaryContactEmail',
    'partnerStatementsSecondaryContactPhone',
    'rule5130_finraNewIssues_1',
    'rule5130_finraNewIssues_2',
    'rule5131_finraNewIssues_1',
    'rule5131_finraNewIssues_2',
    'retirementPlan',
    'foreignPartner',
    'Rep7EUandUK',
    'Rep8Switzerland',
  ],
  ELEPHANT_PARTNER: [
    'foresite_investor_type',
    'foresiteFinraAffiliation',
    'foresiteFinraAffiliationFurtherDetails',
    'foresitePublicCompanyAffiliation',
    'foresitePublicCompanyAffiliationFurtherDetails',
    'foresiteEntities',
    'foresiteEntitiesFurtherDetails',
    'hcm',
    'institutionalAccountStatus',
    'partnerStatementsPrimaryContactName',
    'partnerStatementsPrimaryContactEmail',
    'partnerStatementsPrimaryContactPhone',
    'partnerStatementsSecondaryContactName',
    'partnerStatementsSecondaryContactEmail',
    'partnerStatementsSecondaryContactPhone',
    'rule5130_finraNewIssues_1',
    'rule5130_finraNewIssues_2',
    'rule5131_finraNewIssues_1',
    'rule5131_finraNewIssues_2',
    'retirementPlan',
    'foreignPartner',
    'Rep7EUandUK',
    'Rep8Switzerland',
  ],
  FIREWORK: [
    'foresite_investor_type',
    'foresiteFinraAffiliation',
    'foresiteFinraAffiliationFurtherDetails',
    'foresitePublicCompanyAffiliation',
    'foresitePublicCompanyAffiliationFurtherDetails',
    'foresiteEntities',
    'foresiteEntitiesFurtherDetails',
    'hcm',
    'institutionalAccountStatus',
    'partnerStatementsPrimaryContactName',
    'partnerStatementsPrimaryContactEmail',
    'partnerStatementsPrimaryContactPhone',
    'partnerStatementsSecondaryContactName',
    'partnerStatementsSecondaryContactEmail',
    'partnerStatementsSecondaryContactPhone',
    'rule5130_finraNewIssues_1',
    'rule5130_finraNewIssues_2',
    'rule5131_finraNewIssues_1',
    'rule5131_finraNewIssues_2',
    'retirementPlan',
    'foreignPartner',
    'Rep7EUandUK',
    'Rep8Switzerland',
  ],
   PROMETHEAN: [
    'foresite_investor_type',
    'foresiteFinraAffiliation',
    'foresiteFinraAffiliationFurtherDetails',
    'foresitePublicCompanyAffiliation',
    'foresitePublicCompanyAffiliationFurtherDetails',
    'foresiteEntities',
    'foresiteEntitiesFurtherDetails',
    'hcm',
    'institutionalAccountStatus',
    'partnerStatementsPrimaryContactName',
    'partnerStatementsPrimaryContactEmail',
    'partnerStatementsPrimaryContactPhone',
    'partnerStatementsSecondaryContactName',
    'partnerStatementsSecondaryContactEmail',
    'partnerStatementsSecondaryContactPhone',
    'rule5130_finraNewIssues_1',
    'rule5130_finraNewIssues_2',
    'rule5131_finraNewIssues_1',
    'rule5131_finraNewIssues_2',
    'retirementPlan',
    'foreignPartner',
    'Rep7EUandUK',
    'Rep8Switzerland',
  ],
  ACCEL: [
    'governmentEntity',
    'bhcaPartner',
    'foundationPartner',
    'publicPensionPartner',
    'wiringBankCustomer',
    'country',
    'fatfCountry',
    'wireInfo',
    'usPersonSecAct',
    'badActor',
    'finraAffiliation',
    'foresite_investor_type',
    'foresiteFinraAffiliation',
    'foresiteFinraAffiliationFurtherDetails',
    'foresitePublicCompanyAffiliation',
    'foresitePublicCompanyAffiliationFurtherDetails',
    'foresiteEntities',
    'foresiteEntitiesFurtherDetails',
    'hcm',
    'institutionalAccountStatus',
    'partnerStatementsPrimaryContactName',
    'partnerStatementsPrimaryContactEmail',
    'partnerStatementsPrimaryContactPhone',
    'partnerStatementsSecondaryContactName',
    'partnerStatementsSecondaryContactEmail',
    'partnerStatementsSecondaryContactPhone',
    'rule5130_finraNewIssues_1',
    'rule5130_finraNewIssues_2',
    'rule5131_finraNewIssues_1',
    'rule5131_finraNewIssues_2',
    'finra',
    'retirementPlan',
    'foreignPartner',
    'Rep7EUandUK',
    'Rep8Switzerland',
  ],
  ACCELMAIN: [
    'beneficialOwner',
    'usBeneficial',
    'taxedPartnership',
    'taxedAsPartnership',
    'usBeneficialOwners',
    'exemptedEntityStatus',
    'look_c',
    'look_d',
    'look_e',
    'foresite_investor_type',
    'foresiteFinraAffiliation',
    'foresiteFinraAffiliationFurtherDetails',
    'foresitePublicCompanyAffiliation',
    'foresitePublicCompanyAffiliationFurtherDetails',
    'foresiteEntities',
    'foresiteEntitiesFurtherDetails',
    'hcm',
    'institutionalAccountStatus',
    'partnerStatementsPrimaryContactName',
    'partnerStatementsPrimaryContactEmail',
    'partnerStatementsPrimaryContactPhone',
    'partnerStatementsSecondaryContactName',
    'partnerStatementsSecondaryContactEmail',
    'partnerStatementsSecondaryContactPhone',
    'rule5130_finraNewIssues_1',
    'rule5130_finraNewIssues_2',
    'rule5131_finraNewIssues_1',
    'rule5131_finraNewIssues_2',
  ],
  LIMITED_PARTNER_MULTI_FUND: [
    'governmentEntity',
    'bhcaPartner',
    'foundationPartner',
    'publicPensionPartner',
    'wiringBankCustomer',
    'country',
    'fatfCountry',
    'wireInfo',
    'usPersonSecAct',
    'badActor',
    'finraAffiliation',
    'foresite_investor_type',
    'foresiteFinraAffiliation',
    'foresiteFinraAffiliationFurtherDetails',
    'foresitePublicCompanyAffiliation',
    'foresitePublicCompanyAffiliationFurtherDetails',
    'foresiteEntities',
    'foresiteEntitiesFurtherDetails',
    'hcm',
    'institutionalAccountStatus',
    'partnerStatementsPrimaryContactName',
    'partnerStatementsPrimaryContactEmail',
    'partnerStatementsPrimaryContactPhone',
    'partnerStatementsSecondaryContactName',
    'partnerStatementsSecondaryContactEmail',
    'partnerStatementsSecondaryContactPhone',
    'rule5130_finraNewIssues_1',
    'rule5130_finraNewIssues_2',
    'rule5131_finraNewIssues_1',
    'rule5131_finraNewIssues_2',
    'finra',
    'retirementPlan',
    'foreignPartner',
    'Rep7EUandUK',
    'Rep8Switzerland',
  ],
  LIMITED_PARTNERSHIP_MULTI_FUND: [
    'foresite_investor_type',
    'foresiteFinraAffiliation',
    'foresiteFinraAffiliationFurtherDetails',
    'foresitePublicCompanyAffiliation',
    'foresitePublicCompanyAffiliationFurtherDetails',
    'foresiteEntities',
    'foresiteEntitiesFurtherDetails',
    'hcm',
    'institutionalAccountStatus',
    'partnerStatementsPrimaryContactName',
    'partnerStatementsPrimaryContactEmail',
    'partnerStatementsPrimaryContactPhone',
    'partnerStatementsSecondaryContactName',
    'partnerStatementsSecondaryContactEmail',
    'partnerStatementsSecondaryContactPhone',
    'rule5130_finraNewIssues_1',
    'rule5130_finraNewIssues_2',
    'rule5131_finraNewIssues_1',
    'rule5131_finraNewIssues_2',
    'retirementPlan',
    'foreignPartner',
    'Rep7EUandUK',
    'Rep8Switzerland',
  ],
};

export const EXCLUDE_HEADERS = {
  LIMITED_PARTNER: [
    'Foresite Investor Type',
    'Foresite FINRA Affiliation',
    'Foresite FINRA Affiliation Further Details',
    'Foresite Public Company Affiliation',
    'Foresite Public Company Affiliation Further Details',
    'Foresite Entities',
    'Foresite Entities Further Details',
    'Institutional Account Status',
    'Finra Rule 5130 1',
    'Finra Rule 5130 2',
    'Finra Rule 5131 1',
    'Finra Rule 5131 2',
  ],
  CAYMAN: [
    'Foresite Investor Type',
    'Foresite FINRA Affiliation',
    'Foresite FINRA Affiliation Further Details',
    'Foresite Public Company Affiliation',
    'Foresite Public Company Affiliation Further Details',
    'Foresite Entities',
    'Foresite Entities Further Details',
    'Institutional Account Status',
    'Finra Rule 5130 1',
    'Finra Rule 5130 2',
    'Finra Rule 5131 1',
    'Finra Rule 5131 2',
  ],
   AUDERE: [
    'Foresite Investor Type',
    'Foresite FINRA Affiliation',
    'Foresite FINRA Affiliation Further Details',
    'Foresite Public Company Affiliation',
    'Foresite Public Company Affiliation Further Details',
    'Foresite Entities',
    'Foresite Entities Further Details',
    'Institutional Account Status',
    'Finra Rule 5130 1',
    'Finra Rule 5130 2',
    'Finra Rule 5131 1',
    'Finra Rule 5131 2',
  ],
  LIMITED_LIABILITY_COMPANY: [
    'Foresite Investor Type',
    'Foresite FINRA Affiliation',
    'Foresite FINRA Affiliation Further Details',
    'Foresite Public Company Affiliation',
    'Foresite Public Company Affiliation Further Details',
    'Foresite Entities',
    'Foresite Entities Further Details',
    'Institutional Account Status',
    'Finra Rule 5130 1',
    'Finra Rule 5130 2',
    'Finra Rule 5131 1',
    'Finra Rule 5131 2',
  ],
  FORESITE: [
    'Institutional Account Status',
    'Finra Rule 5130 1',
    'Finra Rule 5130 2',
    'Finra Rule 5131 1',
    'Finra Rule 5131 2',
  ],
  QUESTACAPITAL: [
    'Foresite Investor Type',
    'Foresite FINRA Affiliation',
    'Foresite FINRA Affiliation Further Details',
    'Foresite Public Company Affiliation',
    'Foresite Public Company Affiliation Further Details',
    'Foresite Entities',
    'Foresite Entities Further Details',
    'Finra Rule 5130 1',
    'Finra Rule 5130 2',
    'Finra Rule 5131 1',
    'Finra Rule 5131 2',
  ],
  INITIALIZED: [
    'Foresite Investor Type',
    'Foresite FINRA Affiliation',
    'Foresite FINRA Affiliation Further Details',
    'Foresite Public Company Affiliation',
    'Foresite Public Company Affiliation Further Details',
    'Foresite Entities',
    'Foresite Entities Further Details',
    'Institutional Account Status',
    'Finra Rule 5130 1',
    'Finra Rule 5130 2',
    'Finra Rule 5131 1',
    'Finra Rule 5131 2',
  ],
  HCM: [
    'Foresite Investor Type',
    'Foresite FINRA Affiliation',
    'Foresite FINRA Affiliation Further Details',
    'Foresite Public Company Affiliation',
    'Foresite Public Company Affiliation Further Details',
    'Foresite Entities',
    'Foresite Entities Further Details',
    'Institutional Account Status',
    'Finra Rule 5130 1',
    'Finra Rule 5130 2',
    'Finra Rule 5131 1',
    'Finra Rule 5131 2',
  ],
  FORESITELABS: [
    'Institutional Account Status',
    'Finra Rule 5130 1',
    'Finra Rule 5130 2',
    'Finra Rule 5131 1',
    'Finra Rule 5131 2',
  ],
  LIMITED_PARTNER_ABBR: [
    'Government Entity?',
    'BHCA Partner?',
    'Foundation Partner?',
    'Public Pension Partner?',
    'Customer of Wiring Bank?',
    'If no, Country?',
    'FATF Country',
    'Wire Info?',
    'US Person (SEC Act)?',
    'Bad Actor?',
    'FINRA',
    'Foresite Investor Type',
    'Foresite FINRA Affiliation',
    'Foresite FINRA Affiliation Further Details',
    'Foresite Public Company Affiliation',
    'Foresite Public Company Affiliation Further Details',
    'Foresite Entities',
    'Foresite Entities Further Details',
    'Institutional Account Status',
    'Finra Rule 5130 1',
    'Finra Rule 5130 2',
    'Finra Rule 5131 1',
    'Finra Rule 5131 2',
  ],
   IOLAR: [
    'Government Entity?',
    'BHCA Partner?',
    'Foundation Partner?',
    'Public Pension Partner?',
    'Customer of Wiring Bank?',
    'If no, Country?',
    'FATF Country',
    'Wire Info?',
    'US Person (SEC Act)?',
    'Bad Actor?',
    'FINRA',
    'Foresite Investor Type',
    'Foresite FINRA Affiliation',
    'Foresite FINRA Affiliation Further Details',
    'Foresite Public Company Affiliation',
    'Foresite Public Company Affiliation Further Details',
    'Foresite Entities',
    'Foresite Entities Further Details',
    'Institutional Account Status',
    'Finra Rule 5130 1',
    'Finra Rule 5130 2',
    'Finra Rule 5131 1',
    'Finra Rule 5131 2',
  ],
  OSAGE: [
    'Foresite Investor Type',
    'Foresite FINRA Affiliation',
    'Foresite FINRA Affiliation Further Details',
    'Foresite Public Company Affiliation',
    'Foresite Public Company Affiliation Further Details',
    'Foresite Entities',
    'Foresite Entities Further Details',
    'Institutional Account Status',
  ],
  NEW_LIMITED_PARTNER: [
    'Foresite Investor Type',
    'Foresite FINRA Affiliation',
    'Foresite FINRA Affiliation Further Details',
    'Foresite Public Company Affiliation',
    'Foresite Public Company Affiliation Further Details',
    'Foresite Entities',
    'Foresite Entities Further Details',
    'Institutional Account Status',
    'Finra Rule 5130 1',
    'Finra Rule 5130 2',
    'Finra Rule 5131 1',
    'Finra Rule 5131 2',
  ],
   TCG_CROSSOVER: [
    'Foresite Investor Type',
    'Foresite FINRA Affiliation',
    'Foresite FINRA Affiliation Further Details',
    'Foresite Public Company Affiliation',
    'Foresite Public Company Affiliation Further Details',
    'Foresite Entities',
    'Foresite Entities Further Details',
    'Institutional Account Status',
    'Finra Rule 5130 1',
    'Finra Rule 5130 2',
    'Finra Rule 5131 1',
    'Finra Rule 5131 2',
  ],
  BITKRAFT: [
    'Foresite Investor Type',
    'Foresite FINRA Affiliation',
    'Foresite FINRA Affiliation Further Details',
    'Foresite Public Company Affiliation',
    'Foresite Public Company Affiliation Further Details',
    'Foresite Entities',
    'Foresite Entities Further Details',
    'Institutional Account Status',
    'Finra Rule 5130 1',
    'Finra Rule 5130 2',
    'Finra Rule 5131 1',
    'Finra Rule 5131 2',
  ],
   ELEPHANT_PARTNER: [
    'Foresite Investor Type',
    'Foresite FINRA Affiliation',
    'Foresite FINRA Affiliation Further Details',
    'Foresite Public Company Affiliation',
    'Foresite Public Company Affiliation Further Details',
    'Foresite Entities',
    'Foresite Entities Further Details',
    'Institutional Account Status',
    'Finra Rule 5130 1',
    'Finra Rule 5130 2',
    'Finra Rule 5131 1',
    'Finra Rule 5131 2',
  ],
   FIREWORK: [
    'Foresite Investor Type',
    'Foresite FINRA Affiliation',
    'Foresite FINRA Affiliation Further Details',
    'Foresite Public Company Affiliation',
    'Foresite Public Company Affiliation Further Details',
    'Foresite Entities',
    'Foresite Entities Further Details',
    'Institutional Account Status',
    'Finra Rule 5130 1',
    'Finra Rule 5130 2',
    'Finra Rule 5131 1',
    'Finra Rule 5131 2',
  ],
  PROMETHEAN: [
    'Foresite Investor Type',
    'Foresite FINRA Affiliation',
    'Foresite FINRA Affiliation Further Details',
    'Foresite Public Company Affiliation',
    'Foresite Public Company Affiliation Further Details',
    'Foresite Entities',
    'Foresite Entities Further Details',
    'Institutional Account Status',
    'Finra Rule 5130 1',
    'Finra Rule 5130 2',
    'Finra Rule 5131 1',
    'Finra Rule 5131 2',
  ],
   ACCEL: [
    'Government Entity?',
    'BHCA Partner?',
    'Foundation Partner?',
    'Public Pension Partner?',
    'Customer of Wiring Bank?',
    'If no, Country?',
    'FATF Country',
    'Wire Info?',
    'US Person (SEC Act)?',
    'Bad Actor?',
    'FINRA',
    'Foresite Investor Type',
    'Foresite FINRA Affiliation',
    'Foresite FINRA Affiliation Further Details',
    'Foresite Public Company Affiliation',
    'Foresite Public Company Affiliation Further Details',
    'Foresite Entities',
    'Foresite Entities Further Details',
    'Institutional Account Status',
    'Finra Rule 5130 1',
    'Finra Rule 5130 2',
    'Finra Rule 5131 1',
    'Finra Rule 5131 2',
  ],
  ACCELMAIN: [
    'Taxed as a Partnership',
    '100% U.S. Beneficial Owners',
    'Tax Exempt Beneficial Owners',
    'Lookthrough (c)',
    'Lookthrough (d)',
    'Lookthrough (e)',
     'Foresite Investor Type',
    'Foresite FINRA Affiliation',
    'Foresite FINRA Affiliation Further Details',
    'Foresite Public Company Affiliation',
    'Foresite Public Company Affiliation Further Details',
    'Foresite Entities',
    'Foresite Entities Further Details',
    'Institutional Account Status',
    'Finra Rule 5130 1',
    'Finra Rule 5130 2',
    'Finra Rule 5131 1',
    'Finra Rule 5131 2',
  ],
  LIMITED_PARTNER_MULTI_FUND: [
    'Government Entity?',
    'BHCA Partner?',
    'Foundation Partner?',
    'Public Pension Partner?',
    'Customer of Wiring Bank?',
    'If no, Country?',
    'FATF Country',
    'Wire Info?',
    'US Person (SEC Act)?',
    'Bad Actor?',
    'FINRA',
    'Foresite Investor Type',
    'Foresite FINRA Affiliation',
    'Foresite FINRA Affiliation Further Details',
    'Foresite Public Company Affiliation',
    'Foresite Public Company Affiliation Further Details',
    'Foresite Entities',
    'Foresite Entities Further Details',
    'Institutional Account Status',
    'Finra Rule 5130 1',
    'Finra Rule 5130 2',
    'Finra Rule 5131 1',
    'Finra Rule 5131 2',
  ],
  LIMITED_PARTNERSHIP_MULTI_FUND: [
    'Foresite Investor Type',
    'Foresite FINRA Affiliation',
    'Foresite FINRA Affiliation Further Details',
    'Foresite Public Company Affiliation',
    'Foresite Public Company Affiliation Further Details',
    'Foresite Entities',
    'Foresite Entities Further Details',
    'Institutional Account Status',
    'Finra Rule 5130 1',
    'Finra Rule 5130 2',
    'Finra Rule 5131 1',
    'Finra Rule 5131 2',
  ],
};
