/* eslint-disable react/sort-comp,import/prefer-default-export,no-shadow, no-loop-func,
 no-nested-ternary */

/* @flow */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import type { Connector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import './../../../../styles_common/questionnaire_form.scss';
import * as notify from '../../../../../../utils/notify';
import {
  Context as ContextType,
  QuestionnaireExperience as QuestionnaireExperienceContext,
  Reducer,
  Dispatch,
} from '../../../../../../types/index';
import TextInput from '../../../../../../components/TextInput/index.uncontrolled';
import Button from '../../../../../../components/Button/index';
import TextArea from '../../../../../../components/TextArea/index.uncontrolled';
import Title from '../../../../../../components/Title/index';
import ConfirmCheckbox from '../../../../../../components/ConfirmCheckbox/index';
import MultiSelect from '../../../../../../components/MultiSelect/index';
import { QuestionnaireIndex } from '../QuestionnaireIndex/index.v2';
import Alert from '../../../../../../components/Alert/index';
import Paragraph from '../../../../../../components/Paragraph/index';
import FundTool from '../../../../../../components/FundTool/index';
import Tooltip from '../../../../../../components/Tooltip/index';
import * as iqID from '../../../../../../utils/constants/questionnaire/identifiers';
import { saveGeneralInformation } from '../../../../util';

type Props = {
  context: ContextType,
  questionnaireContext: QuestionnaireExperienceContext,
};

export class QuestionnaireForm extends PureComponent {
  props: Props;

  static defaultProps: {
    context: {},
  };

  handleTextInputChange = (event) => {
    event.preventDefault();
    const { questionnaireContext } = this.props;
    const name = event.target.name;
    const value = event.target.value;

    const payload = {};
    if (questionnaireContext.multiAccess) {
      payload.limitedPartnerFund =
        questionnaireContext.investorFund._id.toString();
    } else {
      payload.investorFund = questionnaireContext.investorFund._id.toString();
    }

    const generalInfo = questionnaireContext.investorFund.generalInfo;
    payload.name = generalInfo.name;
    payload.signatory = generalInfo.signatory;
    payload.tax = generalInfo.tax;
    payload.country = generalInfo.country;
    payload.countryPlace = generalInfo.countryPlace;
    payload.types = generalInfo.types;
    payload.otherValue = generalInfo.otherValue;
    payload.namePrimaryContact = generalInfo.namePrimaryContact;
    payload.emailPrimaryContact = generalInfo.emailPrimaryContact;
    payload.addressPrimaryContact = generalInfo.addressPrimaryContact;
    payload.phonePrimaryContact = generalInfo.phonePrimaryContact;
    payload.capitalCommitment = generalInfo.capitalCommitment;
    payload.nameLetter = generalInfo.nameLetter;
    payload.totalAllFund = generalInfo.totalAllFund;
    payload.additionalContactInfo = generalInfo.additionalContactInfo;
    payload.additionalDistributionInfo = generalInfo.additionalDistributionInfo;
    // eslint-disable-next-line
    payload.principalPlaceOfBusiness =
      generalInfo.principalPlaceOfBusiness || '';
    payload.principalPlaceOfBusinessPrimaryContact =
      generalInfo.principalPlaceOfBusinessPrimaryContact || '';
    // eslint-disable-next-line
    payload.principalPlaceOfResidence =
      generalInfo.principalPlaceOfResidence || '';
    payload.principalPlaceOfResidencePrimaryContact =
      generalInfo.principalPlaceOfResidencePrimaryContact || '';
    // eslint-disable-next-line
    payload.jurisdictionOrganitzationPrimaryContact =
      generalInfo.jurisdictionOrganitzationPrimaryContact;
    payload.reportsPrimaryContactName = generalInfo.reportsPrimaryContactName;
    payload.reportsPrimaryContactEmail = generalInfo.reportsPrimaryContactEmail;
    payload.reportsPrimaryContactPhone = generalInfo.reportsPrimaryContactPhone;
    payload.reportsSecondaryContactName =
      generalInfo.reportsSecondaryContactName;
    payload.reportsSecondaryContactEmail =
      generalInfo.reportsSecondaryContactEmail;
    payload.reportsSecondaryContactPhone =
      generalInfo.reportsSecondaryContactPhone;
    payload.taxPrimaryContactName = generalInfo.taxPrimaryContactName;
    payload.taxPrimaryContactEmail = generalInfo.taxPrimaryContactEmail;
    payload.taxPrimaryContactPhone = generalInfo.taxPrimaryContactPhone;
    payload.taxSecondaryContactName = generalInfo.taxSecondaryContactName;
    payload.taxSecondaryContactEmail = generalInfo.taxSecondaryContactEmail;
    payload.taxSecondaryContactPhone = generalInfo.taxSecondaryContactPhone;
    payload.capitalPrimaryContactName = generalInfo.capitalPrimaryContactName;
    payload.capitalPrimaryContactEmail = generalInfo.capitalPrimaryContactEmail;
    payload.capitalPrimaryContactPhone = generalInfo.capitalPrimaryContactPhone;
    payload.capitalSecondaryContactName =
      generalInfo.capitalSecondaryContactName;
    payload.capitalSecondaryContactEmail =
      generalInfo.capitalSecondaryContactEmail;
    payload.capitalSecondaryContactPhone =
      generalInfo.capitalSecondaryContactPhone;
    payload.stockPrimaryContactName = generalInfo.stockPrimaryContactName;
    payload.stockPrimaryContactEmail = generalInfo.stockPrimaryContactEmail;
    payload.stockPrimaryContactPhone = generalInfo.stockPrimaryContactPhone;
    payload.stockSecondaryContactName = generalInfo.stockSecondaryContactName;
    payload.stockSecondaryContactEmail = generalInfo.stockSecondaryContactEmail;
    payload.stockSecondaryContactPhone = generalInfo.stockSecondaryContactPhone;
    payload.legalPrimaryContactName = generalInfo.legalPrimaryContactName;
    payload.legalPrimaryContactEmail = generalInfo.legalPrimaryContactEmail;
    payload.legalPrimaryContactPhone = generalInfo.legalPrimaryContactPhone;
    payload.legalSecondaryContactName = generalInfo.legalSecondaryContactName;
    payload.legalSecondaryContactEmail = generalInfo.legalSecondaryContactEmail;
    payload.legalSecondaryContactPhone = generalInfo.legalSecondaryContactPhone;
    payload.bankName = generalInfo.bankName;
    payload.acctName = generalInfo.acctName;
    payload.swiftCode = generalInfo.swiftCode;
    payload.ibanNumber = generalInfo.ibanNumber;
    payload.interBankAcctNumber = generalInfo.interBankAcctNumber;
    payload.interBankAcctName = generalInfo.interBankAcctName;
    payload.interBankAba = generalInfo.interBankAba;
    payload.interBankName = generalInfo.interBankName;
    payload.interBankSwift = generalInfo.interBankSwift;
    payload.additionalComment = generalInfo.additionalComment;
    payload.acctNumber = generalInfo.acctNumber;
    payload.bankaba = generalInfo.bankaba;
    payload.furtherCredit = generalInfo.furtherCredit;
    payload.furtherCreditNumber = generalInfo.furtherCreditNumber;
    payload.location = generalInfo.location;

    switch (name) {
      case 'legalNameBeneficalOwner':
        payload.legalNameBeneficalOwner = value;
        payload.residenceBeneficalOwner =
          questionnaireContext.investorFund.generalInfo.residenceBeneficalOwner;

        break;
      case 'residenceBeneficalOwner':
        payload.residenceBeneficalOwner = value;
        payload.legalNameBeneficalOwner =
          questionnaireContext.investorFund.generalInfo.legalNameBeneficalOwner;

        break;
    }
    saveGeneralInformation(payload, this.props);
  };

  componentDidMount() {
    this.updateTextAnswersState();
    notify.hideNotification();
  }

  updateTextAnswersState = () => {
    
    // repopulate text inputs values if they exist
    const id_wiring_bank_status_Data = this.parseQuestionDataFromId(
      iqID.id_wiring_bank_status_1_opt
    );
    const id_special_entities_1_b_opt_Data = this.parseQuestionDataFromId(
      iqID.id_special_entities_1_b_opt
    );
    const id_special_entities_2_e_opt_Data = this.parseQuestionDataFromId(
      iqID.id_special_entities_2_e_opt
    );
    const id_warranties_exceptions_Data = this.parseQuestionDataFromId(
      iqID.id_warranties_exceptions
    );
    const id_investor_status_7_tcg_o_Data = this.parseQuestionDataFromId(
      iqID.id_investor_status_7_tcg_o
    );

    if (this.id_investor_status_7_tcg_o_Ref) {
      this.id_investor_status_7_tcg_o_Ref.value = id_investor_status_7_tcg_o_Data && id_investor_status_7_tcg_o_Data.answer
      ? id_investor_status_7_tcg_o_Data.description
      : ' ';
    }

    if (this.id_wiring_bank_status_1_opt_Ref) {
      this.id_wiring_bank_status_1_opt_Ref.value = id_wiring_bank_status_Data
        ? id_wiring_bank_status_Data.answer
        : '';
    }
    if (this.id_special_entities_1_b_opt_Ref) {
      this.id_special_entities_1_b_opt_Ref.value =
        id_special_entities_1_b_opt_Data
          ? id_special_entities_1_b_opt_Data.answer
          : '';
    }

    if (this.id_special_entities_2_e_opt_Ref) {
      this.id_special_entities_2_e_opt_Ref.value =
        id_special_entities_2_e_opt_Data
          ? id_special_entities_2_e_opt_Data.answer
          : '';
    }

    this.id_warranties_exceptions_Ref.value = id_warranties_exceptions_Data
      ? id_warranties_exceptions_Data.answer
      : '';
  };
  handlerFormSubmit = (event) => {
    const { questionnaireContext } = this.props;
    const nextStep = questionnaireContext.currentStep + 1;
    this.props.handleRedirect(event, nextStep);
  };
  backStep = (e) => {
    const { questionnaireContext } = this.props;
    this.props.handleRedirect(e, questionnaireContext.currentStep - 1);
  };

  parseCheckboxStateFromId = (_identifier) => {
    const { questionnaireContext } = this.props;
    const questions =
      questionnaireContext.investorFund.questionnaire.questions || [];
    if (questions && questions.length) {
      const targetQuestion = questions.find(
        (_q) => _q.descriptionIdentifier === _identifier
      );

      if (targetQuestion) {
        return [
          targetQuestion.answer === 'Yes',
          targetQuestion.answer === 'No',
        ];
      }
      return [false, false];
    }
    return [false, false];
  };
  parseMultiSelectStateFromId = (_identifier) => {
    const { questionnaireContext } = this.props;
    const questions =
      questionnaireContext.investorFund.questionnaire.questions || [];
    if (questions && questions.length) {
      const targetQuestion = questions.find(
        (_q) => _q.descriptionIdentifier === _identifier
      );
      if (targetQuestion) {
        return targetQuestion.answer === 'Yes';
      }
      return false;
    }
    return false;
  };
  parseQuestionDataFromId = (_identifier) => {
    const { questionnaireContext } = this.props;
    let targetQuestion = null;
    const questions =
      questionnaireContext.investorFund.questionnaire.questions || [];
    if (questions && questions.length) {
      targetQuestion = questions.find(
        (_q) => _q.descriptionIdentifier === _identifier
      );
      if (targetQuestion) {
        return targetQuestion;
      }
    }
    return targetQuestion;
  };
  handlerConfirm = (event) => {
    event.preventDefault();
    const answer = event.target.nextSibling.innerHTML;
    const descriptionIdentifier =
      event.target.closest('div').parentElement.parentElement.id;
    this.props.updateMultiSelectFormAnswer(descriptionIdentifier, answer);
  };
  orQuestionSelected = (_checked, _identifier, _targetSection) => {
    const {
      questionnaireContext: { currentVersionQuestions },
    } = this.props;
    const targetQuestionsLookup = currentVersionQuestions[_targetSection];
    for (let i = 0; i < targetQuestionsLookup.length; i += 1) {
      const item = targetQuestionsLookup[i];
      // if the item was selected other than _or it needs to be reverted
      if (
        item.id !== _identifier &&
        this.parseMultiSelectStateFromId(item.id)
      ) {
        // right now this is saving one by one . But ideally we should do bulks updates or at least Promise all
        const answer = 'No';
        const descriptionIdentifier = item.id;

        this.props.updateMultiSelectFormAnswer(descriptionIdentifier, answer);
      }
    }
  };
  regularQuestionSelected = (_checked, _identifier, _targetSection) => {
    const {
      questionnaireContext: { currentVersionQuestions },
    } = this.props;
    const targetQuestionsLookup = currentVersionQuestions[_targetSection];
    for (let i = 0; i < targetQuestionsLookup.length; i += 1) {
      const item = targetQuestionsLookup[i];
      // if the item was selected other than _or it needs to be reverted
      if (
        item.id.includes('_or') &&
        this.parseMultiSelectStateFromId(item.id)
      ) {
        // can be simplified with the orQuestionSelected on top look for better naming
        const answer = 'No';
        const descriptionIdentifier = item.id;
        this.props.updateMultiSelectFormAnswer(descriptionIdentifier, answer);
      }
    }
  };

  categoriesOfBeneficialOwnership = () => {
    const {
      questionnaireContext,
      questionnaireContext: {
        multiSelectFormMetadata: { disabled: disabledList }, x
      },
    } = this.props;
    const categoriesOfBeneficialOwnershipQuestions = questionnaireContext && 
    questionnaireContext.currentVersionQuestions.categoriesOfBeneficialOwnershipQuestions;
   
    const indexToAlpha = (num = 1) => {
      // ASCII value of first character
      const A = 'A'.charCodeAt(0);
      let numberToCharacter = number => {
          return String.fromCharCode(A + number);
      };
      return numberToCharacter(num);
    };

    const getValue = (value) => this.parseQuestionDataFromId(value) ? this.parseQuestionDataFromId(value).description : ''

    const triggerOptions = (id) =>  {
      if (id === 'id_investor_status_7_tcg_m') {
        return { value: getValue('id_investor_status_7_tcg_m'), id: `id_investor_status_7_tcg_m`, input : 'select', 
          selectOptions: ['','a','b','c', 'd','e' ,'f', 'g', 'h', 'i', 'j', 'k', 'l'], changeHandler: this.handleTextInputBlur, style: {width: '60px'} }
      } else if (id === 'id_investor_status_7_tcg_o') {
        return { id: `id_investor_status_7_tcg_o`, style: {width: '500px'}, input : 'text2', 
        reference: (_node) => this.id_investor_status_7_tcg_o_Ref =_node, 
        blurHandler: (e) => this.handleTextInputBlur(e, this.id_investor_status_7_tcg_o_Ref.value)}
                                      
      } else {
         return {}   
      }
    }

     const results = [];
     if (categoriesOfBeneficialOwnershipQuestions) {
      for (
        let i = 0;
        i < categoriesOfBeneficialOwnershipQuestions.length;
        i += 1
      ) {
        results.push({
          id: categoriesOfBeneficialOwnershipQuestions[i].id,
          displayName: ReactHtmlParser(
            categoriesOfBeneficialOwnershipQuestions[i].displayName
          ),
          name: categoriesOfBeneficialOwnershipQuestions[i].name,
          identifier: categoriesOfBeneficialOwnershipQuestions[i].identifier,
          isSelected: this.parseMultiSelectStateFromId(
            categoriesOfBeneficialOwnershipQuestions[i].id
          ),
          helpText: categoriesOfBeneficialOwnershipQuestions[i].helpText,
          showHelp: categoriesOfBeneficialOwnershipQuestions[i].showHelp,
          disabled: disabledList.includes(categoriesOfBeneficialOwnershipQuestions[i].id),
          triggerOptions: triggerOptions(categoriesOfBeneficialOwnershipQuestions[i].id),
          letter: indexToAlpha(i),
           // this part should come from top part lists
        });
      }
      return results;
    }  
  };

  // send the belonging section here
  handlerMultiSelect = (event, _targetSection) => {
    const answer = event.target.checked ? 'Yes' : 'No';
    const descriptionIdentifier = event.target.id;

    if (event.target.id.includes('_or')) {
      this.orQuestionSelected(
        event.target.checked,
        event.target.id,
        _targetSection
      );
    } else {
      // if any other is marked verify that or question is not selected from the targetSection
      this.regularQuestionSelected(
        event.target.checked,
        event.target.id,
        _targetSection
      );
    }
    this.props.updateMultiSelectFormAnswer(descriptionIdentifier, answer);
  };

  // send the belonging section here
  handlerSingleSelect = (event, _targetSection) => {
    const answer = event.target.checked ? 'Yes' : 'No';
    const descriptionIdentifier = event.target.id;

    this.orQuestionSelected(
      event.target.checked,
      event.target.id,
      _targetSection
    );
    this.props.updateMultiSelectFormAnswer(descriptionIdentifier, answer);
  };

  accreditedInvestorRepresentationsContent = () => {
    const {
      questionnaireContext,
      questionnaireContext: {
        multiSelectFormMetadata: { disabled: disabledList },
      },
    } = this.props;
    const accreditedInvestorRepresentationsQuestions =
      questionnaireContext.currentVersionQuestions
        .accreditedInvestorRepresentations;
    const results = [];
    for (
      let i = 0;
      i < accreditedInvestorRepresentationsQuestions.length;
      i += 1
    ) {
      results.push({
        id: accreditedInvestorRepresentationsQuestions[i].id,
        displayName: ReactHtmlParser(
          accreditedInvestorRepresentationsQuestions[i].displayName
        ),
        name: accreditedInvestorRepresentationsQuestions[i].name,
        identifier: accreditedInvestorRepresentationsQuestions[i].identifier,
        isSelected: this.parseMultiSelectStateFromId(
          accreditedInvestorRepresentationsQuestions[i].id
        ),
        helpText: accreditedInvestorRepresentationsQuestions[i].helpText,
        showHelp: accreditedInvestorRepresentationsQuestions[i].showHelp,
        disabled: disabledList.includes(
          accreditedInvestorRepresentationsQuestions[i].id
        ), // this part should come from top part lists
      });
    }
    return results;
  };

  qualifiedPurchaserRepresentationsContent = () => {
    const {
      questionnaireContext,
      questionnaireContext: {
        multiSelectFormMetadata: { disabled: disabledList },
      },
    } = this.props;
    const results = [];
    const qualifiedPurchaserRepresentations =
      questionnaireContext.currentVersionQuestions
        .qualifiedPurchaserRepresentations;
    for (let i = 0; i < qualifiedPurchaserRepresentations.length; i += 1) {
      results.push({
        id: qualifiedPurchaserRepresentations[i].id,
        displayName: qualifiedPurchaserRepresentations[i].displayName,
        name: qualifiedPurchaserRepresentations[i].name,
        identifier: qualifiedPurchaserRepresentations[i].identifier,
        isSelected: this.parseMultiSelectStateFromId(
          qualifiedPurchaserRepresentations[i].id
        ),
        helpText: qualifiedPurchaserRepresentations[i].helpText,
        showHelp: qualifiedPurchaserRepresentations[i].showHelp,
        disabled: disabledList.includes(
          qualifiedPurchaserRepresentations[i].id
        ),
      });
    }
    return results;
  };

  qualifiedClientRepresentationsContent = () => {
    const {
      questionnaireContext,
      questionnaireContext: {
        multiSelectFormMetadata: { disabled: disabledList },
      },
    } = this.props;
    const qualifiedClientRepresentations =
      questionnaireContext.currentVersionQuestions
        .qualifiedClientRepresentations;
    const results = [];
    for (let i = 0; i < qualifiedClientRepresentations.length; i += 1) {
      results.push({
        id: qualifiedClientRepresentations[i].id,
        displayName: qualifiedClientRepresentations[i].displayName,
        name: qualifiedClientRepresentations[i].name,
        identifier: qualifiedClientRepresentations[i].identifier,
        isSelected: this.parseMultiSelectStateFromId(
          qualifiedClientRepresentations[i].id
        ),
        helpText: qualifiedClientRepresentations[i].helpText,
        showHelp: qualifiedClientRepresentations[i].showHelp,
        disabled: disabledList.includes(qualifiedClientRepresentations[i].id),
      });
    }
    return results;
  };

  foreignPersonStatusContent = () => {
    const {
      questionnaireContext,
      questionnaireContext: {
        multiSelectFormMetadata: { disabled: disabledList },
      },
    } = this.props;
    const foreignPersonStatusRepresentations =
      questionnaireContext.currentVersionQuestions
        .foreignPersonStatusRepresentations;
    const results = [];
    for (let i = 0; i < foreignPersonStatusRepresentations.length; i += 1) {
      results.push({
        id: foreignPersonStatusRepresentations[i].id,
        displayName: foreignPersonStatusRepresentations[i].displayName,
        name: foreignPersonStatusRepresentations[i].name,
        identifier: foreignPersonStatusRepresentations[i].identifier,
        helpText: foreignPersonStatusRepresentations[i].helpText,
        showHelp: foreignPersonStatusRepresentations[i].showHelp,
        isSelected: this.parseMultiSelectStateFromId(
          foreignPersonStatusRepresentations[i].id
        ),
        disabled: disabledList.includes(
          foreignPersonStatusRepresentations[i].id
        ),
      });
    }
    return results;
  };
  foreignPersonStatusContent2 = () => {
    const {
      questionnaireContext,
      questionnaireContext: {
        multiSelectFormMetadata: { disabled: disabledList },
      },
    } = this.props;
    const foreignPersonStatusRepresentations2 =
      questionnaireContext.currentVersionQuestions
        .foreignPersonStatusRepresentations2;
    const results = [];
    for (let i = 0; i < foreignPersonStatusRepresentations2.length; i += 1) {
      results.push({
        id: foreignPersonStatusRepresentations2[i].id,
        displayName: foreignPersonStatusRepresentations2[i].displayName,
        name: foreignPersonStatusRepresentations2[i].name,
        identifier: foreignPersonStatusRepresentations2[i].identifier,
        helpText: foreignPersonStatusRepresentations2[i].helpText,
        showHelp: foreignPersonStatusRepresentations2[i].showHelp,
        isSelected: this.parseMultiSelectStateFromId(
          foreignPersonStatusRepresentations2[i].id
        ),
        disabled: disabledList.includes(
          foreignPersonStatusRepresentations2[i].id
        ),
      });
    }
    return results;
  };
  // This one should be handled as part of the optional questions set at the
  checkBadActorQuestionAbility = (identifier) => {
    if (identifier === iqID.id_investor_status_5_f) {
      if (
        !this.parseMultiSelectStateFromId(iqID.id_investor_status_5_a) &&
        !this.parseMultiSelectStateFromId(iqID.id_investor_status_5_b) &&
        !this.parseMultiSelectStateFromId(iqID.id_investor_status_5_c) &&
        !this.parseMultiSelectStateFromId(iqID.id_investor_status_5_d) &&
        !this.parseMultiSelectStateFromId(iqID.id_investor_status_5_e)
      ) {
        return false; // to show return true;
      }
    }
    return true;
  };

   handleTextInputBlur = (event, currentValue) => {
    event.preventDefault();
    const hasId = [
      'id_investor_status_7_tcg_o',
      'id_investor_status_7_tcg_m',
    ]
    
    const answer = event.target.value;
    const descriptionIdentifier = event.target.id;
    const tcgCross = hasId.includes(descriptionIdentifier)
   
    const current = this.parseQuestionDataFromId(descriptionIdentifier);
    if (!current || current.answer !== currentValue && !tcgCross) {
      this.props.updateMultiSelectFormAnswer(descriptionIdentifier, answer);
    }
    if ((!current || current.answer) && tcgCross) {
     this.props.updateMultiSelectFormAnswer(descriptionIdentifier, 'Yes', answer);
      
    }
    
  };

  renderConfirm = (
    id,
    title,
    isSelected,
    name,
    size,
    helpText,
    changeHandler,
    isDisabled
  ) => {
    //eslint-disable-next-line
    if (!isDisabled) isDisabled = [false, false];
    const inputDataTemp = {
      id,
      title,
      isSelected,
      name,
      size,
      showHelp: helpText && helpText !== '',
      helpText,
      isDisabled,
    };
    return (
      <ConfirmCheckbox data={inputDataTemp} onChangeHandler={changeHandler} />
    );
  };

  render() {
    const {
      context,
      questionnaireContext,
      questionnaireContext: {
        multiSelectFormMetadata: { optionals, disabled: disabledList },
      },
    } = this.props;
    const { includesFormPFQuestion, includesRegulatoryFund } = questionnaireContext.fund;

    const readableDate =
      questionnaireContext.investorFund.questionnaire &&
      questionnaireContext.investorFund.questionnaire.updatedAt
        ? new Date(questionnaireContext.investorFund.questionnaire.updatedAt)
        : '';
    const formattedDate =
      readableDate !== ''
        ? `${
            readableDate.getMonth() + 1
          }/${readableDate.getDate()}/${readableDate.getFullYear()}  ${readableDate.getHours()}:${readableDate.getMinutes()}:${readableDate.getSeconds()}`
        : readableDate;

        const foreignYesOrNo =
      questionnaireContext.currentVersionQuestions
        .foreignPersonStatusRepresentations2[0];

    return questionnaireContext.investorFund.questionnaire.questions ? (
      <div className="margin-bottom-spacing">
        <FundTool
          data={{
            saveLaterAble: true,
            modalToggle: 'modal',
            modalTarget: 'saveLaterPopup',
            lastSavedTimeStamp: formattedDate,
          }}
          printHandler={null}
          saveLaterHandler={null}
        />
        {/* <Alert
          inputData={{
            type: context.notificationType,
            title: context.title,
            message: context.message,
          }}
        /> */}
        <div className="row">
          {' '}
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="content-bg main-content-area clearfix">
              <div className="row">
                <QuestionnaireIndex />
                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                  <div className="main">
                    <h4 className="color-dark-green align-header-text-sub-header questionnaire-header">
                      Questionnaire
                    </h4>
                    <b>
                      <Paragraph
                        text={
                          'In connection with the proposed investment in the Fund, except as otherwise specified herein (including in Section J), the undersigned hereby represents as follows:'
                        }
                        className="align-header-text-sub-header"
                      />
                    </b>
                    <br />
                    <form onSubmit={this.handlerFormSubmit}>
                      <Title
                        text={
                          'A. General Investor Representations and Warranties'
                        }
                        className="align-header-text-sub-header"
                        contentId={'content1'}
                      />
                      {questionnaireContext.currentVersionQuestions.generalInvestorRepresentationsandWarranties.map(
                        (item) => (
                          <div key={item.id + item.displayName} className="row">
                            <Paragraph text={item.displayName} />
                            {item.toolTip && (
                              <Tooltip
                                data={{
                                  message: item.toolTip,
                                  className: 'position-absolute-tool-tip',
                                }}
                              />
                            )}
                          </div>
                        )
                      )}
                      <br />
                      <Title
                        text={'B. Identification of Investor Status'}
                        className="align-header-text-sub-header"
                        contentId={'content2'}
                      />
                      {questionnaireContext.currentVersionQuestions.identificationofInvestorStatus.map(
                        (item) => (
                          <div key={item.id + item.displayName} className="row">
                            <div id={item.id}>
                              {item.type === 'title' && (
                                <div className="align-text-push">
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <Paragraph text={item.displayName} />
                                </div>
                              )}
                              {item.type === 'quest' &&
                              this.checkBadActorQuestionAbility(item.id) &&
                              item.id !== iqID.id_investor_status_1
                                ? this.renderConfirm(
                                    [
                                      item.radioIdentifier[0],
                                      item.radioIdentifier[1],
                                      item.id,
                                    ],
                                    item.displayName,
                                    this.parseCheckboxStateFromId(item.id),
                                    item.name,
                                    item.size,
                                    item.tooltip,
                                    this.handlerConfirm
                                  )
                                : item.type === 'quest' &&
                                  this.checkBadActorQuestionAbility(item.id) &&
                                  item.id === iqID.id_investor_status_1
                                ? this.renderConfirm(
                                    [
                                      item.radioIdentifier[0],
                                      item.radioIdentifier[1],
                                      item.id,
                                    ],
                                    item.displayName,
                                    this.parseCheckboxStateFromId(item.id),
                                    item.name,
                                    item.size,
                                    item.tooltip,
                                    this.handlerConfirm,
                                    [
                                      !questionnaireContext.isNaturalPerson,
                                      questionnaireContext.isNaturalPerson,
                                    ]
                                  )
                                : null}
                              {/* this one may not be needed */}
                              {item.type === 'opt' &&
                              this.parseMultiSelectStateFromId(
                                item.id.split('_opt')[0]
                              ) === item.conditional
                                ? this.renderConfirm(
                                    [
                                      item.radioIdentifier[0],
                                      item.radioIdentifier[1],
                                      item.id,
                                    ],
                                    item.displayName,
                                    this.parseCheckboxStateFromId(item.id),
                                    item.name,
                                    item.size,
                                    item.tooltip,
                                    this.handlerConfirm
                                  )
                                : null}
                            </div>
                          </div>
                        )
                      )}
                      {includesFormPFQuestion &&
                        <div className="row">
                          <div>
                            <div className="paragraph-container">
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <p>
                                  7. Categories of Beneficial Ownership 
                                  for Advisers Act Reporting: {' '}
                                  The Fund’s management 
                                  company or an affiliate thereof may be required,
                                  pursuant to the Investment Advisers Act 
                                  of 1940 (as amended, the “Advisers Act”), to make periodic filings on Form PF with the 
                                  Securities  and Exchange  Commission.  Form PF requires disclosure of, among other information, 
                                  the percentage composition of the Fund’s equity by the type of beneficial owner. 
                                  The undersigned hereby represents and warrants that the undersigned is one of the following 
                                  (check exactly one (1) applicable box that best describes the undersigned):      
                                </p>
                              </div>
                            </div>
                          <MultiSelect
                            data={{
                              id: 'org-types-multiselect',
                              size: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
                            }}
                            content={this.categoriesOfBeneficialOwnership()}
                            onChangeHandler={(e) =>
                              this.handlerSingleSelect(
                                e,
                                'categoriesOfBeneficialOwnershipQuestions'
                              )
                          } />
                        </div>}
                      <br />
                      <Title
                        text={'C. Accredited Investor Representations'}
                        className="align-header-text-sub-header"
                        contentId={'content3'}
                      />
                      <div className="row">
                        <p id="accredited-investor-principal-introduction">
                          <b>
                            The undersigned hereby represents and warrants that
                            the undersigned is an Accredited Investor under Rule
                            501 of Regulation D promulgated by the Securities
                            and Exchange Commission and that the undersigned
                            qualifies as such because the undersigned is (check
                            applicable box):
                          </b>
                        </p>
                      </div>
                      <div className="row">
                        <MultiSelect
                          data={{
                            id: 'org-types-multiselect',
                            size: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
                          }}
                          content={this.accreditedInvestorRepresentationsContent()}
                          onChangeHandler={(e) =>
                            this.handlerMultiSelect(
                              e,
                              'accreditedInvestorRepresentations'
                            )
                          }
                        />
                      </div>
                      <br />
                      <Title
                        text={'D. Qualified Purchaser Representations'}
                        className="align-header-text-sub-header"
                        contentId={'content4'}
                      />
                      <div className="row">
                        <p id="qualified-purchaser-principal-introduction">
                          <b>
                            The undersigned hereby represents and warrants that
                            the undersigned is a Qualified Purchaser under
                            Section 2(a)(51) of the 1940 Act and that the
                            undersigned qualifies as such because the
                            undersigned is (check applicable box):
                          </b>
                        </p>
                      </div>
                      <div className="row">
                        <MultiSelect
                          data={{
                            id: 'org-types-multiselect2',
                            size: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
                          }}
                          content={this.qualifiedPurchaserRepresentationsContent()}
                          onChangeHandler={(e) =>
                            this.handlerMultiSelect(
                              e,
                              'qualifiedPurchaserRepresentations'
                            )
                          }
                        />
                      </div>
                      <br />
                      <Title
                        text={'E. Qualified Client Representations'}
                        className="align-header-text-sub-header"
                        contentId={'content5'}
                      />
                      <div>
                        <div
                          id="qualified-client-principal-introduction"
                          className={`row ${
                            disabledList.includes(
                              'qualified-client-principal-introduction'
                            )
                              ? 'color-gray'
                              : ''
                          }`}
                        >
                          <p>
                            <b>
                              If the undersigned is a qualified purchaser (and
                              the undersigned indicated the basis under which it
                              qualifies as such in the immediately preceding
                              Section D), the undersigned is not required to
                              complete this Section E and should skip to Section
                              F.
                            </b>
                          </p>
                          <p>
                            The undersigned hereby represents and warrants that
                            the undersigned is a Qualified Client under Rule
                            205-3 of the Advisers Act promulgated by the U.S.
                            Securities and Exchange Commission, and that the
                            undersigned qualifies as such because the
                            undersigned is (check all applicable boxes):
                          </p>
                        </div>
                        <div className="row">
                          <MultiSelect
                            data={{
                              id: 'org-types-multiselect3',
                              size: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
                            }}
                            content={this.qualifiedClientRepresentationsContent()}
                            onChangeHandler={(e) =>
                              this.handlerMultiSelect(
                                e,
                                'qualifiedClientRepresentations'
                              )
                            }
                          />
                        </div>
                      </div>

                      <br />
                      <Title
                        text={
                          'F. Committee on Foreign Investment in the United States (“CFIUS”) Foreign Person Status Representations'
                        }
                        className="align-header-text-sub-header"
                        contentId={'content6'}
                      />
                      <Paragraph
                        text={
                          '1. The undersigned hereby represents and warrants that the undersigned is (check applicable box):'
                        }
                        className="align-header-text-sub-header"
                      />
                      <div className="row bottom-space">
                        <MultiSelect
                          data={{
                            id: 'org-types-multiselect-cfius',
                            size: 'col-lg-12 col-md-12 col-sm-12 col-xs-12',
                          }}
                          content={this.foreignPersonStatusContent()}
                          onChangeHandler={(e) =>
                            this.handlerSingleSelect(
                              e,
                              'foreignPersonStatusRepresentations'
                            )
                          }
                        />
                      </div>

                   <div
                        className={`row ${
                          questionnaireContext.isNaturalPerson && 'color-gray'
                        }`}
                      >
                        <div className="col-lg-12 col-xs-12 col-md-12 col-sm-12 bottom-space">
                          {foreignYesOrNo && foreignYesOrNo &&
                            this.renderConfirm(
                              [
                                foreignYesOrNo.radioIdentifier[0],
                                foreignYesOrNo.radioIdentifier[1],
                                foreignYesOrNo.id,
                              ],
                              foreignYesOrNo.displayName,
                              this.parseCheckboxStateFromId(foreignYesOrNo.id),
                              foreignYesOrNo.name,
                              foreignYesOrNo.size,
                              foreignYesOrNo.tooltip,
                              this.handlerConfirm,
                              [
                                questionnaireContext.isNaturalPerson,
                                questionnaireContext.isNaturalPerson,
                              ]
                            )}
                        </div>
                        
                      </div>
                      <Title
                        text={'G. Wiring Bank Status'}
                        className="align-header-text-sub-header"
                        contentId={'content7'}
                      />
                      <div className="row">
                        {questionnaireContext.currentVersionQuestions.wiringBankStatus.map(
                          (item) => (
                            <div
                              key={item.id + item.displayName}
                              className="row"
                            >
                              <div id={item.id}>
                                {item.type === 'title' ? (
                                  <Paragraph text={item.displayName} />
                                ) : null}
                                <div className="col-lg-12 col-xs-12 col-md-12 col-sm-12">
                                  {item.type === 'quest'
                                    ? this.renderConfirm(
                                        [
                                          item.radioIdentifier[0],
                                          item.radioIdentifier[1],
                                          item.id,
                                        ],
                                        item.displayName,
                                        this.parseCheckboxStateFromId(item.id),
                                        item.name,
                                        item.size,
                                        item.tooltip,
                                        this.handlerConfirm
                                      )
                                    : null}
                                </div>
                                {item.type === 'opt' &&
                                item.id === iqID.id_wiring_bank_status_1_opt &&
                                optionals.includes(item.id) ? (
                                  <div>
                                    <Paragraph
                                      className="align-text-push reduce-bottom-margin"
                                      text={item.displayName}
                                    />
                                    <TextInput
                                      inputData={{
                                        id: item.id,
                                        isRequired: false,
                                        size: item.size,
                                        hasError: false,
                                        type: 'text',
                                      }}
                                      reference={(_node) => {
                                        this.id_wiring_bank_status_1_opt_Ref =
                                          _node;
                                      }}
                                      onBlurHandler={(e) =>
                                        this.handleTextInputBlur(
                                          e,
                                          this.id_wiring_bank_status_1_opt_Ref
                                            .value
                                        )
                                      }
                                    />
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          )
                        )}
                      </div>
                      <br />
                      <Title
                        text={'H. Identification of Special Entities'}
                        className="align-header-text-sub-header"
                        contentId={'content8'}
                      />

                      <div>
                        <div
                          id="special-entities-introduction-p"
                          className={`${
                            disabledList.includes(
                              'special-entities-introduction-p'
                            )
                              ? 'color-gray'
                              : ''
                          } row`}
                        >
                          <p>
                            <b>
                              If the undersigned is a natural person (and
                              indicated such by answering “Yes” to Question 1 in
                              Section B above), the undersigned is not required
                              to complete this Section H and should skip to
                              Section I.
                            </b>
                          </p>
                          <p>
                            In order to identify certain special entities whose
                            investment may require special treatment by the
                            Fund, please carefully answer each of the following
                            questions:
                          </p>
                        </div>
                        <div className="row">
                          {questionnaireContext.currentVersionQuestions.identificationofSpecialEntities.map(
                            (item) => (
                              <div
                                key={item.id + item.displayName}
                                className="row"
                              >
                                {item.id === iqID.id_special_entities_3_a ? (
                                  <div
                                    className={`${
                                      disabledList.includes(
                                        'special-entities-lost-descrip-quest'
                                      )
                                        ? 'color-gray'
                                        : ''
                                    } col-lg-10 col-md-10 col-sm-10 col-xs-10 tab-space`}
                                    id="special-entities-lost-descrip-quest"
                                  >
                                    <Paragraph
                                      text={
                                        '(a). any agency, authority or instrumentality of the state or political subdivision;'
                                      }
                                    />
                                    <Paragraph
                                      text={
                                        '(b). a pool of assets sponsored or established by the state or political subdivision or any agency, authority or instrumentality thereof, including but not limited to a “defined benefit plan” as defined in section 414(j) of the Internal Revenue Code, or a state general fund;'
                                      }
                                    />
                                    <Paragraph
                                      text={
                                        '(c). a plan or program of a government entity; and'
                                      }
                                    />
                                    <Paragraph
                                      text={
                                        '(d). officers, agents or employees of the state or political subdivision or any agency, authority or instrumentality thereof, acting in their official capacity?'
                                      }
                                    />
                                  </div>
                                ) : null}
                                <div id={item.id}>
                                  {item.type === 'title' ? (
                                    <div className="align-text-push-max">
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <Paragraph
                                        id={`${item.id}-title`}
                                        className={`${
                                          disabledList.includes(
                                            `${item.id}-title`
                                          )
                                            ? 'color-gray'
                                            : ''
                                        }`}
                                        text={item.displayName}
                                      />
                                    </div>
                                  ) : null}
                                  <div className="col-lg-12 col-xs-12 col-md-12 col-sm-12">
                                    {item.type === 'quest'
                                      ? this.renderConfirm(
                                          [
                                            item.radioIdentifier[0],
                                            item.radioIdentifier[1],
                                            item.id,
                                          ],
                                          item.displayName,
                                          this.parseCheckboxStateFromId(
                                            item.id
                                          ),
                                          item.name,
                                          item.size,
                                          item.tooltip,
                                          this.handlerConfirm,
                                          [
                                            disabledList.includes(item.id),
                                            disabledList.includes(item.id),
                                          ]
                                        )
                                      : null}
                                  </div>
                                  {item.type === 'opt' &&
                                  item.id ===
                                    iqID.id_special_entities_1_b_opt &&
                                  optionals.includes(item.id) ? (
                                    <div>
                                      <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10 tab-space">
                                        <Paragraph
                                          className="reduce-bottom-margin"
                                          text={item.displayName}
                                        />
                                      </div>
                                      <TextInput
                                        inputData={{
                                          id: item.id,
                                          isRequired: false,
                                          size: item.size,
                                          hasError: false,
                                          type: 'text',
                                        }}
                                        reference={(_node) => {
                                          this.id_special_entities_1_b_opt_Ref =
                                            _node;
                                        }}
                                        onBlurHandler={(e) =>
                                          this.handleTextInputBlur(
                                            e,
                                            this.id_special_entities_1_b_opt_Ref
                                              .value
                                          )
                                        }
                                      />
                                    </div>
                                  ) : null}
                                  {item.type === 'opt' &&
                                  item.id ===
                                    iqID.id_special_entities_2_e_opt &&
                                  optionals.includes(item.id) ? (
                                    <div>
                                      <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10 tab-space">
                                        <Paragraph
                                          className="reduce-bottom-margin align-text-push"
                                          text={item.displayName}
                                        />
                                      </div>
                                      <div className="align-text-push">
                                        <TextInput
                                          inputData={{
                                            id: item.id,
                                            isRequired: false,
                                            size: item.size,
                                            hasError: false,
                                            type: 'number',
                                          }}
                                          reference={(_node) => {
                                            this.id_special_entities_2_e_opt_Ref =
                                              _node;
                                          }}
                                          onBlurHandler={(e) =>
                                            this.handleTextInputBlur(
                                              e,
                                              this
                                                .id_special_entities_2_e_opt_Ref
                                                .value
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </div>

                      <br />
                      <Title
                        text={'I. Miscellaneous'}
                        className="align-header-text-sub-header"
                        contentId={'content9'}
                      />
                      <div className="row">
                   {includesRegulatoryFund &&  
                    <p>
                       1. If the General Partner forms a Regulatory Fund (as defined in the Fund Agreement) 
                        for the purpose of seeking an alternate exemption from registration under the 1940 Act 
                        to the exemption sought by the Fund (such Regulatory Fund, the “1940 Act Regulatory  Fund”),  
                        the  undersigned  agrees  that  the  General  Partner  may,  in  its sole and absolute 
                        discretion, elect to admit the undersigned as a limited partner of the 1940 Act Regulatory 
                        Fund in lieu of admitting the undersigned as a Limited Partner of the Fund; provided, 
                        that the material economic terms of the 1940 Act Regulatory Fund shall be substantially 
                        identical to those of the Fund Agreement.  If  the  General  Partner  elects  to  admit  
                        the  undersigned  to  such  a  1940  Act Regulatory Fund, the undersigned further 
                        acknowledges and agrees that (a) all of the  covenants,  representations  and 
                         warranties  made  by  the  undersigned  in  this Investor   Questionnaire   
                         and   all   exhibits   and   other   documents   information accompanying  
                         the  same  shall  be  deemed  to  have  been  delivered  to  the  General
                          Partner with respect to such 1940 Act Regulatory Fund and all references 
                          to “the Fund” herein shall be deemed to apply to such 1940 Act Regulatory 
                          Fund, and (b) the undersigned’s signature page to the Fund Agreement executed 
                          in connection with the undersigned’s subscription for an interest in the Fund 
                          shall be deemed to be a counterpart signature page to the limited partnership 
                          agreement of such 1940 Act Regulatory Fund and may be attached as a counterpart 
                          signature page to such agreement  (in  which  case  the  undersigned  agrees  
                          that  the  undersigned  shall  be deemed to have made, and shall be subject to, 
                          all of the covenants, representations, warranties  and  agreements  of  the 
                           limited  partners  set  forth  in  the  limited partnership  agreement  of 
                            the  1940  Act  Regulatory  Fund  to  the  extent  such covenants,  
                            representations,  warranties  and  agreements  are  substantially  
                            identical in form and substance to the covenants, representations, 
                            warranties and agreements of  the  limited  partners  set  forth  in 
                             the  Fund  Agreement).   In  such  event,  the undersigned hereby 
                             authorizes the General Partner to redact or otherwise cross out any 
                              reference  to  the  name  of  the  Fund  on  any  such  signature 
                               page  to  the  Fund Agreement, and to insert the name of the 1940 
                               Act Regulatory Fund in its place.
                      </p>}

                      {questionnaireContext.currentVersionQuestions.miscellaneous.map(
                      (item, i) => (
                                 <p>
                                    {includesRegulatoryFund ? i + 2 : i + 1}. {item.displayName}
                                 </p>
                            ))}
                      </div>
                      <br />
                      <Title
                        text={'J. Exceptions to Representations and Warranties'}
                        className="align-header-text-sub-header"
                        contentId={'content10'}
                      />
                      <div className="row">
                        <TextArea
                          inputData={{
                            id: questionnaireContext.currentVersionQuestions
                              .warrantiesExceptions[0].id,
                            displayName:
                              questionnaireContext.currentVersionQuestions
                                .warrantiesExceptions[0].displayName,
                            isRequired: false,
                            hasError: false,
                            title: '',
                            description:
                              questionnaireContext.currentVersionQuestions
                                .warrantiesExceptions[0].description,
                          }}
                          reference={(_node) => {
                            this.id_warranties_exceptions_Ref = _node;
                          }}
                          onBlurHandler={(e) =>
                            this.handleTextInputBlur(
                              e,
                              this.id_warranties_exceptions_Ref.value
                            )
                          }
                        />
                      </div>
                      <div className="margin-bottom-spacing">
                        <div className="back-btn">
                          <Button
                            inputData={{
                              id: 'back-btn',
                              displayName: 'Back',
                              type: 'button',
                            }}
                            clickHandler={this.backStep}
                          />
                        </div>
                        <div className="next-btn">
                          <Button
                            inputData={{
                              id: 'next-btn',
                              displayName: 'Next',
                              type: 'submit',
                            }}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <p>'...'</p>
    );
  }
}

const mapStateToProps = (state: Reducer) => ({
  context: state.context,
  questionnaireContext: state.questionnaireExperience,
});
const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  (dispatch: Dispatch) => ({})
);

export default connector(QuestionnaireForm);
