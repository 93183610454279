import React from 'react';
import './styles.scss';
import Button from '../Button/index';
import SearchBox from '../SearchBox/index';
import Tooltip from "rc-tooltip";
const { formatMoney } = require('../../utils/format');

type Props = {
  data: Object,
  searchHandler: () => {},
  buttonHandler: () => {},
  buttonEditHandler: () => {},
  linkHandler: () => {},
  searchValue: string,
  changeHandler: () => {},
  enterHandler: () => {},
};

const nFormatter = (num) => {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
  }
  
  return `${formatMoney(num, 0, '.', ',')}`;
};


const CapitalToolTip = ({amount, title}) => {
  const isUnderMillion = amount < 1000000 || !amount;
  return (
    <div className="capital-tooltip">
     {!isUnderMillion ? <Tooltip
      placement='bottom'
      trigger={['hover']}
      overlay={<div className='capital-tooltip-overlay'>${formatMoney(amount, 2, '.', ',')}</div>}>
       <span className="tooltip-text">{amount ? `$${nFormatter(amount)}` : '$0'}</span>
    </Tooltip> : 
    <span className="tooltip-text">{amount ? `$${nFormatter(amount)}` : '$0'}</span>}
    <p>{title}</p> 
    </div>
  )
}

const renderButton = (id, displayName, icon, type, handler) => {
  const inputDataTemp = {
    id,
    displayName,
    icon,
  };
  return <Button inputData={inputDataTemp} clickHandler={handler} />;
};

const searchFilterType = (
  data,
  buttonHandler,
  linkHandler,
  searchHandler,
  changeHandler,
  buttonEditHandler,
  searchValue,
  enterHandler
) => (
  <div className="row">
    <div className={'col-lg-6 col-md-5 col-sm-3 col-xs-12'}>
      <div className="filter-row-label color-light-black">
        {data.boxTitleIsLink ? (
          <a
            onClick={linkHandler}
            id="idBackButton"
            href="#/"
            className=" color-light-black text-with-arrow left"
          >
            {data.boxTitle}{' '}
          </a>
        ) : (
          data.boxTitle
        )}
      </div>
    </div>
    <div className="col-lg-6 col-md-7 col-sm-9 col-xs-12">
      <div className="filter-row-content pull-right">
        {data.filterType === 'search' ? (
          <SearchBox
            searchHandler={searchHandler}
            changeHandler={changeHandler}
            searchValue={searchValue}
            enterHandler={enterHandler}
          />
        ) : (
          ''
        )}
        {data.filterType === 'edit' ? (
          <a
            href="#/"
            onClick={buttonEditHandler}
            className="pull-left color-light-black edit-firm"
          >
            Edit Firm Details
          </a>
        ) : (
          ''
        )}
        {data.filterType === 'detail' ? (
          <div className="pull-left color-light-black filter-detail">
            <CapitalToolTip amount={data.detail.allCapitalAmount} title="All Commitments" /> |
            <CapitalToolTip amount={data.detail.notReadyToCloseAmount} title="Not Yet Ready to Close" /> |
            <CapitalToolTip amount={data.detail.readyToCloseAmount} title="Ready to Close" /> |
            <CapitalToolTip amount={data.detail.closedAmount}  title="Closed" /> 
          </div>
        ) : (
          ''
        )}
        {data.buttonId &&
          renderButton(
            data.buttonId,
            data.buttonDisplayName,
            data.buttonIcon,
            data.buttonType,
            buttonHandler
          )}
      </div>
    </div>
  </div>
);

const infoFilterType = (data, buttonHandler, linkHandler) => (
  <div className="row">
    <div className={'col-lg-3 col-md-3 col-sm-3 col-xs-12'}>
      <div className="filter-row-label color-light-black">
        {data.boxTitleIsLink ? (
          <a
            onClick={linkHandler}
            href="#/"
            className="color-light-black text-with-arrow left"
          >
            {data.boxTitle}{' '}
          </a>
        ) : (
          data.boxTitle
        )}
      </div>
    </div>
    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12 col-lg-pull-1 col-md-pull-1 col-sm-pull-1 col-xs-pull-0">
      <div className="filter-row-content text-align-center">
        <h4 className="color-dark-green">{data.infoText}</h4>
      </div>
    </div>
  </div>
);

const FilterBox = ({
  data,
  buttonHandler,
  searchHandler,
  buttonEditHandler,
  searchValue,
  linkHandler,
  changeHandler,
  enterHandler,
}: Props) => (
  <div
    className={`filter-row background-color-white ${
      data.boxTitleIsLink ? 'no-border' : ''
    } clearfix`}
  >
    {data.filterType === 'info'
      ? infoFilterType(data, buttonHandler, linkHandler)
      : searchFilterType(
          data,
          buttonHandler,
          linkHandler,
          searchHandler,
          changeHandler,
          buttonEditHandler,
          searchValue,
          enterHandler
        )}
  </div>
);

FilterBox.defaultProps = {
  data: {
    boxTitle: '',
    boxTitleIsLink: false,
    buttonId: '',
    buttonDisplayName: '',
    buttonIcon: '',
    buttonType: '',
    infoText: '',
    filterType: '',
    detail: '',
  },
  searchHandler: () => {},
  buttonHandler: () => {},
  buttonEditHandler: () => {},
  linkHandler: () => {},
  searchValue: '',
  changeHandler: () => {},
  enterHandler: () => {},
};

export default FilterBox;
