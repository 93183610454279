import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import './styles.scss';
import CustomTooltip from '../Tooltip/index';
import { resolveSizeClass } from '../utils/common';

type Props = {
  inputData: Object,
  show: Boolean,
  reference: any,
  onBlurHandler: () => void,
  onFocusHandler: () => void,
};

const resolveRequired = (isRequired, size) => {
  if (isRequired) {
    const requiredText = size === 'small' ? '*' : '* Required';
    return <legend>{requiredText}</legend>;
  }
  return <div />;
};

const resolveErrorClass = (hasError) => {
  const error = hasError ? 'error' : '';
  const formGroupClass = `form-group input-group-style ${error}`;

  return formGroupClass;
};

const handlerChangeName = (event) => {
  event.preventDefault();
  const shareInputs = document.getElementsByClassName('input-share-link-general-class');
  const copyBtns = document.getElementsByClassName('copy-btn');
  for (let index = 0; index < copyBtns.length; index += 1) {
    copyBtns[index].innerHTML = 'copied!';
    copyBtns[index].style.fontWeight = 'bold';
    shareInputs[index].children[1].children[0].children[1].select();
  }
  setTimeout(() => {
    for (let index = 0; index < copyBtns.length; index += 1) {
      copyBtns[index].innerHTML = 'copy';
      copyBtns[index].style.fontWeight = 'normal';
    }
  }, 2000);
};

const onKeyDown = (e) => {
    if (e.key === "Tab" && e.target.id === 'legalNameBeneficalOwner') {
      e.preventDefault();
      e.target.blur();
      setTimeout(() => {
        document.getElementById("residenceBeneficalOwner").focus();
      }, 1000)
      
    }
  };

const TextInput = ({
  inputData,
  reference,
  onChangeHandler,
  onBlurHandler,
  onFocusHandler,
  show,
}: Props) => (
    <div className={`${resolveSizeClass(inputData.size)} ${!show ? 'input-no-show' : ''}`}>
      <div className={inputData.wrapperClass}>
        <div>
          {!inputData.noTitle && (
            <h5 id={`title${inputData.id}`} className="color-black tooltip-positioning">
              {inputData.displayName}
            </h5>
          )}
          {inputData.showHelp && (
            <CustomTooltip
              className={'tooltip-positioning'}
              data={{
                message: inputData.helpText,
                extraClass: inputData.tooltipExtraClass || 'tooltip-margin',
              }}
            />
          )}
        </div>

        <div className={resolveErrorClass(inputData.hasError)}>
          <fieldset style={inputData.percentage && { display: 'flex', alignItems: 'flex-end'}}>
            {resolveRequired(inputData.requiredLegendIndicator, inputData.size)}
            <input
              id={inputData.id}
              style={inputData.style}
              onFocus={onFocusHandler}
              onBlur={onBlurHandler}
              onKeyDown={onKeyDown}
              type={inputData.type}
              className="form-control input-style"
              placeholder={inputData.placeholder}
              name={inputData.name}
              disabled={inputData.disabled}
              autoComplete={'off'}
              ref={reference}
            />
            {inputData.hasCopyButton ? (
              <div>
                <CopyToClipboard text={inputData.value}>
                  <a onClick={handlerChangeName} id="copy-button" href="#/" className="copy-btn">
                    {' '}
                    copy{' '}
                  </a>
                </CopyToClipboard>
                &nbsp;
            </div>
            ) : null}
            {inputData.percentage && <p>%</p>}
          </fieldset>
        </div>
        {inputData.hasError ? (
          <div className="error-message">
            <span className="text">{inputData.errorMessage}</span>
          </div>
        ) : null}
      </div>
    </div>
  );

TextInput.defaultProps = {
  inputData: {
    id: '',
    displayName: '',
    placeholder: '',
    name: '',
    size: '', // use other values for a custom size
    isRequired: false,
    requiredLegendIndicator: false,
    hasError: false,
    type: 'text',
    errorMessage: 'not set',
    value: '',
    wrapperClass: '',
    helpText: 'tooltip text',
    showHelp: true,
    noTitle: false,
    focused: false,
    hasCopyButton: false,
    disabled: false,
    moneyInput: false,
  },
  show: true,
  onChangeHandler: () => { },
  onBlurHandler: () => { },
  onFocusHandler: () => { },
};

export default TextInput;
