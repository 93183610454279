module.exports = {
  id_investor_status_1: 'id_investor_status_1',
  id_investor_status_2: 'id_investor_status_2',
  id_investor_status_3: 'id_investor_status_3',
  id_investor_status_4: 'id_investor_status_4',
  id_investor_status_5: 'id_investor_status_5',
  id_investor_status_5_a: 'id_investor_status_5_a',
  id_investor_status_5_b: 'id_investor_status_5_b',
  id_investor_status_5_c: 'id_investor_status_5_c',
  id_investor_status_5_d: 'id_investor_status_5_d',
  id_investor_status_5_e: 'id_investor_status_5_e',
  id_investor_status_5_f: 'id_investor_status_5_f',
  id_investor_status_6: 'id_investor_status_6',
  id_investor_status_7: 'id_investor_status_7', // added for Questa Capital
  id_investor_status_7_hcm: 'id_investor_status_7_hcm', // added for HCM
  id_investor_status_7_hcm_a: 'id_investor_status_7_hcm_a', // added for HCM
  id_investor_status_7_hcm_b: 'id_investor_status_7_hcm_b', // added for HCM
  id_investor_status_7_hcm_c: 'id_investor_status_7_hcm_c', // added for HCM
  id_investor_status_7_hcm_d: 'id_investor_status_7_hcm_d', // added for HCM
  id_investor_status_7_hcm_e: 'id_investor_status_7_hcm_e', // added for HCM
  id_investor_status_7_hcm_f: 'id_investor_status_7_hcm_f', // added for HCM
  id_investor_status_7_hcm_g: 'id_investor_status_7_hcm_g', // added for HCM
  id_investor_status_7_hcm_h: 'id_investor_status_7_hcm_h', // added for HCM
  id_investor_status_7_hcm_i: 'id_investor_status_7_hcm_i', // added for HCM
  id_investor_status_7_hcm_j: 'id_investor_status_7_hcm_j', // added for HCM
  id_investor_status_7_hcm_k: 'id_investor_status_7_hcm_k', // added for HCM
  id_investor_status_7_hcm_l: 'id_investor_status_7_hcm_l', // added for HCM
  id_investor_status_7_hcm_m: 'id_investor_status_7_hcm_m', // added for HCM
  id_investor_status_7_hcm_m_trigger: 'id_investor_status_7_hcm_m_trigger', // added for HCM
  id_investor_status_7_hcm_n: 'id_investor_status_7_hcm_n', // added for HCM
  id_investor_status_7_hcm_other: 'id_investor_status_7_hcm_other', // added for HCM
  id_investor_status_7_hcm_other_trigger:
    'id_investor_status_7_hcm_other_trigger', // added for HCM
    
  // added for tcg crossOver    
  id_investor_status_7_tcg: 'id_investor_status_7_tcg', // added for tcg crossOver
  id_investor_status_7_tcg_a: 'id_investor_status_7_tcg_a', // added for tcg CrossOver 
  id_investor_status_7_tcg_b: 'id_investor_status_7_tcg_b', // added for tcg CrossOver
  id_investor_status_7_tcg_c: 'id_investor_status_7_tcg_c', // added for tcg CrossOver   
  id_investor_status_7_tcg_d: 'id_investor_status_7_tcg_d', // added for tcg CrossOver
  id_investor_status_7_tcg_e: 'id_investor_status_7_tcg_e', // added for tcg CrossOver
  id_investor_status_7_tcg_f: 'id_investor_status_7_tcg_f', // added for tcg CrossOver
  id_investor_status_7_tcg_g: 'id_investor_status_7_tcg_g', // added for tcg CrossOver
  id_investor_status_7_tcg_h: 'id_investor_status_7_tcg_h', // added for tcg CrossOver
  id_investor_status_7_tcg_i: 'id_investor_status_7_tcg_i', // added for tcg CrossOver
  id_investor_status_7_tcg_j: 'id_investor_status_7_tcg_j', // added for tcg CrossOver
  id_investor_status_7_tcg_k: 'id_investor_status_7_tcg_k', // added for tcg CrossOver
  id_investor_status_7_tcg_l: 'id_investor_status_7_tcg_l', // added for tcg CrossOver
  id_investor_status_7_tcg_m: 'id_investor_status_7_tcg_m', // added for tcg CrossOver
  id_investor_status_7_tcg_n: 'id_investor_status_7_tcg_n', // added for tcg CrossOver
  id_investor_status_7_tcg_o: 'id_investor_status_7_tcg_o', // added for tcg CrossOver


  // added for tcg crossOver
  id_covered_investor_status: 'id_covered_investor_status',
  id_covered_investor_status_a: 'id_covered_investor_status_a',
  id_covered_investor_status_b: 'id_covered_investor_status_b',
  id_covered_investor_status_or: 'id_covered_investor_status_or',

  id_exempted_entity_status: 'id_exempted_entity_status',
  id_exempted_entity_status_a: 'id_exempted_entity_status_a',
  id_exempted_entity_status_b: 'id_exempted_entity_status_b',
  id_exempted_entity_status_c: 'id_exempted_entity_status_c',
  id_exempted_entity_status_d: 'id_exempted_entity_status_d',
  id_exempted_entity_status_e: 'id_exempted_entity_status_e',
  id_exempted_entity_status_f: 'id_exempted_entity_status_f',
  id_exempted_entity_status_g: 'id_exempted_entity_status_g',
  id_exempted_entity_status_h: 'id_exempted_entity_status_h',
  id_exempted_entity_status_i: 'id_exempted_entity_status_i',

 
  id_restricted_person_status: 'id_restricted_person_status',
  id_restricted_person_status_a: 'id_restricted_person_status_a',
  id_restricted_person_status_b: 'id_restricted_person_status_b',
  id_restricted_person_status_c: 'id_restricted_person_status_c',
  id_restricted_person_status_d: 'id_restricted_person_status_d',
  id_restricted_person_status_e: 'id_restricted_person_status_e',
  id_restricted_person_status_f: 'id_restricted_person_status_f',
  id_restricted_person_status_g: 'id_restricted_person_status_g',
  id_restricted_person_status_h: 'id_restricted_person_status_h',
  id_restricted_person_status_i: 'id_restricted_person_status_i',
  id_restricted_person_status_j: 'id_restricted_person_status_j',
  id_restricted_person_status_k: 'id_restricted_person_status_k',
  id_restricted_person_status_or: 'id_restricted_person_status_or',

  // added for tcg crossOver

  id_investor_representations_1: 'id_investor_representations_1',
  id_investor_representations_2: 'id_investor_representations_2',
  id_investor_representations_3: 'id_investor_representations_3',
  id_investor_representations_4: 'id_investor_representations_4',
  id_investor_representations_5: 'id_investor_representations_5',
  id_investor_representations_6: 'id_investor_representations_6',
  id_investor_representations_6_extra: 'id_investor_representations_6_extra',
  id_investor_representations_7: 'id_investor_representations_7',
  id_investor_representations_8: 'id_investor_representations_8',
  id_investor_representations_9: 'id_investor_representations_9',
  id_investor_representations_10: 'id_investor_representations_10',
  id_investor_representations_11: 'id_investor_representations_11',
  id_investor_representations_12: 'id_investor_representations_12',
  id_investor_representations_or: 'id_investor_representations_or',

  id_purchase_representations_1: 'id_purchase_representations_1',
  id_purchase_representations_2: 'id_purchase_representations_2',
  id_purchase_representations_3: 'id_purchase_representations_3',
  id_purchase_representations_4: 'id_purchase_representations_4',
  id_purchase_representations_5: 'id_purchase_representations_5',
  id_purchase_representations_6: 'id_purchase_representations_6',
  id_purchase_representations_or: 'id_purchase_representations_or',

  id_client_representations_1: 'id_client_representations_1',
  id_client_representations_2: 'id_client_representations_2',
  id_client_representations_2_a: 'id_client_representations_2_a',
  id_client_representations_2_b: 'id_client_representations_2_b',
  id_client_representations_or: 'id_client_representations_or',

  id_special_entities_1: 'id_special_entities_1',
  id_special_entities_1_a: 'id_special_entities_1_a',
  id_special_entities_1_b: 'id_special_entities_1_b',
  id_special_entities_1_b_opt: 'id_special_entities_1_b_opt',
  id_special_entities_2: 'id_special_entities_2',
  id_special_entities_2_a: 'id_special_entities_2_a',
  id_special_entities_2_b: 'id_special_entities_2_b',
  id_special_entities_2_c: 'id_special_entities_2_c',
  id_special_entities_2_d: 'id_special_entities_2_d',
  id_special_entities_2_e_opt: 'id_special_entities_2_e_opt',
  id_special_entities_3: 'id_special_entities_3',
  id_special_entities_3_a: 'id_special_entities_3_a',
  id_special_entities_3_b: 'id_special_entities_3_b',
  id_special_entities_3_c: 'id_special_entities_3_c',
  id_special_entities_3_d: 'id_special_entities_3_d',
  id_special_entities_4: 'id_special_entities_4',
  id_special_entities_4_a: 'id_special_entities_4_a',
  id_special_entities_5: 'id_special_entities_5',
  id_special_entities_6: 'id_special_entities_6',
  id_special_entities_7: 'id_special_entities_7',
  id_special_entities_8: 'id_special_entities_8',
  id_special_entities_9: 'id_special_entities_9',
  id_special_entities_9_a: 'id_special_entities_9_a',
  id_special_entities_9_b: 'id_special_entities_9_b',
  id_special_entities_9_c: 'id_special_entities_9_c',
  id_special_entities_10: 'id_special_entities_10',

  id_foreign_person_1: 'id_foreign_person_1',
  id_foreign_person_2: 'id_foreign_person_2',
  id_foreign_person_3: 'id_foreign_person_3',
  id_foreign_person_4: 'id_foreign_person_4',
  id_foreign_person_or: 'id_foreign_person_or',

  id_foreign_person_2_1: 'id_foreign_person_2_1',
  id_foreign_person_2_2: 'id_foreign_person_2_2',
  id_foreign_person_2_or: 'id_foreign_person_2_or',

  id_wiring_bank_status_1: 'id_wiring_bank_status_1',
  id_wiring_bank_status_1_opt: 'id_wiring_bank_status_1_opt',
  id_wiring_bank_status_2: 'id_wiring_bank_status_2',

  id_warranties_exceptions: 'id_warranties_exceptions',

  id_foresite_investor_type_1: 'id_foresite_investor_type_1',
  id_foresite_investor_type_2: 'id_foresite_investor_type_2',
  id_foresite_investor_type_3: 'id_foresite_investor_type_3',
  id_foresite_investor_type_4: 'id_foresite_investor_type_4',
  id_foresite_investor_type_5: 'id_foresite_investor_type_5',
  id_foresite_investor_type_6: 'id_foresite_investor_type_6',
  id_foresite_investor_type_7: 'id_foresite_investor_type_7',
  id_foresite_investor_type_8: 'id_foresite_investor_type_8',
  id_foresite_investor_type_9: 'id_foresite_investor_type_9',
  id_foresite_investor_type_10: 'id_foresite_investor_type_10',
  id_foresite_investor_type_11: 'id_foresite_investor_type_11',
  id_foresite_investor_type_12: 'id_foresite_investor_type_12',
  id_foresite_investor_type_13: 'id_foresite_investor_type_13',

  id_foresiteFinraAffiliation: 'id_foresiteFinraAffiliation',
  id_foresiteFinraAffiliationFurtherDetails:
    'id_foresiteFinraAffiliationFurtherDetails',
  id_foresitePublicCompanyAffiliation: 'id_foresitePublicCompanyAffiliation',
  id_foresitePublicCompanyAffiliation_opt:
    'id_foresitePublicCompanyAffiliation_opt',
  id_foresiteEntities: 'id_foresiteEntities',
  id_foresiteEntities_opt: 'id_foresiteEntities_opt',

  rule5130_finraNewIssues_1: 'rule5130_finraNewIssues_1',
  rule5130_finraNewIssues_1_opt_1: 'rule5130_finraNewIssues_1_opt_1',
  rule5130_finraNewIssues_1_opt_2: 'rule5130_finraNewIssues_1_opt_2',
  rule5130_finraNewIssues_1_opt_3: 'rule5130_finraNewIssues_1_opt_3',
  rule5130_finraNewIssues_2: 'rule5130_finraNewIssues_2',

  rule5131_finraNewIssues_1: 'rule5131_finraNewIssues_1',
  rule5131_finraNewIssues_2: 'rule5131_finraNewIssues_2',

  disregardedQuestion: 'disregardedQuestion',

  id_retirementPlan: 'id_retirementPlan',

  id_additional_contact_info: 'id_additional_contact_info',

  // griw-7 accel-main
  id_girw_7_a: 'id_girw_7_a',
  id_girw_7_b: 'id_girw_7_b',
  id_girw_7_c: 'id_girw_7_c',
  id_girw_7_d: 'id_girw_7_d',
  id_girw_7_e: 'id_girw_7_e',
  id_girw_7_f: 'id_girw_7_f',
  id_girw_7_g: 'id_girw_7_g',
  id_girw_7_h: 'id_girw_7_h',
  id_girw_7_i: 'id_girw_7_i',
  // griw-7 accel-main
  id_girw_7_2_a: 'id_girw_7_2_a',
  id_girw_7_2_b: 'id_girw_7_2_b',
  id_girw_7_2_c: 'id_girw_7_2_c',
  id_girw_7_2_d: 'id_girw_7_2_d',
  id_girw_7_2_e: 'id_girw_7_2_e',
  id_girw_7_2_f: 'id_girw_7_2_f',

  id_griw7_2CompanyBasis_a: 'id_griw7_2CompanyBasis_a',
  id_griw7_2CompanyBasis_b: 'id_griw7_2CompanyBasis_b',
  id_griw7_2CompanyBasis_c: 'id_griw7_2CompanyBasis_c',

  id_girw_8_1_a: 'id_girw_8_1_a',
  id_girw_8_1_b: 'id_girw_8_1_b',
  id_girw_8_1_c: 'id_girw_8_1_c',
  id_girw_8_1_d: 'id_girw_8_1_d',
  id_girw_8_1_e: 'id_girw_8_1_e',
  id_girw_8_1_f: 'id_girw_8_1_f',
  id_girw_8_1_g: 'id_girw_8_1_g',
  id_girw_8_1_h: 'id_girw_8_1_h',
  id_girw_8_1_i: 'id_girw_8_1_i',
  id_girw_8_1_j: 'id_girw_8_1_j',
  id_girw_8_1_k: 'id_girw_8_1_k',
  id_girw_8_1_l: 'id_girw_8_1_l',
};
